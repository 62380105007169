// material-ui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";
import { ReportCandidate } from "model/basicInfo";
import { addReport, editReport } from "store/actions/main/basicInfo/reporet";
import { CircularProgress } from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { FormattedMessage,useIntl } from "react-intl";

// constant
const getInitialValues = (customer: ReportCandidate | null) => {
  if (customer) {
    return {
      text: customer.text || "",
      text_trans: customer.text_trans.ar || "",
      status: customer.status || false,
    };
  } else {
    return {
      text: "",
      text_trans: "",
      status: false,
    };
  }
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormReportAdd = ({
  customer,
  closeModal,
  fetchData,
  loading,
}: {
  loading: any;
  setLoading: any;
  fetchData: any;
  id: number;
  customer: ReportCandidate | null;
  closeModal: () => void;
}) => {
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    text: Yup.string().required("English text is required"),
   
  });
  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editReport({
              id: customer.id || 0,
              data: {
                text: values.text,
                status: values.status,
                text_trans: {
                ar:values.text_trans
                },
                
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "basicInfo/editReport/fulfilled") {
              openSnackbar({
                open: true,
                message: "Report edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addReport({
           text: values.text,
            status: values.status,
            text_trans: {
            ar:values.text_trans
            },})).then((action) => {
            fetchData();
            if (action.type === "basicInfo/addReport/fulfilled") {
              openSnackbar({
                open: true,
                message: "Report added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue
  } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ?  <FormattedMessage id="edit-report-candidate-details" /> : <FormattedMessage id="add-new-report-candidate" />}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container>
                <Grid item xs={12} md={12} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name"><FormattedMessage id="english-text" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="type-english-text-of-the-report" />}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="customer-name"
                      placeholder="Enter text"
                      {...getFieldProps("text")}
                      error={Boolean(touched.text && errors.text)}
                      helperText={touched.text && errors.text}
                     
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-order"><FormattedMessage id="arabic-text" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="type-arabic-text-of-the-report" />}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                      <TextField
                          fullWidth
                          type="text"
                          id="customer-ar_text"
                          placeholder="ادخل النص باللغة العربية"
                          {...getFieldProps("text_trans")}
                          error={Boolean(touched.text_trans && errors.text_trans)}
                          helperText={touched.text_trans && errors.text_trans}
                          inputProps={{
                            dir: "rtl",
                            style: { textAlign: "left" },
                          
                          }}
                          InputProps={{
                            inputProps: {
                              style: { textAlign: "right" },
                          
                            },
                            classes: {
                              input: "placeholder-ltr", // Custom class for placeholder styling
                            },
                          }}
                        />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-is_active">
                    <FormattedMessage id="status" />
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="set-the-country-status" />}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            id="customer-is_active"
                            checked={formik.values.status}
                            {...getFieldProps("status")}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              setFieldValue("status", checked);
                            }}
                          />
                        }
                        label={formik.values.status ? <FormattedMessage id="active" /> : <FormattedMessage id="inactive" />}
                      />
                    </FormControl>
                    {touched.status && errors.status && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{ pl: 1.75 }}
                      >
                        {errors.is_active}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : <FormattedMessage id="submit" />}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                      <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormReportAdd;
