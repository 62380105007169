import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS } from "api";
import responseError from "utils/api/error-handling";
import educationalURL from "../../../../utils/url/basicInfo/educationType";

//get AllUser
export const getAllEducational = createAsyncThunk(
  "basicInfo/getAllEducational",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${educationalURL.GET_EDUCATIONAL_TYPE}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER
export const searshAllEducational = createAsyncThunk(
  "basicInfo/searshAllEducational",
  async ({ name_trans}: { name_trans: string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${educationalURL.GET_EDUCATIONAL_TYPE}?name_trans=${name_trans}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const getOneEducational = createAsyncThunk(
  "basicInfo/getOneEducational",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${educationalURL.GET_ONE_EDUCATIONAL_TYPE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editEducational = createAsyncThunk(
  "basicInfo/editEducational",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.post(
        `${educationalURL.EDIT_EDUCATIONAL_TYPE}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addEducational = createAsyncThunk(
  "basicInfo/addEducational",
  async (data: any, thunkAPI) => {
    try {
      const response = await AXIOS.post(
        educationalURL.ADD_EDUCATIONAL_TYPE,
        data,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteEducational = createAsyncThunk(
  "basicInfo/deleteEducational",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(
        `${educationalURL.DELETE_EDUCATIONAL_TYPE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletEducational = createAsyncThunk(
  "basicInfo/MultiDeletEducational",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(
        educationalURL.MULTI_DELET_EDUCATIONAL_TYPE,
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
