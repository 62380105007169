import {  useEffect, useState } from "react";


// material-ui
import {

  Button,

  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,

  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  DialogActions,
} from "@mui/material";


// third party
import * as Yup from "yup";

// project import
import { openSnackbar } from "api/snackbar";

import { useAppDispatch } from "hooks";
import { getAllCompanies } from "store/actions/main/companies";
import { SnackbarProps } from "types/snackbar";
import { addNewRecoResumes } from "store/actions/main/cv/recomendation/recommendation";
import { FormattedMessage } from "react-intl";

// types
const validationSchema = Yup.object().shape({
  recommender_name: Yup.string().required("Recommender Name is required"),
  position: Yup.string().required("Position id required"),
  company_name: Yup.string().required("Company Name id required"),
  job_title: Yup.string().required("Job Title is required"),
  mobile: Yup.string().required("Mobile is required"),

  email: Yup.string().required("Email Year is required"),
});
function RecommendationsDialog({
  cvId,
  openReco,
  setOpenReco,
  setLoading,
  loading,
  fetchGetOneReco,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneReco: any;
  cvId: any;
  openReco: boolean;
  setOpenReco: (open: boolean) => void;
}) {
  const handleClose = () => {
    setOpenReco(false);
  };
  const [recoData, setRecoData] = useState({
    resume_id: 0,
    user_id: 0,
    recommender_name: "",
    position: "",
    company_name: "",
    job_title: "",
    mobile: "",
    email: "",
    status: false,
    read_by_admin: false,
  });
  const [selectedStaus, setSelectedStatus] = useState(false);
  const [selectedRead, setSelectedRead] = useState(false);

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const fetchCompanyData = () => {
    dispatch(getAllCompanies({ pageNumber: 1 }))
      .then((action) => {
       

        // Extract user names
       // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  useEffect(() => {
    fetchCompanyData();
  }, [dispatch]);
  const [formErrors, setFormErrors] = useState<{
 
    recommender_name: string,
    position: string,
    company_name: string,
    job_title: string,
    mobile: string,
    email: string,
    status: boolean,
    read_by_admin: boolean,
  }>({
    
    recommender_name: "",
    position: "",
    company_name: "",
    job_title: "",
    mobile: "",
    email: "",
    status: false,
    read_by_admin: false,
  });
  const validate = async () => {
    try {
      // Combine all data to be validated
      const dataToValidate = {
        ...recoData,
        // Include selected job availability ID
        status: selectedStaus,
        read_by_admin: selectedRead,
      };

      // Validate the combined data against the validation schema
      await validationSchema.validate(dataToValidate, { abortEarly: false });
      setFormErrors({
    
        recommender_name: "",
        position: "",
        company_name: "",
        job_title: "",
        mobile: "",
        email: "",
        status: false,
        read_by_admin: false,
      });
      return true; // Return true if validation succeeds
    } catch (errors:any) {
      // If validation fails, extract error messages
      const errorMessages = {
    
        recommender_name: "",
        position: "",
        company_name: "",
        job_title: "",
        mobile: "",
        email: "",
        status: false,
        read_by_admin: false,
      };
      errors.inner.forEach((error) => {
        errorMessages[error.path] = error.message;
      });
      setFormErrors(errorMessages); // Set error messages for each field
      return false;
    }
  };

  const handleSave = async () => {
    // Log average value for debugging

    const isValid = await validate();
    if (isValid) setLoading(true);
    dispatch(
      addNewRecoResumes({
        resume_id: cvId,

        recommender_name: recoData.recommender_name,
        position: recoData.position,
        company_name: recoData.company_name,
        job_title: recoData.job_title,
        mobile: recoData.mobile,
        email: recoData.email,
        status: selectedStaus,
        read_by_admin: selectedRead,
      }),
    ).then((action) => {
      if (action.type === "recommendation/addNewRecoResumes/fulfilled") {
        openSnackbar({
          open: true,
          message: "Recommendation Added successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setLoading(false);
        setOpenReco(false);
        fetchGetOneReco();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setLoading(false);
      }
    });
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setRecoData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Dialog open={openReco} onClose={handleClose}>
     
        <DialogTitle style={{ fontWeight: "bold" }}><FormattedMessage id="references" /></DialogTitle>
        <Divider />

      <DialogContent>
     
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-recommender_name">
                  <FormattedMessage id="referencer-name" />
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-recommender_name"
                  value={recoData?.recommender_name || ""}
                  name="recommender_name"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder={intl.formatMessage({ id: "recomender-name", defaultMessage: "Recomender name" })}
                  autoFocus
                  //inputRef={inputRef}
                />
                {formErrors.recommender_name && (
                  <FormHelperText error>
                    {formErrors.recommender_name}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-position">
                  <FormattedMessage id="job-position" />
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-position"
                  value={recoData?.position || ""}
                  name="position"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder={intl.formatMessage({ id: "job-position", defaultMessage: "Job position" })}
                />
                {formErrors.position && (
                  <FormHelperText error>{formErrors.position}</FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-company_name">
                   <FormattedMessage id="company-name" />
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-company_name"
                  value={recoData?.company_name || ""}
                  name="company_name"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder={intl.formatMessage({ id: "company-name", defaultMessage: "Company Name" })}
                />
                {formErrors.company_name && (
                  <FormHelperText error>
                    {formErrors.company_name}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-job_title"><FormattedMessage id="job-title" /></InputLabel>
                <TextField
                  fullWidth
                  id="personal-job_title"
                  value={recoData?.job_title || ""}
                  name="job_title"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder={intl.formatMessage({ id: "job-title", defaultMessage: "Job title" })}
                />
                {formErrors.job_title && (
                  <FormHelperText error>{formErrors.job_title}</FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-mobile"><FormattedMessage id="mobile-number" /> </InputLabel>
                <TextField
                  type="mobile"
                  fullWidth
                  value={recoData?.mobile || ""}
                  name="mobile"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  id="personal-email"
                  placeholder={intl.formatMessage({ id: "mobile-number", defaultMessage: "Mobile Number" })}
                />
                {formErrors.mobile && (
                  <FormHelperText error>{formErrors.mobile}</FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-email"><FormattedMessage id="email" /></InputLabel>
                <TextField
                  fullWidth
                  id="personal-email"
                  value={recoData?.email || ""}
                  name="email"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder={intl.formatMessage({ id: "email", defaultMessage: "Email" })}
                />
                {formErrors.email && (
                  <FormHelperText error>{formErrors.email}</FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-staus"><FormattedMessage id="status" /></InputLabel>
                
                <Select
                  id="staus"
                  value={selectedStaus} // Set the selected ID as the value of the Select component
                  onChange={(event:any) => setSelectedStatus(event.target.value)} // Handle the change event
                >
                 {/*  @ts-ignore */} 
                  <MenuItem  value={true}><FormattedMessage id="true" /></MenuItem>  {/*  @ts-ignore */}
                  <MenuItem value={false}><FormattedMessage id="false" /></MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-staus"><FormattedMessage id="read-by-admin" /></InputLabel>
                <Select
                  id="read_by_admin"
                  value={selectedRead} // Set the selected ID as the value of the Select component
                  onChange={(event:any) => setSelectedRead(event.target.value)} // Handle the change event
                >
                  {/*  @ts-ignore */}
                  <MenuItem value={true} ><FormattedMessage id="true" /></MenuItem>{/*  @ts-ignore */}
                  <MenuItem value={false} ><FormattedMessage id="false" /></MenuItem>
                </Select>
              </Stack>
            </Grid>
          </Grid>
       
       
      </DialogContent>
      <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" onClick={handleSave} variant="contained">
                      <FormattedMessage id="add-reference" />
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={handleClose}>
                        <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
    </Dialog>
  );
}

export default RecommendationsDialog;
