// material-ui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
} from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";

import { ReplyApplicant } from "model/companyList/replyApp";
import {
  addReplayApplication,
  editReplyApplication,
} from "store/actions/main/companyList/replyApp";
import { FormattedMessage,useIntl } from "react-intl";

// constant
const getInitialValues = (customer: ReplyApplicant | null) => {
  if (customer) {
    return {
      message: customer.message || "",
      message_trans: customer.message_trans.ar || "",
      title: customer.title || "",
      title_trans: customer.title_trans.ar || "",
      status: customer.status||false,
    };
  } else {
    return {
      message: "",
      message_trans: "",
      title: "",
      title_trans: "",
      status: false,
    };
  }
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormReplyAppAdd = ({
  customer,
  id,
  closeModal,
  fetchData,
  setLoading,
  loading,
}: {
  loading: any;
  setLoading: any;
  fetchData: any;
  id: number;
  customer: ReplyApplicant | null;
  closeModal: () => void;
}) => {
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    message: Yup.string().required("English message is required"),
    
    title: Yup.string().required("English title is required"),

  });

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editReplyApplication({
              id: customer.id || 0,
              data: {
                // @ts-ignore
                message: values.message,
                message_trans:{
                  // @ts-ignore
                  ar:values.message_trans
                },
                // @ts-ignore
                title:values.title,
                // @ts-ignore
                title_trans: {
                  // @ts-ignore
                ar:values.title_trans
                },
                status: values.status,
            }}),
          ).then((action) => {
            fetchData();
            if (action.type === "companyList/editReplyApplication/fulfilled") {
              openSnackbar({
                open: true,
                message: "Reply application edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addReplayApplication({
            
            message: values.message,
          
            title:values.title,
            title_trans: {
             
            ar:values.title_trans
            },
            status: values.status,
            message_trans: {
            
              ar:values.message_trans
              }})).then((action) => {
            fetchData();
            if (action.type === "companyList/addReplayApplication/fulfilled") {
              openSnackbar({
                open: true,
                message: "Reply application added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? <FormattedMessage id="edit-reply-application" /> : <FormattedMessage id="new-reply-application" />}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container>
                  <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-en_title">
                        <FormattedMessage id="english-title" /><span style={{color:'red'}}>*</span>
                              <span style={{color:'grey', marginLeft:'5px'}}>
                                <Tooltip title={<FormattedMessage id="type-english-title" />}>
                                
                                  <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                                </Tooltip>
                              </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-en_title"
                          placeholder="Enter English Title"
                          {...getFieldProps("title")}
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                        />
                      </Stack>
                  </Grid>
                  <Grid md={1}/>
                  <Grid item xs={12} sm={5} mb={1}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="customer-ar_title">
                      <FormattedMessage id="arabic-title" /><span style={{color:'red'}}>*</span>
                              <span style={{color:'grey', marginLeft:'5px'}}>
                                <Tooltip title={<FormattedMessage id="type-arabic-title" />}>
                             
                                  <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                                </Tooltip>
                              </span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="customer-ar_title"
                        placeholder="Enter Arabic Title"
                        {...getFieldProps("title_trans")}
                     
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="customer-en_message">
                      <FormattedMessage id="english-message" /> <span style={{color:'red'}}>*</span>
                              <span style={{color:'grey', marginLeft:'5px'}}>
                                <Tooltip title={<FormattedMessage id="type-english-message" />}>
                                
                                  <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                                </Tooltip>
                              </span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="customer-en_message"
                        placeholder="Enter English Message"
                        {...getFieldProps("message")}
                      
                        multiline
                        rows={3}
                      />
                    </Stack>
                  </Grid>
                  <Grid md={1}/>
                  <Grid item xs={12} sm={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="customer-ar_message">
                      <FormattedMessage id="arabic-message" /> <span style={{color:'red'}}>*</span>
                              <span style={{color:'grey', marginLeft:'5px'}}>
                                <Tooltip title={<FormattedMessage id="type-arabic-message" />}>
                                  <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                                </Tooltip>
                              </span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="customer-ar_message"
                        placeholder="Enter Arabic Message"
                        {...getFieldProps("message_trans")}
                       
                        
                        multiline
                        rows={3}
                      />
                    </Stack>
                  </Grid>  
                  <Grid item xs={12} md={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-is_active">
                    <FormattedMessage id="status" />
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="set-the-country-status" />}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            id="customer-is_active"
                            checked={formik.values.status}
                            {...getFieldProps("status")}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              setFieldValue("status", checked);
                            }}
                          />
                        }
                        label={formik.values.status ? <FormattedMessage id="active" /> : <FormattedMessage id="inactive" />}
                      />
                    </FormControl>
                    {touched.status && errors.status && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{ pl: 1.75 }}
                      >
                        {errors.status}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>                
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                    {customer? <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : <FormattedMessage id="update" />}
                      </Button>:
                       <Button fullWidth type="submit" variant="contained">
                       {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : <FormattedMessage id="submit" />}
                     </Button>}
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                      <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormReplyAppAdd;
