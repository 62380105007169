

// material-ui

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";
// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";
import { VacancyOrder } from "model/order";
import {
  addNewVacancyOrder,
  editOneVacancyOrder,
} from "store/actions/main/order/vacancyOrder";
import { CircularProgress } from "@mui/material";
import { FormControl } from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// constant
const getInitialValues = (customer: VacancyOrder | null) => {
  const newCustomer = {
    user_id: "",
    urgent: false,
    vacancy_package_ids: [],
    show_logo: false,
    show_in_best_place: false,
    payment_id: "",
    show_in_app: false,
    amount_vacancies: "",
    price: "",
    status: false,
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormVacancyAdd = ({
  customer,
  id,
  userName,
  closeModal,
  fetchData,
  Packages,
  loading,
  setLoading,
}: {
  setLoading: any;
  loading: any;
  userName: any;
  Packages: any;
  id: number;
  fetchData: any;
  customer: VacancyOrder | null;
  closeModal: () => void;
}) => {
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    user_id: Yup.string().required("Company Name is required"),
    vacancy_package_ids: Yup.array().required("Vacancy Package is required"),
    //show_logo: Yup.boolean().required("Package Validation is required"),
    //status: Yup.string().required("Status is required"),
    price: Yup.number().required("Price is required"),
    amount_vacancies: Yup.number().required("Amount Vacancies is required"),
  });



 
  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const vacancyPackageIdsArray = Array.isArray(values.vacancy_package_ids)
        ? values.vacancy_package_ids
        : [values.vacancy_package_ids];
      try {
        if (customer) {
          await dispatch(
            editOneVacancyOrder({
              id: customer.id || 0,
              data: {
                user_id: values.user_id,
                vacancy_package_ids: vacancyPackageIdsArray,
                show_logo: values.show_logo,
                show_in_best_place: values.show_in_best_place,
                payment_id: values.payment_id,
                show_in_app: values.show_in_app,
                amount_vacancies: values.amount_vacancies,
                price: values.price,
                urgent: values.urgent,
                status: values.status,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "order/editOneVacancyOrder/fulfilled") {
              openSnackbar({
                open: true,
                message: "Vacancy Orders Updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            } else {
              openSnackbar({
                open: true,
                message: "some thing went wrong",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "error",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addNewVacancyOrder({
              user_id: values.user_id,
              vacancy_package_ids: vacancyPackageIdsArray,
              show_logo: values.show_logo,
              show_in_best_place: values.show_in_best_place,
              payment_id: values.payment_id,
              show_in_app: values.show_in_app,
              amount_vacancies: values.amount_vacancies,
              price: values.price,
              urgent: values.urgent,
              status: values.status,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "order/addNewVacancyOrder/fulfilled") {
              openSnackbar({
                open: true,
                message: "Vacancy Orders deleted successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            } else {
              openSnackbar({
                open: true,
                message: "some thing went wrong",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "error",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;



  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? "Edit vacancy order details" : "Add new vacancy order"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-user_id">
                          Company Name  <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Select company name for the vacancy order'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <Select
                          id="customer-user_id"
                          displayEmpty
                          {...getFieldProps("user_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = userName.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedUserName = selectedUser
                              ? selectedUser.user_name
                              : "";
                            setFieldValue("user_id", selectedId);
                            setFieldValue("user_name", selectedUserName); // Set user name value to another field
                          }}
                          value={getFieldProps("user_id").value || ""}
                        >
                          {userName &&
                            userName.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.user_name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.user_id && errors.user_id && (
                          <FormHelperText error>
                            {errors.user_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid md={1}/>                    
                    <Grid item xs={12} sm={3} mb={1}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="customer-vacancy_package_ids">
                            Packages <span style={{ color: 'red' }}>*</span>
                            <span style={{ color: 'grey', marginLeft: '5px' }}>
                              <Tooltip title={'Select packages type for the vacancy order'}>
                                <HelpOutlineOutlinedIcon
                                  style={{ width: '15px', height: '15px', cursor: 'pointer' }}
                                />
                              </Tooltip>
                            </span>
                          </InputLabel>
                          <Select
                            id="customer-vacancy_package_ids"
                            multiple
                            displayEmpty
                            {...getFieldProps("vacancy_package_ids")}
                            onChange={(event: SelectChangeEvent<string[]>) => {
                              const selectedIds = event.target.value as string[];
                              setFieldValue("vacancy_package_ids", selectedIds);
                            }}
                            value={getFieldProps("vacancy_package_ids").value || []}
                          >
                            {Packages &&
                              Packages.map((pkg: any) => (
                                <MenuItem key={pkg.id} value={pkg.id}>
                                  {pkg.type}
                                </MenuItem>
                              ))}
                          </Select>
                          {touched.vacancy_package_ids && errors.vacancy_package_ids && (
                            <FormHelperText error>
                              {/*  @ts-ignore */}
                              {errors.vacancy_package_ids}
                            </FormHelperText>
                          )}
                        </Stack>
                      </Grid>
                    <Grid md={1}/>
                    <Grid item xs={12} sm={3} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-payment_id">
                          Payment   <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Select payment type for the vacancy order'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <Select
                          id="customer-payment_id"
                          displayEmpty
                          {...getFieldProps("payment_id")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;
                            // Set the field value to the converted value
                            setFieldValue("payment_id", selectedValue);
                          }}
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-amount_vacancies">
                          Count Vacancies <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'type count for the vacancy order'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          type="number"
                          id="customer-amount_vacancies"
                          placeholder="Amount Vacancies"
                          {...getFieldProps("amount_vacancies")}
                          error={Boolean(
                            touched.amount_vacancies && errors.amount_vacancies,
                          )}
                          helperText={
                            touched.amount_vacancies && errors.amount_vacancies
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-price">Price
                        <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'type price for the vacancy order'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span></InputLabel>
                        <TextField
                          fullWidth
                          type="number"
                          id="customer-price"
                          placeholder="Enter Price"
                          {...getFieldProps("price")}
                          error={Boolean(touched.price && errors.price)}
                          helperText={touched.price && errors.price}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel>Urgent
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the Urgent value (Default no)'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <RadioGroup
                          row
                          value={getFieldProps("urgent").value}
                          onChange={(event) => {
                            setFieldValue("urgent", event.target.value);
                          }}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="true"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="false"
                          />
                        </RadioGroup>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel>Show in App
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the Show in App value (Default no)'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                          </InputLabel>
                        <RadioGroup
                          row
                          value={getFieldProps("show_in_app").value}
                          onChange={(event) => {
                            setFieldValue("show_in_app", event.target.value);
                          }}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="true"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="false"
                          />
                        </RadioGroup>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel>Show Logo
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the show logo value (Default no)'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <RadioGroup
                          row
                          value={getFieldProps("show_logo").value}
                          onChange={(event) => {
                            setFieldValue("show_logo", event.target.value);
                          }}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="true"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="false"
                          />
                        </RadioGroup>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel>Show in Best Place
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the Show in Best Place value (Default no)'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span></InputLabel>
                        <RadioGroup
                          row
                          value={getFieldProps("show_in_best_place").value}
                          onChange={(event) => {
                            setFieldValue(
                              "show_in_best_place",
                              event.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="true"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="false"
                          />
                        </RadioGroup>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-status">
                          Status
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the vacancy status (Default inactive)'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <FormControl fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                id="customer-status"
                                checked={formik.values.status}
                                {...getFieldProps("status")}
                                onChange={(event) => {
                                  const checked = event.target.checked;
                                  setFieldValue("status", checked);
                                }}
                              />
                            }
                            label={formik.values.status ? <FormattedMessage id="active" /> : <FormattedMessage id="inactive" />}
                          />
                        </FormControl>
                        {touched.status && errors.status && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{ pl: 1.75 }}
                          >
                            {errors.status}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : 'Submit'}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                        <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormVacancyAdd;
