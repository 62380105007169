import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS } from "api";
import responseError from "utils/api/error-handling";
import jobAvaURL from "../../../../utils/url/basicInfo/jobAva";

//get AllUser
export const getAllJobAva = createAsyncThunk(
  "basicInfo/getAllJobAvaURL",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${jobAvaURL.GET_JOBAVA_TYPE}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER
export const searshAllJobAva = createAsyncThunk(
  "basicInfo/getAllJobAvaURL",
  async ({ name_trans}: { name_trans: string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${jobAvaURL.GET_JOBAVA_TYPE}?name_trans=${name_trans}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const getOneJobAva = createAsyncThunk(
  "basicInfo/getOneJobAva",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${jobAvaURL.GET_ONE_JOBAVA__TYPE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editJobAva = createAsyncThunk(
  "basicInfo/editJobAva",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.post(
        `${jobAvaURL.EDIT_JOBAVA___TYPE}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addJobAva = createAsyncThunk(
  "basicInfo/addJobAva",
  async (data: any, thunkAPI) => {
    try {
      const response = await AXIOS.post(jobAvaURL.ADD_JOBAVA___TYPE, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deletJobAva = createAsyncThunk(
  "basicInfo/deletJobAva",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(
        `${jobAvaURL.DELETE_JOBAVA__TYPE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletJobAva = createAsyncThunk(
  "basicInfo/MultiDeletJobAva",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(jobAvaURL.MULTI_DELET_JOBAVA___TYPE, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
