const GET_USER = "jobSeekers";

const GET_ONE_USER = "jobSeekers/";

const EDIT_USER = "jobSeekers/edit/";

const ACIVATE_ACCOUNT = "jobseekers/activate/";

const DEACTIVATE_ACCOUNT = "jobseekers/deactivate/";

const MULTI_DELET = "jobseekers/remove/bulk_delete";

const FILTER_USER = "jobseekers/users/filter";

const SEARCH_USERS = "jobSeekers/users/search/";

const GET_TOTAL = "jobseekers/job_seeker/total";

export default {
  GET_USER,

  EDIT_USER,

  GET_ONE_USER,

  ACIVATE_ACCOUNT,

  GET_TOTAL,

  DEACTIVATE_ACCOUNT,

  MULTI_DELET,

  FILTER_USER,

  SEARCH_USERS,
};
