import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import { FormattedMessage,useIntl } from "react-intl";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ListIcon from "@mui/icons-material/List";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import SearchIcon from "@mui/icons-material/Search";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
const useCompanyItem = () => {
  const icons = {
    LocationCityOutlinedIcon,
    BusinessIcon,
    PeopleAltIcon,
    AccountBalanceIcon,
    ListIcon,
    SpeakerNotesOffIcon,
    DoNotDisturbIcon,
    MoneyOffIcon,
    SearchIcon,
    CalendarTodayIcon,
    ChatBubbleOutlineIcon,
    MarkChatUnreadIcon,
    FeedbackIcon,
    ReportGmailerrorredIcon,
    SettingsSuggestIcon,
  };

  const companyItems = {
    id: "group-countries",
    title: (
      <FormattedMessage id="companies">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "list-of-companies",
        title: <FormattedMessage id="listofcompanies" />,
        type: "item",
        url: "/list-of-companies",
        icon: icons.BusinessIcon,
        breadcrumbs: false,
      },
      {
        id: "clientcompanieslist",
        title: <FormattedMessage id="clientcompanieslist" />,
        type: "item",
        url: "/clientcompanieslist",
        icon: icons.ListIcon,
        breadcrumbs: false,
      },
      {
        id: "notclientcompanieslist",
        title: <FormattedMessage id="notclientcompanieslist" />,
        type: "item",
        url: "/not-client-companieslist",
        icon: icons.SpeakerNotesOffIcon,
        breadcrumbs: false,
      },
      {
        id: "undefinedcompanieslist",
        title: <FormattedMessage id="undefinedcompanieslist" />,
        type: "item",
        url: "/undefined-companieslist",
        icon: icons.DoNotDisturbIcon,
        breadcrumbs: false,
      },
      {
        id: "freecompanieslist",
        title: <FormattedMessage id="freecompanieslist" />,
        type: "item",
        url: "/free-companies-list",
        icon: icons.MoneyOffIcon,
        breadcrumbs: false,
      },
      {
        id: "companiessearch",
        title: <FormattedMessage id="companiessearch" />,
        type: "item",
        url: "/companies-search",
        icon: icons.SearchIcon,
        breadcrumbs: false,
      },

      {
        id: "companiesloggedtoday",
        title: <FormattedMessage id="companiesloggedtoday" />,
        type: "item",
        url: "/companies-logged-today",
        icon: icons.CalendarTodayIcon,
        breadcrumbs: false,
      },
      {
        id: "companiesloggedthroughtheweek",
        title: <FormattedMessage id="companiesloggedthroughtheweek" />,
        type: "item",
        url: "/companies-logged-through-week",
        icon: icons.CalendarTodayIcon,
        breadcrumbs: false,
      },
      {
        id: "suggestedcompanies",
        title: <FormattedMessage id="suggestedcompanies" />,
        type: "item",
        url: "/suggested-companies",
        icon: icons.SettingsSuggestIcon,
        breadcrumbs: false,
      },
      {
        id: "servicepersons",
        title: <FormattedMessage id="servicepersons" />,
        type: "item",
        url: "/service-persons",
        icon: icons.FeedbackIcon,
        breadcrumbs: false,
      },

      {
        id: "companiesdescription",
        title: <FormattedMessage id="companiesdescription" />,
        type: "item",
        url: "/companies-description",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },

      {
        id: "companiesreplies",
        title: <FormattedMessage id="companiesreplies" />,
        type: "item",
        url: "/companies-replies",
        icon: icons.MarkChatUnreadIcon,
        breadcrumbs: false,
      },
      {
        id: "companiesreports",
        title: <FormattedMessage id="companiesreports" />,
        type: "item",
        url: "/companies-reports",
        icon: icons.ReportGmailerrorredIcon,
        breadcrumbs: false,
      },
      {
        id: "commentsofcompanyprofile",
        title: <FormattedMessage id="commentsofcompanyprofile" />,
        type: "item",
        url: "/comments-of-company-profile",
        icon: icons.ChatBubbleOutlineIcon,
        breadcrumbs: false,
      },
    ],
  };

  return companyItems;
};

export default useCompanyItem;
