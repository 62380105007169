// material-ui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  FormControl,
  Switch,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";
import {
  addLanguage,
  editLanguage,
} from "store/actions/main/basicInfo/languages";
import { CircularProgress } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { BasicInfo } from "model/basicInfo";
import { FormHelperText } from "@mui/material";
import { FormattedMessage,useIntl } from "react-intl";

// constant
const getInitialValues = (customer: BasicInfo | null) => {
  if (customer) {
    return {
      name: customer.name || "",
      order: customer.order || 0,
      name_trans: customer.name_trans.ar || "",
      status: customer.status || false,
    };
  } else {
    return {
      name: "",
      name_trans:"",
      order: 0,
      status: false,
    };
  }
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormLanguageAdd = ({
  customer,
  closeModal,
  fetchData,
  setLoading,
  loading,
}: {
  loading: any;
  setLoading: any;
  fetchData: any;
  customer: BasicInfo | null;
  closeModal: () => void;
}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const CustomerSchema = Yup.object().shape({
    name: Yup.string().required("English Name is required"),
    name_trans: Yup.string().required("Arabic Name is required"),
    order: Yup.number().required("Order is required"),
  });
  console.log(customer);
  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editLanguage({
              id: customer.id || 0,
              data: {
                name: values.name,
                name_trans: {
                ar:values.name_trans
                },
                status:values.status
            
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "basicInfo/editLanguage/fulfilled") {
              openSnackbar({
                open: true,
                message: "Language edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addLanguage({
            name: values.name,
            name_trans: {
            ar:values.name_trans
            },
            status:values.status
        
          })).then((action) => {
            fetchData();
            if (action.type === "basicInfo/addLanguage/fulfilled") {
              openSnackbar({
                open: true,
                message: "Language added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? <FormattedMessage id="edit-language-details" /> : <FormattedMessage id="add-new-language" />}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name"><FormattedMessage id="english-name" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={ <FormattedMessage id="enter-english-name-for-the-language" />}>
                       
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="customer-name"
                      placeholder={intl.formatMessage({ id: "enter-english-name", defaultMessage: "Enter english name" })}
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                </Grid>
                <Grid item md={1}/>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name"><FormattedMessage id="arabic-name" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="enter-arabic-name-for-the-language" />}>
                        
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                        fullWidth
                        id="customer-name"
                        name="name_trans"
                        {...getFieldProps("name_trans")}
                        placeholder={intl.formatMessage({ id: "enter-arabic-name", defaultMessage: "Enter arabic name" })}
                        error={Boolean(touched.name_trans && errors.name_trans)}
                        
                      />
                  </Stack>
                </Grid>
                <Grid md={1}/>
                <Grid item xs={12} sm={5}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-order"><FormattedMessage id="order" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="type-the-number-of-orders-in-this-language" />}>
                        
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type="number"
                      id="customer-order"
                      placeholder={intl.formatMessage({ id: "enter.order", defaultMessage: "Enter order" })}
                      {...getFieldProps("order")}
                      error={Boolean(touched.order && errors.order)}
                      helperText={touched.order && errors.order}
                    />
                  </Stack>
                </Grid>
                <Grid md={1}/>
                <Grid item xs={12} md={5}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-is_active">
                    <FormattedMessage id="status" />
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="set-the-language-status-(Default=inactive)" />}>
                        
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            id="customer-is_active"
                            checked={formik.values.status}
                            {...getFieldProps("status")}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              setFieldValue("status", checked);
                            }}
                          />
                        }
                        label={formik.values.status ? <FormattedMessage id="active" /> : <FormattedMessage id="inactive" />}
                      />
                    </FormControl>
                    {touched.status && errors.status && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{ pl: 1.75 }}
                      >
                        {errors.status}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                     {customer? <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : <FormattedMessage id="update" />}
                      </Button>:
                       <Button fullWidth type="submit" variant="contained">
                       {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : <FormattedMessage id="submit" />}
                     </Button>}
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                      <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormLanguageAdd;
