// eslint-disable-next-line 
import { Fragment, MouseEvent, useEffect, useMemo, useState } from "react";

// material-ui
import { alpha, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,

  Chip,

  CircularProgress,

  Divider,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _debounce from "lodash/debounce";
// third-party
import {
  ColumnDef,
  HeaderGroup,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  useReactTable,
  SortingState,
  FilterFn,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";

// project-import
import ScrollX from "components/ScrollX";
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";

import { DebouncedInput, HeaderSort, IndeterminateCheckbox, RowSelection, SelectColumnSorting, TablePagination } from "components/@extended/react-table";

// types

import { LabelKeyObject } from "react-csv/lib/core";

// assets
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { CustomerList, type NoteTypeList } from "types/customer";
import { useAppDispatch } from "hooks";
import { MultiDeletnotes, getAllAdminNote } from "store/actions/main/adminNote";
import { Category } from "model/categories";
import Loader from "components/Loader";
import ExpandingCategoryDetail from "./ExpandingCategoryDetail";
import CustomerModal from "./CustomerModal";
import AlertCustomerDelete from "./AlertCustomerDelete";
import { FormattedMessage,useIntl } from "react-intl";
import { searshAllAdminNote } from "../../../../store/actions/main/adminNote";


export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // store the ranking info
  addMeta(itemRank);

  // return if the item should be filtered in/out
  return itemRank.passed;
};


// ==============================|| REACT TABLE - LIST ||============================== //

function ReactTable({
  countPage,
  setPageSize,
  pageSize,
  currentPage,
  handlePageChange,
  setResult,
  data,
  columns,
  fetchData,
  setCurrenttPage,
  modalToggler,
}: any) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "id",
      desc: true,
    },
  ]);
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  const table = useReactTable({
    data,

    columns,
    state: {
      sorting,
      rowSelection,
      globalFilter,
    },

    enableRowSelection: true,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    getRowCanExpand: () => true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    globalFilterFn: fuzzyFilter,
    debugTable: true,
  });

  const backColor = alpha(theme.palette.primary.lighter, 0.1);
  let headers: LabelKeyObject[] = [];
  columns.map(
    (columns: any) =>
      // @ts-ignore
      columns.accessorKey &&
      headers.push({
        label: typeof columns.header === "string" ? columns.header : "#",
        // @ts-ignore
        key: columns.accessorKey,
      }),
  );
  const dispatch = useAppDispatch();
  const [loadingSearsh, setloadingSearsh] = useState(false);
  const [searchedtext, setsearchedtext] = useState("");
  const selectedRowIds = useMemo(() => {
    // Get the selected rows from the table object
    const selectedRows = table
      .getSortedRowModel()
      .flatRows.filter((row) => row.getIsSelected());

    // Map over the selected rows to extract their IDs
    return selectedRows.map((row) => row.original.id);
  }, [table, rowSelection]);
  const handleMultiDelet = () => {
    dispatch(MultiDeletnotes({ ids: selectedRowIds }));
    fetchData();
  };
   const searshMials =(searchedtext)=>{
              setloadingSearsh(true)
               dispatch(searshAllAdminNote({title_trans:searchedtext})).then((action)=>{
                
                 setResult(action.payload.data);
                 setloadingSearsh(false)
               })
               }
  return (
    <MainCard content={false}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: 2,
          ...(matchDownSM && {
            "& .MuiOutlinedInput-root, & .MuiFormControl-root": {
              width: "100%",
            },
          }),
        }}
      >
        {/* @ts-ignore */}
        <Stack direction="row" spacing={2} alignItems="center">
<TextField   
     variant="outlined"
    
    onChange={(e)=>setsearchedtext(e.target.value)}
       
    placeholder={`Search ${data.length} records...`}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchOutlined/>
        </InputAdornment>
      ),
    }}
  />
  <Button
                 variant="contained"
                 
                 onClick={()=>searshMials(searchedtext)}
               > {loadingSearsh===false?"searsh":<CircularProgress size="1.5rem" style={{color:'white'}}/>}</Button>
               </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="center"
          sx={{ width: { xs: "100%", sm: "auto" } }}
        >
          {/* <SelectColumnSorting
              {...{
                getState: table.getState,
                getAllColumns: table.getAllColumns,
                setSorting,
              }}
            /> */}

          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="contained"
              startIcon={<PlusOutlined />}
              onClick={modalToggler}
            >
              <FormattedMessage id="add-new" />
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <ScrollX>
        <Stack>
          <RowSelection selected={Object.keys(rowSelection).length} />
          <TableContainer>
            <Table>
              <TableHead>
                {table
                  .getHeaderGroups()
                  .map((headerGroup: HeaderGroup<any>) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        if (
                          header.column.columnDef.meta !== undefined &&
                          header.column.getCanSort()
                        ) {
                          Object.assign(header.column.columnDef.meta, {
                            className:
                              header.column.columnDef.meta.className +
                              " cursor-pointer prevent-select",
                          });
                        }

                        return (
                          <TableCell
                            key={header.id}
                            {...header.column.columnDef.meta}
                            onClick={header.column.getToggleSortingHandler()}
                            {...(header.column.getCanSort() &&
                              header.column.columnDef.meta === undefined && {
                                className: "cursor-pointer prevent-select",
                              })}
                          >
                            {header.isPlaceholder ? null : (
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <Box>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                </Box>
                                {header.column.getCanSort() && (
                                  <HeaderSort column={header.column} />
                                )}
                              </Stack>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <TableRow>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          {...cell.column.columnDef.meta}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.getIsExpanded() && (
                      <TableRow
                        sx={{
                          bgcolor: backColor,
                          "&:hover": { bgcolor: `${backColor} !important` },
                        }}
                      >
                        <TableCell colSpan={row.getVisibleCells().length}>
                          <ExpandingCategoryDetail data={row.original} />
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <>
            <Divider />
            <Box sx={{ p: 2 }}>
              <TablePagination
                {...{
                  setPageSize: setPageSize,
                  setPageIndex: setCurrenttPage,
                  getState: table.getState,
                  getPageCount: () => countPage.last_page,
                  currentPage: currentPage,
                  handlePageChange: handlePageChange,
                  initialPageSize: pageSize,
                }}
              />
            </Box>
          </>
        </Stack>
      </ScrollX>
    </MainCard>
  );
}

// ==============================|| CUSTOMER LIST ||============================== //

const AdminResumeNoteType = () => {
  const theme = useTheme();

  //const { customersLoading, customers: lists } = useGetCustomer();

  const [open, setOpen] = useState<boolean>(false);

  const [customerModal, setCustomerModal] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any | null>(
    null,
  );
  const [selected, setSelected] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(!open);
  };
  const intl = useIntl();
  const columns = useMemo<ColumnDef<NoteTypeList>[]>(
    () => [
      
      {
        header: "#",
        accessorKey: "id",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {row?.index + 1}
              </Typography>
            </Stack>
          </Stack>
        ),
      },
      {
     
        header:()=>( <FormattedMessage id="title" />),
        accessorKey: "title",
        meta: {
          className: "cell-center",
        },
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },

      {
       
        header:()=>( <FormattedMessage id="title-arabic-name" />),
        accessorKey: "title_trans",
        meta: {
          className: "cell-center",
        },
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {row.original.title_trans.ar}
              </Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        
        header:()=>( <FormattedMessage id="message" />),
        accessorKey: "message",
        meta: {
          className: "cell-center",
        },
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as number}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        
        header:()=>( <FormattedMessage id="message-arabic-name" />),
        accessorKey: "message_trans",
        meta: {
          className: "cell-center",
        },
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {row.original.message_trans.ar}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header:intl.formatMessage({ id: "status", defaultMessage: "Status" }),
       accessorKey: "status",
       meta: {
         className: "cell-center",
       },
       cell: (cell) => {
         const status = cell.getValue();
         return (
           <Chip
             color={status === true ? "success" : "error"}
             label={status === true ? <FormattedMessage id="active" /> : "Inactive"}
             size="small"
             variant="light"
           />
         );
       },
     },
      {
        header: "Actions",
        meta: {
          className: "cell-center",
        },
        disableSortBy: true,
        cell: ({ row }) => {
          // @ts-ignore
          const collapseIcon =
            row.getCanExpand() && row.getIsExpanded() ? (
              <PlusOutlined
                style={{
                  color: theme.palette.error.main,
                  transform: "rotate(45deg)",
                }}
              />
            ) : (
              <EyeOutlined />
            );
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={0}
            >
              <Tooltip title="Edit">
                <IconButton
                  color="primary"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setSelectedCustomer(row.original);
                    setCustomerModal(true);
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    handleClose();
                    setSelected(row.original);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [theme],
  );
  const [result, setResult] = useState<Category[]>([]);
  const [countPage, setCountPage] = useState({
    current_page: 0,
    last_page: 0,
  });
  const [currentPage, setCurrenttPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); 
  const dispatch = useAppDispatch();
  const fetchData = () => {
    setLoading(true);
    dispatch(getAllAdminNote({ pageNumber: currentPage }))
      .then((action) => {
        const { data, meta } = action.payload;
        const fetchedUCountryData: Category[] = data;
        setResult(fetchedUCountryData);
        setLoading(false);
        setCountPage(meta);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching user:", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, dispatch]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setCurrenttPage(page); // Update the currentPage state when the page changes
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ReactTable
          pageSize={pageSize}
          setPageSize={setPageSize}
          data={result}
          columns={columns}
          setResult={setResult}
          modalToggler={() => {
            setCustomerModal(true);
            setSelectedCustomer(null);
          }}
          fetchData={fetchData}
          countPage={countPage}
          currentPage={currentPage}
          setCurrenttPage={setCurrenttPage}
          handlePageChange={handlePageChange}
        />
      )}
      <AlertCustomerDelete
        fetchData={fetchData}
        id={selected?.id}
        title={selected?.title}
        open={open}
        handleClose={handleClose}
      />
      <CustomerModal
        fetchData={fetchData}
        id={selected?.id}
        open={customerModal}
        modalToggler={setCustomerModal}
        customer={selectedCustomer}
      />
    </>
  );
};

export default AdminResumeNoteType;
