import { useEffect, useState } from "react";

// material-ui

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,

  MenuItem,

  Select,
  SelectChangeEvent,
  Stack,
 
  TextField,

} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { FormattedMessage,useIntl } from "react-intl";
// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports

import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets


// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";

import TextArea from "antd/es/input/TextArea";
import { VacancyModel } from "model/vacancy";
import {
  addVacancy,
  editVacancy,
} from "store/actions/main/vacaciens/vacaciens";
import { getAllCategories } from "store/actions/main/categories";
import { getAllUsers } from "store/actions/main/users";
import { getAllJobType } from "store/actions/main/basicInfo/jobTypes";
import { getAllDegree } from "store/actions/main/basicInfo/degree";

// constant
const getInitialValues = (customer: VacancyModel | null) => {
  const newCustomer = {
    user_id: 0,
    job_type_id: 0,
    degree_type_id: 0,
    title: "",
    company_description: "",
    hide_company_name: false,
    email: "",
    employees_total_numbers: 0,
    duration: "",
    heats: 0,
    country: "",
    city: "",
    responsibilities: "",
    qualifications: "",
    additional_req: "",
    benefits: "",
    gender: "",
    military_service: "",
    photo: false,
    resume_lang: "",
    vacancy_lang: "",
    smart_window: false,
    vip_company: false,
    best_place: false,
    status: false,
    expiration_date: "",
    requirements_pdf: "",
    details_pdf: "",
    external_url: "",
    salary: 0,
    salary_min: 0,
    salary_max: 0,
    categories: [],
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormVacancyManagmentAdd = ({
  customer,
  fetchData,
  id,
  closeModal,
}: {
  id: number;
  fetchData: any;
  customer: VacancyModel | null;
  closeModal: () => void;
}) => {

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);
  const [userName, setUserName] = useState<any>();
  const [type, setType] = useState<any>();
  const [degree, setDegree] = useState<any>();
  const CustomerSchema = Yup.object().shape({
    type: Yup.string().required("Type is required"),
    amount: Yup.number().required("Number is required"),
    price: Yup.number().required("Cost is required"),
    price_per_unit: Yup.number().required("Cost Per Unit is required"),
    package_validity: Yup.number().required("Validity Per Unit is required"),
    package_duration: Yup.number().required("Duration Per Unit is required"),
    order: Yup.number().required("Order Per Unit is required"),
    note: Yup.string().required("Note Per Unit is required"),
  });


  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);

  const fetchUserData = () => {
    dispatch(getAllUsers({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setUserName(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchTypeData = () => {
    dispatch(getAllJobType({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setType(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchDegreeData = () => {
    dispatch(getAllDegree({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setDegree(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchDataPackages = () => {
    setLoading(true);
    dispatch(getAllCategories({ pageNumber: 1 }))
      .then((action) => {
        setLoading(false);
        const { data } = action.payload;

        // Extract user names
        setSelectedCategory(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching users:", error);
      });
  };
  useEffect(() => {
    fetchTypeData();
    fetchDataPackages();
    fetchUserData();
    fetchDegreeData();
  }, [dispatch]);
  const handleCategoryChange = (event) => {
    const selectedId = event.target.value; // Get the selected ID
    setSelectedCategoryId(selectedId); // Set the selected ID to state
    const selectedItem = selectedCategory.find(
      (item) => item.id === selectedId,
    );
    if (selectedItem) {
       // @ts-ignore
      const selectedName = selectedItem.name;
    }
  };
  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
       // @ts-ignore
      const vacancyCategoriesArray = Array.isArray(values.categories)
        ? values.categories
        : [values.categories];
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editVacancy({ id: customer.id || 0, data: values }),
          ).then((action) => {
            fetchData();
            if (action.type === "vacancy/editVacancy/fulfilled") {
              openSnackbar({
                open: true,
                message: "Vacancy updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addVacancy(values)).then((action) => {
            fetchData();
            if (action.type === "vacancy/addVacancy/fulfilled") {
              openSnackbar({
                open: true,
                message: "Vacancy added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
 
    getFieldProps,
    setFieldValue,
  } = formik;
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px',}} >
              {customer ? "Edit Vacancy" : "New Vacancy"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={10}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-user_id">
                           <FormattedMessage id="company-name" />
                        </InputLabel>
                        <Select
                          id="customer-user_id"
                          displayEmpty
                          {...getFieldProps("user_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = userName.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedUserName = selectedUser
                              ? selectedUser.user_name
                              : "";
                            setFieldValue("user_id", selectedId);
                            setFieldValue("user_name", selectedUserName); // Set user name value to another field
                          }}
                          value={getFieldProps("user_id").value || ""}
                        >
                          {userName &&
                            userName.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.user_name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.user_id && errors.user_id && (
                          <FormHelperText error>
                            {errors.user_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-job_type_id">
                          Job Type
                        </InputLabel>
                        <Select
                          id="customer-job_type_id"
                          displayEmpty
                          {...getFieldProps("job_type_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = type.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedType = selectedUser
                              ? selectedUser.name
                              : "";
                            setFieldValue("job_type_id", selectedId);
                            setFieldValue("name", selectedType); // Set user name value to another field
                          }}
                          value={getFieldProps("job_type_id").value || ""}
                        >
                          {type &&
                            type.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.user_id && errors.user_id && (
                          <FormHelperText error>
                            {errors.user_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-degree_type_id">
                          Degree Type
                        </InputLabel>
                        <Select
                          id="customer-degree_type_id"
                          displayEmpty
                          {...getFieldProps("degree_type_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = degree.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedType = selectedUser
                              ? selectedUser.name
                              : "";
                            setFieldValue("degree_type_id", selectedId);
                            setFieldValue("name", selectedType); // Set user name value to another field
                          }}
                          value={getFieldProps("degree_type_id").value || ""}
                        >
                          {degree &&
                            degree.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.user_id && errors.user_id && (
                          <FormHelperText error>
                            {errors.user_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-category_ids">
                          Categories
                        </InputLabel>
                        <Select
                          id="category_id"
                          value={selectedCategoryId} // Set the selected ID as the value of the Select component
                          onChange={handleCategoryChange} // Handle the change event
                        >
                          {selectedCategory.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                              {user.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.user_id && errors.user_id && (
                          <FormHelperText error>
                            {errors.user_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-price_per_unit">
                          Price One Unit
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-price_per_unit"
                          placeholder="Enter Price One Unit"
                          {...getFieldProps("price_per_unit")}
                          error={Boolean(
                             // @ts-ignore
                            touched.price_per_unit && errors.price_per_unit,
                          )}
                          helperText={
                             // @ts-ignore
                            touched.price_per_unit && errors.price_per_unit
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-cost">Price</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-price"
                          placeholder="Enter Price"
                          {...getFieldProps("price")}
                           // @ts-ignore
                          error={Boolean(touched.price && errors.price)}
                           // @ts-ignore
                          helperText={touched.price && errors.price}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customr-package_validity">
                          Validity
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-package_validity"
                          placeholder="Enter Validity"
                          {...getFieldProps("package_validity")}
                          error={Boolean(
                             // @ts-ignore
                            touched.package_validity && errors.package_validity,
                          )}
                          helperText={
                             // @ts-ignore
                            touched.package_validity && errors.package_validity
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-package_duration">
                          Duration
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-package_duration"
                          placeholder="Enter Duration"
                          {...getFieldProps("package_duration")}
                          error={Boolean(
                             // @ts-ignore
                            touched.package_duration && errors.package_duration,
                          )}
                          helperText={
                             // @ts-ignore
                            touched.package_duration && errors.package_duration
                          }
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-order">Order</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-order"
                          placeholder="Order"
                          {...getFieldProps("order")}

                        
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-note">Note</InputLabel>
                        <TextArea
                          id="customer-note"
                          placeholder="Note"
                          {...getFieldProps("note")}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit"  variant="contained">
                      {customer ? <FormattedMessage id="edit" /> : "Add"}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                        <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>

        
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormVacancyManagmentAdd;
