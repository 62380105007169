import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import bankURL from "../../../../utils/url/basicInfo/bank";

//get AllUser
export const getAllBanks = createAsyncThunk(
  "basicInfo/getAllBanks",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${bankURL.GET_ALL_BANKS}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER
export const searshAllBanks = createAsyncThunk(
  "basicInfo/searshAllBanks",
  async ({ name_trans }: { name_trans: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${bankURL.GET_ALL_BANKS}?name_trans=${name_trans}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const getOneBanks = createAsyncThunk(
  "basicInfo/getOneBanks",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(`${bankURL.GET_ONE_BANKS}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editBank = createAsyncThunk(
  "basicInfo/editBank",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(`${bankURL.EDIT_BANKS}${id}`, data);
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addBank = createAsyncThunk(
  "basicInfo/addBank",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(bankURL.ADD_BANKS, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteBank = createAsyncThunk(
  "basicInfo/deleteBank",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(`${bankURL.DELETE_BANKS}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletBank = createAsyncThunk(
  "basicInfo/MultiDeletBank",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(bankURL.MULTI_DELET_BANKS, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
