import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import currencyURL from "../../../../utils/url/basicInfo/currency";

//get AllUser
export const getAllCurrency = createAsyncThunk(
  "basicInfo/getAllCurrency",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${currencyURL.GET_ALL_CURRENCY}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER
export const searshAllCurrency = createAsyncThunk(
  "basicInfo/searshAllCurrency",
  async ({ name_trans }: { name_trans: string}, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${currencyURL.GET_ALL_CURRENCY}?name_trans=${name_trans}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const getOneCurrency = createAsyncThunk(
  "basicInfo/getOneCurrency",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${currencyURL.GET_ONE_CURRENCY}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editCurrency = createAsyncThunk(
  "basicInfo/editCurrency",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${currencyURL.EDIT_CURRENCY}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addCurrency = createAsyncThunk(
  "basicInfo/addCurrency",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(currencyURL.ADD_CURRENCY, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteCurrency = createAsyncThunk(
  "basicInfo/deleteCurrency",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${currencyURL.DELETE_CURRENCY}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletCurrency = createAsyncThunk(
  "basicInfo/MultiDeletCurrency",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        currencyURL.MULTI_DELET_CURRENCY,
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
