import { CardHeader, Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LanguageDialog from "./Language";
import { TableLanguage } from "./TableLanguage";
import { FormattedMessage } from "react-intl";

const LanguageSection = ({
  cvId,
  basicInfo,
  setLoading,
  loading,
  openLanguage,
  setOpenLanguages,
  fetchGetOneLang,
}: {
  fetchGetOneLang: any;
  cvId: any;
  basicInfo: any;
  setLoading: any;
  loading: any;
  openLanguage: any;
  setOpenLanguages: (open: boolean) => void;
}) => {


  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack className="tabs-card-resume-container" direction={"column"}>
          { basicInfo.resume_languages?.length==0? <Button
          variant="contained"
          className="add-button-resume-section-without-data"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenLanguages(true)}
        >
          <FormattedMessage id="add-language" />
        </Button>: <Button
          variant="contained"
          className="add-button-resume-section"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenLanguages(true)}
        >
          <FormattedMessage id="add-language" />
        </Button>}
         
        
          <CardHeader className="tabs-card-resumes" title="Languages" />

          <Stack style={{ paddingLeft: "10px" }}>
            {/* Pass educationCertificates as prop to TableEducation */}
            <TableLanguage
              cvId={cvId}
              languageData={basicInfo}
              setLoading={setLoading}
              loading={loading}
              fetchGetOneLang={fetchGetOneLang}
            />
          </Stack>
        </Stack>

        {/* Other education-related fields */}
      </Stack>
    
      
        
        {openLanguage && (
          <LanguageDialog
            openLanguage={openLanguage}
            setOpenLanguages={setOpenLanguages}
            cvId={cvId}
            setLoading={setLoading}
            loading={loading}
            fetchGetOneLang={fetchGetOneLang}
          />
        )}
   
    </>
  );
};

export default LanguageSection;
