import { useEffect, useState } from "react";

// material-ui
import {


  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,

  MenuItem,
  FormControl,
  Select,
  Divider,
  DialogActions,
  Switch,
} from "@mui/material";

import { useAppDispatch } from "hooks";

import TextArea from "antd/es/input/TextArea";

import {
  GetChildCountries,
  ParentCountries,
} from "store/actions/main/countries";
import {
  editCourse,
  getOneCourse,
} from "store/actions/main/cv/courses/courses";
import { openSnackbar } from "api/snackbar";
import { SnackbarProps } from "types/snackbar";
import { FormattedMessage,useIntl } from "react-intl";

// types

function EditCourseDialog({
  open,
  cvId,
  setOpen,
  courseData,
  setCourseData,
  resumeId,
  setLoading,
  loading,
  fetchGetOneCourse,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneCourse: any;
  resumeId: any;
  cvId: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  courseData: any;
  setCourseData: any;
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useAppDispatch();
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [country, setCountry] = useState();

  const [childCities, setChildCities] = useState([]);
  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [certificate, setcertificate] = useState(false);
  const intl = useIntl();
  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value; // Get the selected country ID
    setSelectedCountry(selectedCountryId); // Update the selected country ID state

    // Find the selected country object
      // @ts-ignore
    const selectedCountryObject = country.find(
      (country) => country.id === selectedCountryId,
    );

    if (selectedCountryObject) {
      setSelectedCountryName(selectedCountryObject.name); // Set the selected country name

      // Set the selected city to "All Cities" if the selected country is not the first item in the list
      if (selectedCountryId == "1") {
        dispatch(GetChildCountries({ parent_id: 1 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        }); // Disable city selection

        setSelectedCity("");
      } else {
        dispatch(GetChildCountries({ parent_id: 2 })).then((action) => {
          const  data  = action.payload.data;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        });
        setSelectedCity("");
      }
    }
  };

  const fetchDataPackages = () => {
    dispatch(ParentCountries())
      .then((action) => {
        const  data  = action.payload.data;
        // Set user names as options for autocomplete
        setCountry(data);
        // Set default selected country to the first one in the list
        if (data.length > 0) {
          setSelectedCountry(data[0].id);
          setSelectedCountryName(data[0].name);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = childCities.find((city) => city.id === selectedCityId);
    setSelectedCity(selectedCityId);
    // Set the selected city name
    setSelectedCityName(selectedCity?.name || "");
  };
  useEffect(() => {
    fetchDataPackages();
    dispatch(GetChildCountries({ parent_id: 1 })).then((action) => {
      const  data  = action.payload.data;
      setChildCities(data);
      if (data.length > 0) {
        setSelectedCity(data[0].id);
        setSelectedCityName(data[0].name);
      }
      // Set fetched child cities to state
    });
  }, [dispatch]);

  const fetchGetOne = () => {
    dispatch(getOneCourse({ id: cvId })).then((action) => {
      const data = action.payload.data;
      console.log(action.payload,"action payload")
      setCourseData(data);

      setSelectedCountry(data?.country_id|| "");
      setSelectedCity(data?.city_id|| "");
    });
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setCourseData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchGetOne();
  }, [dispatch]);

  const handleSave = async () => {
    // Log average value for debugging
    setLoading(true);
    dispatch(
      editCourse({
        id: cvId,
        data: {
          resume_id: resumeId,
          institute: courseData.institute,
          course_name: courseData.course_name,
          country_id: selectedCountry,
          city_id: selectedCity,
          certificate_date: courseData.certificate_date,
          course_field: courseData.course_field,
          course_descriptions: courseData.course_descriptions,
          certificate: certificate,
          additional_info:courseData.additional_info,
          certificate: courseData.certificate,

        },
      }),
    ).then((action) => {
      if (action.type === "course/editCourse/fulfilled") {
        openSnackbar({
          open: true,
          message: "Course Updated successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);

        setOpen(false);

        setLoading(false);
        fetchGetOneCourse();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setLoading(false);
      }
    });
  };
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 90; i--) {
    years.push(i);
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      
        <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px' }}>
          <FormattedMessage id="education" /></DialogTitle>
        <Divider/>

      <DialogContent>
       
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="institute"><FormattedMessage id="institute" /></InputLabel>
                <TextField
                  fullWidth
                  id="personal-institute"
                  value={courseData?.institute || ""}
                  name="institute"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                   placeholder={intl.formatMessage({ id: "institute", defaultMessage: "Institute" })}
                  autoFocus
                  //inputRef={inputRef}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-course_name">
                
                  <FormattedMessage id="course-name" />
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-course_name"
                  value={courseData?.course_name || ""}
                  name="course_name"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                   placeholder={intl.formatMessage({ id: "course.name", defaultMessage: "Course name" })}
                  autoFocus
                  //inputRef={inputRef}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-course_field">
                  
                  <FormattedMessage id="course-field" />
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-course_field"
                  value={courseData?.course_field || ""}
                  name="course_field"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                   placeholder={intl.formatMessage({ id: "course.field", defaultMessage: "Course field" })}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="certificate_date">
                  
                  <FormattedMessage id="certificate-date" />
                </InputLabel>
                <Select
                  fullWidth
                  id="certificate_date"
                  value={courseData?.certificate_date || ""}
                    // @ts-ignore
                  onChange={handleInputChange}
                  name="certificate_date"
                   placeholder={intl.formatMessage({ id: "certificate.date", defaultMessage: "Certificate Date" })}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <Stack direction={"row"} display={"flex"}>
                  <InputLabel htmlFor="country"><FormattedMessage id="country" /></InputLabel>
                  <InputLabel style={{ marginLeft: "130px" }} htmlFor="city">
                   
                    <FormattedMessage id="city" />
                  </InputLabel>
                </Stack>
                <Stack direction={"row"}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    // alignItems="center"
                    spacing={2}
                  >
                    <FormControl fullWidth>
                      <Select
                        style={{ width: "140px" }}
                        value={selectedCountry || courseData.country_id}
                        onChange={handleCountryChange}
                        //  onBlur={handleBlur}
                        inputProps={{
                          name: "country",
                          id: "country",
                        }}
                      >
                        {country &&
                          // @ts-ignore
                          country.map((country: any) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <FormControl fullWidth>
                      <Select
                        style={{ marginLeft: "20px", width: "140px" }}
                        labelId="city-label"
                        id="city"
                        value={selectedCity}
                        onChange={handleCityChange}
                        // onBlur={handleBlur}
                        name="city"
                      >
                        {childCities.map((city) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
              
                <InputLabel>
                <FormattedMessage id="certificate" />
                </InputLabel>
                <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            id="customer-is_active"
                            checked={certificate}
                            
                            onChange={(event) => {
                              const checked = event.target.checked;
                              setcertificate(checked);
                            }}
                          />
                        }
                        label={certificate ? <FormattedMessage id="active" /> : <FormattedMessage id="inactive" />}
                      />
                    </FormControl>
                {/* <RadioGroup
                  row
                  value={certificate}
                  onChange={(event) => setcertificate(event.target.value)}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    value="true"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="No"
                    value="false"
                  />
                </RadioGroup> */}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-course_descriptions">
                 
                  <FormattedMessage id="course-descriptions" />
                </InputLabel>
                <TextArea
                  id="personal-course_descriptions"
                  value={courseData?.course_descriptions || ""}
                  name="course_descriptions"
                  rows={3}
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                   placeholder={intl.formatMessage({ id: "course.description", defaultMessage: "Course description" })}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-additional_info">
                
                  <FormattedMessage id="additional-info" />
                </InputLabel>
                <TextArea
                  id="personal-additional_info"
                  value={courseData?.additional_info || ""}
                  name="additional_info"
                  rows={3}
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder={intl.formatMessage({ id: "additional.info", defaultMessage: "Additional Info" })}
                />
              </Stack>
            </Grid>
          </Grid>
         
    
      </DialogContent>
      <Divider/>
      <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" onClick={handleSave} variant="contained">
                      <FormattedMessage id="edit" />
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={handleClose}>
                       <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
    </Dialog>
  );
}

export default EditCourseDialog;
