const GET_ALL_REPLY_APP = "reply_to_application";

const GET_ONE_REPLY_APP = "reply_to_application/";

const EDIT_REPLY_APP = "reply_to_application/";

const ADD_REPLY_APP = "reply_to_application";

const DELETE_REPLY_APP = "reply_to_application/";

const MULTI_DELETE_REPLY_APP = "reply_to_application/remove/bulk_delete";

export default {
  GET_ONE_REPLY_APP,
  GET_ALL_REPLY_APP,
  EDIT_REPLY_APP,
  ADD_REPLY_APP,
  DELETE_REPLY_APP,
  MULTI_DELETE_REPLY_APP,
};
