import { useEffect, useState } from "react";
import { useLocation} from "react-router";
// material-ui


import { Formik } from "formik";
import {
  Box,
  Button,
  Autocomplete,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  FormControl,

  MenuItem,

  Select,

  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Checkbox,
  
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _ from "lodash";
import * as Yup from "yup";


// project imports

import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets
import {ThemeDirection} from 'types/config';
import useConfig from 'hooks/useConfig';
// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";


import TextArea from "antd/es/input/TextArea";

import {
  addVacancy,
  editVacancy,
  getOneVacancy,
} from "store/actions/main/vacaciens/vacaciens";

import { getAllJobTypeSelect } from "store/actions/main/basicInfo/jobTypes";
import { getAllDegreeSelect } from "store/actions/main/basicInfo/degree";
import MainCard from "components/MainCard";
import {  fieldVacancyConfigurations } from "pages/extra-pages/resumes/profileList";
import { GetChildCountries, GetCitiesSelect, ParentCountrieSelect } from "store/actions/main/countries";
import { useParams } from "react-router";
import { getAllJobActivities } from "store/actions/main/vacaciens/jobActivities";

import { getAllCompanies } from "store/actions/main/companies";
import { FormattedMessage,useIntl } from "react-intl";
import { ParentCountries } from "../../../../store/actions/main/countries";
import { searshAllCompanies } from "../../../../store/actions/main/companies";
import { getAllDegree } from "../../../../store/actions/main/basicInfo/degree";
import { getAllJobType } from "../../../../store/actions/main/basicInfo/jobTypes";
// constant


// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormVacancyManagmentEdit = () => {
    const location = useLocation();
  const pathname = window.location.pathname;
const params=window.location.pathname
console.log(params,"params");
console.log(location.search,"searsh");


  // Split the pathname into segments using "/"
  const segments = pathname.split("/");
const langName=location.search.split("?Lang:")
const vacancyLang=langName[langName?.length-1]
console.log(langName,"langname");
console.log(vacancyLang,"vacancy lang");


  // Extract the resume ID (second-to-last segment) and user ID (last segment)

  const vacancyId = segments[segments?.length - 1];
  console.log(vacancyId,"vacnacy id");

  
 
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(true);
const [customer, setcustomer] = useState<any>();
const [isMilitaryServiceDisabled, setIsMilitaryServiceDisabled] =
useState(false);
 // @ts-ignore
const [selectedCountryName, setSelectedCountryName] = useState("");

const [selectedCity, setSelectedCity] = useState("");
const [country, setCountry] = useState<any>();
const [selectedCountry, setSelectedCountry] = useState("");
const [firstCountryItem, setfirstCountryItem] = useState<any>();
 // @ts-ignore
const [selectedCityName, setSelectedCityName] = useState("");
const [selectedJopType, setselectedJopType] = useState<any>();
const [selectedDegree, setselectedDegree] = useState<any>();
const [childCities, setChildCities] = useState([]); 
 // @ts-ignore
const [selectedUser, setselectedUser] = useState("");
const [selectedUserId, setselectedUserId] = useState<any>("");
const [eductationDegree, seteductationDegree] = useState<any>();
const [isEmployerNameHide, setisEmployerNameHide] = useState(false);
const [isPhoto, setisPhoto] = useState(false);
const [jobActitivities, setjobActitivities] = useState([]);
const [selectedActivities, setselectedActivities] = useState<any>([]);
// @ts-ignore
const [isPromotionAvailable, setisPromotionAvailable] = useState(false);

const [selectedSalaryType, setselectedSalaryType] = useState<any>();
const [salaryTypeMetaMin, setsalaryTypeMetaMin] = useState(0);
const [salaryTypeMetaMax, setsalaryTypeMetaMax] = useState(0);
const [salaryTypeMetaValue, setsalaryTypeMetaValue] = useState<any>(0);
const {  themeDirection } = useConfig();

let initialTheme = 'default';
if (themeDirection === ThemeDirection.RTL) initialTheme = 'rtl';
const { onChangeDirection} = useConfig();

let { Lang } = useParams();
console.log(Lang,"lang");



console.log(customer,"customer");
  useEffect(() => {
    setLoading(false);
  }, []);
 
  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = childCities.find((city) => city?.id === selectedCityId);
    setSelectedCity(selectedCityId);
    // Set the selected city name
    setSelectedCityName(selectedCity?.name || "");
  };
  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value; // Get the selected country ID
    setSelectedCountry(selectedCountryId); // Update the selected country ID state

    // Find the selected country object
    const selectedCountryObject = country.find(
      (country) => country?.id === selectedCountryId,
    );
    console.log("Selected Country Object:", selectedCountryObject); // Add this line for debugging
    console.log("Selected Country ID:", selectedCountryId); // Add this line for debugging

    if (selectedCountryObject) {
      setSelectedCountryName(selectedCountryObject.name); // Set the selected country name
     
      // Set the selected city to "All Cities" if the selected country is not the first item in the list
    
        dispatch(GetChildCountries({ parent_id: selectedCountryId })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        });
        setSelectedCity("");
      
    }
  };

useEffect(() => {
  if(vacancyLang==="AR"){
   
    
    onChangeDirection(ThemeDirection.RTL)
    
}
else{
    
    onChangeDirection(ThemeDirection.LTR)
  
}
}, [vacancyLang]);
  useEffect(() => {
    console.log(vacancyId,"vacancy id");
    if(vacancyId!=="0"){
      console.log("im if");
      
    dispatch(getOneVacancy({id:vacancyId})).then((action)=>{
console.log(action.payload,"data vacancy");
 
        const  data  = action.payload.data;
        console.log(data,"vacancy data");
        setvacancyData(data)
        setselectedJopType(data.job_type.id);
        setselectedSalaryType(data.salary_type);
      seteductationDegree(data.degree_type.id)
        setSelectedCountry(vacancyData?.country);
        setSelectedCity(vacancyData.city);
       
        
      setcustomer(data)  
    })}
  }, [dispatch]);
  const [userName, setUserName] = useState([]);
  const [type, setType] = useState<any>();
  const [degree, setDegree] = useState<any>();
  const [vacancyData, setvacancyData] = useState({
    id:0,
    user_id: 0,
    job_type: "",
    degree_type_id: "",
    title: "",
    company_description: "",
    hide_company_name: false,
    email: "",
    employees_total_numbers: "0",
    salary_type:"",
    duration: "",
    heats: 0,
    country: "",
    city: "",
    responsibilities: "",
    qualifications: "",
    additional_req: "",
    benefits: "",
    gender: "",
    military_service: "",
    photo: false,
    resume_lang: "",
    vacancy_lang: "",
    smart_window: false,
    vip_company: false,
    best_place: false,
    status: false,
    job_activities:[],

    expiration_date: "",
    requirements_pdf: "",
    details_pdf: "",
    external_url: "",
    salary: 0,
    salary_min: 0,
    salary_max: 0,
    categories: [],
    salary_type_meta:{
      end_range:"",
      start_range:"",
      value:""
    }

  });
  
  const validationSchema = Yup.object().shape({
    company_description:
      vacancyData?.company_description === ""
        && Yup.string().required("Company Description is required"),
        email: vacancyData?.email=== ""
        &&  Yup.string().required("Email is required"),
       
        employees_total_numbers:
      vacancyData?.employees_total_numbers === ""
        && Yup.string().required("Employee total number is required")
        ,
        duration:
      vacancyData?.duration === ""
        && Yup.date().required("Ad period is required")
        ,
       
        qualifications:
      vacancyData?.qualifications=== ""
        
        && Yup.string().required("Phone number must be a number")
          ,
          responsibilities:
          vacancyData?.responsibilities=== ""
            
            && Yup.string().required("Phone number must be a number")
              ,
              additional_req:
          vacancyData?.additional_req=== ""
            
            && Yup.string().required("Phone number must be a number")
              ,
              benefits:
      vacancyData?.benefits=== ""
        
        && Yup.number().required("Salary must be a number"),
      
    // Add more fields as needed
    // Add more fields as needed

    // Add more fields as needed
  });

  const handleChange = (event: React.ChangeEvent<any>) => {
    const { name, value, type, checked } = event.target;

    // Handle different input types
    const newValue = type === "checkbox" ? checked : value;

    console.log(newValue,"new value");
    
    console.log(name,"name");
    
    // Update profileData state with the new value
    setvacancyData((prevProfileData) => ({
      ...prevProfileData,
      [name]: newValue,
    }));
console.log(vacancyData,"updates");

    // Toggle military service field based on selected gender
    if (name === "gender" && value === "FEMALE") {
      setIsMilitaryServiceDisabled(true);
    } else if (name === "gender" && value === "MALE") {
      setIsMilitaryServiceDisabled(false);
    }
  };
  const fetchUserData = () => {
    dispatch(searshAllCompanies({name:"" }))
      .then((action) => {
        const { data } = action.payload;
const autoCompleteArrary=[]
        // Extract user names
     // Set user names as options for autocomplete
data.map((user:any)=>
  (autoCompleteArrary.push({id:user.id,label:user.user_name}))
 
)

setUserName(autoCompleteArrary)
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchTypeData = () => {
    dispatch(getAllJobType({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setType(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
      dispatch(getAllJobActivities({pageNumber:1})).then((action)=>{
        const {data} = action.payload;
        setjobActitivities(data)
      }).catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchDegreeData = () => {
    dispatch(getAllDegree({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setDegree(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchDataPackages = () => {
    
    dispatch(ParentCountries())
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setCountry(data);
        // Set default selected country to the first one in the list
        setSelectedCountry(data[0].id);
        setSelectedCountryName(data[0].name)
        const firstItem=data[0].id
        if (data.length > 0) {
          dispatch(GetChildCountries({ parent_id: firstItem })).then((action) => {
            const { data } = action.payload;
            setChildCities(data);
            if (data.length > 0) {
              setSelectedCity(data[0].id);
              setSelectedCityName(data[0].name);
            }
            // Set fetched child cities to state
          });
          setSelectedCountryName(data[0].name);
         
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  useEffect(() => {
    fetchTypeData();
    fetchDataPackages();
    
    fetchUserData();
    fetchDegreeData();
  }, [dispatch]);

  
  const handleCompanyChange=(name)=>{
    
      dispatch(searshAllCompanies({name:name }))
        .then((action) => {
          const { data } = action.payload;
  const autoCompleteArrary=[]
          // Extract user names
       // Set user names as options for autocomplete
  data.map((user:any)=>
    (autoCompleteArrary.push({id:user.id,label:user.user_name}))
   
  )
  
  setUserName(autoCompleteArrary)
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    
  }
 

  
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
  <Formik
                initialValues={{
                 
                  company_description: vacancyData?.company_description,
                  email:vacancyData?.email||"",
                  employees_total_numbers: vacancyData?.employees_total_numbers||"",
                  duration: vacancyData?.duration||"",
                 
                  responsibilities:vacancyData?.responsibilities||"",
                  qualifications:vacancyData?.qualifications||"",
                  additional_req:vacancyData?.additional_req||"",
                  benefits:vacancyData?.benefits||"",
                  title:vacancyData?.title||"",
                city:vacancyData?.city||"",
                  job_type_id:vacancyData?.job_type,
                  degree_type_id:vacancyData?.degree_type_id

                }}
                validationSchema={validationSchema} // Pass the validation schema to Formik
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values,"values");
                  
                  // Check if account_photo is an image
                 
                    // Prepare FormData object

                    const formData = new FormData();
                      console.log(vacancyData,"vacancy data");
                                         
                    formData.append("title", vacancyData?.title);
                    formData.append("user_id", selectedUserId.id);
                    console.log(formData,"form data");
                    
                    formData.append(
                      "email",
                      vacancyData?.email,
                    );
                    formData.append(
                      "company_description",
                      vacancyData?.company_description,
                    );
                    formData.append("employees_total_numbers", vacancyData?.employees_total_numbers);
                    formData.append("duration", vacancyData?.duration);
                    formData.append("country_id", selectedCountry);
                    
                     formData.append("city_id", selectedCity);
                     formData.append("degree_type_id", eductationDegree);
 // @ts-ignore
                     formData.append("job_activity_ids[0]", 1);
                    //  selectedActivities.map((activity:any,index)=>{
                    //   formData.append(`job_activity_ids[${index}]`, Number(activity));
                    //  })
                    //  [1,2].forEach((item) => formData.append("job_activity_ids[]", item));
                    formData.append(
                      "job_type_id",
                      selectedJopType,
                    );
                    formData.append(
                      "gender",
                      vacancyData?.gender,
                    );
                    formData.append(
                      "salary_type",
                      selectedSalaryType,
                    );
                  
                    
               


                    formData.append(
                      "resume_lang",
                      vacancyData.resume_lang,
                    );
                    
                    formData.append(
                      "vacancy_lang",
                      vacancyLang
                    );
                    
                    
                    formData.append(
                      "military_service",
                     vacancyData.military_service,
                    );
                    formData.append(
                      "responsibilities",
                      vacancyData.responsibilities,
                    );
                    formData.append(
                      "qualifications",
                      vacancyData.qualifications,
                    );
                    
                    
                    //   formData.append("confidetial", profileData.confidetial);
                    // Assuming profileData.account_photo is a File object or a Blob
                    formData.append("additional_req", vacancyData.additional_req);
                    formData.append("benefits", vacancyData.benefits);
                   

                   // Assuming code is an object with only one key-value pair

                    // Concatenate country code and phone number
                   
                    const formDataObject = Object.fromEntries(formData.entries());
                    console.log(formDataObject,"ob string3");
                    
                  let newData=formDataObject 
                  newData={
                    ...formDataObject,
                    // @ts-ignore
                    "photo":vacancyData.photo,
                     // @ts-ignore
                    "vip_company":vacancyData.vip_company,
                     // @ts-ignore
                    "smart_window":vacancyData.smart_window,
                     // @ts-ignore
                    "best_place":vacancyData.best_place,
                   "hide_company_name":isEmployerNameHide,
                    "salary_type_meta":{
                       // @ts-ignore
                      "end_range":salaryTypeMetaMax,
                      "start_range":salaryTypeMetaMin,
                      "value":salaryTypeMetaValue
                    }
                  }


                  
                  
                   
                    if (!customer) {
                  
                      
                      dispatch(addVacancy(newData)).then(
                        (action) => {
                          if (
                            action.type ===
                            "jobSeeker/addNewJobSeekerProfile/fulfilled"
                          ) {
                            openSnackbar({
                              open: true,
                              message: "profile added successfully",
                              anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                              },
                              variant: "alert",
                              alert: {
                                color: "success",
                              },
                            } as SnackbarProps);
                            setSubmitting(false);
                          }
                        },
                      );
                    } else {
                      dispatch(
                        editVacancy({
                          id: vacancyId,
                          data: formData,
                        }),
                      ).then((action) => {
                        if (
                          action.type ===
                          "jobSeeker/editJobSeekerProfile/fulfilled"
                        ) {
                          openSnackbar({
                            open: true,
                            message: "profile Updated successfully",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                            variant: "alert",
                            alert: {
                              color: "success",
                            },
                          } as SnackbarProps);
                          setSubmitting(false);
                        }
                      });
                    }

                    // Send FormData with Axios
                    // Set file error message
                   // Your form submission logic here
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <>
                  <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px',}} >
              {customer ? <FormattedMessage id="edit-vacancy" /> : <FormattedMessage id="new-vacancy" />}
             
            </DialogTitle>
            <Divider />    
                  <Stack direction={"row"}>
                     
                     <Grid style={{ height: "100px", marginRight: "20px", width: "50%" }}>
                        
        <MainCard
          content={false}
          title="Settings"
          sx={{ "& .MuiInputLabel-root": { fontSize: "0.875rem" } }}
          style={{paddingLeft:"15px",paddingRight:"15px",paddingBottom:"15px",marginBottom:"15px"
          }}
        >
 <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="email" style={{marginTop:"10px"}} >
                        
                          <FormattedMessage id="hide-employeer-name" />
                        </InputLabel>
                        <FormControl fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                id="hide_company_name"
                                checked={isEmployerNameHide}
                               
                                onChange={(event) => {
                                  const checked = event.target.checked;
                                  setisEmployerNameHide(checked)
                                }}
                              />
                            }
                            label={isEmployerNameHide ? <FormattedMessage id="active" /> : <FormattedMessage id="inactive" />}
                          />
                        </FormControl>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="email">
                          
                          <FormattedMessage id="photo" />
                        </InputLabel>
                        <FormControl fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                id="photo"
                                checked={isPhoto}
                               name="photo"
                                onChange={(event) => {
                                  const checked = event.target.checked;
                                  setisPhoto(checked)
                                }}
                              />
                            }
                            label={isPhoto ? <FormattedMessage id="required" /> : <FormattedMessage id="doesnt-matter" />}
                          />
                        </FormControl>
                      </Stack>
                    </Grid>
                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                             
                                <InputLabel htmlFor="military_service">
                               
                                <FormattedMessage id="requested-resume-language" />
                                </InputLabel>
                                
                            
                            
                                  <FormControl fullWidth>
                                    <Select
                                      
                                      value={vacancyData?.resume_lang}
                                      onChange={(e:any)=>handleChange(e)}
                                      onBlur={handleBlur}
                                      
                                      name="resume_lang"
                                    >
                                      <MenuItem value="EN">
                                        <FormattedMessage id="english" />
                                      </MenuItem>
                                      <MenuItem value="AR">
                                        <FormattedMessage id="arabic" />
                                      </MenuItem>
                                      
                                    </Select>
                                  </FormControl>
                              
                            </Stack>
                          </Grid>
        </MainCard>
        <MainCard
          content={false}
          title={intl.formatMessage({ id: "additional-promotions", defaultMessage: "Additional Promotions" })}
          sx={{ "& .MuiInputLabel-root": { fontSize: "0.875rem" } }}
          style={{paddingLeft:"15px",paddingRight:"15px",paddingBottom:"15px",marginBottom:"15px"
          }}
        >
<Grid item xs={12} sm={6} style={{marginTop:"10px"}}>
                      <Stack spacing={1}>
                      
                  <FormControlLabel
                    control={
                      <Checkbox  name="smart_window"
                        checked={vacancyData?.smart_window}
                        onChange={handleChange}
                        
                      />
                    }
                    
                    label="Publish this vacancy on Hot Jobs Bar"
                  />
               
                        </Stack></Grid>
                        <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                      
                  <FormControlLabel
                    control={
                      <Checkbox   name="vip_company"
                        checked={vacancyData?.vip_company}
                        onChange={handleChange}
                        
                      />
                    }
                    label="Posting Company logo on home page under Top Employers category"
                  />
               
                        </Stack></Grid>
                        <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                      
                  <FormControlLabel
                    control={
                      <Checkbox name="best_place"
                        checked={vacancyData?.best_place}
                        onChange={handleChange}
                        
                      />
                    }
                    label="Post my company name on home page (Best Place to Work)"
                  />
               
                        </Stack></Grid>
          
        </MainCard>
                     </Grid>
                     <Stack className="main-resume-container" direction={"column"}>
                  <form className="form-vacancy-container" noValidate onSubmit={handleSubmit}>
                     
    
        <LocalizationProvider>
          
           
            <MainCard style={{marginTop:"0px"}} title="Employer Information" >
            <Grid item xs={12} md={12}>
                  <Grid container spacing={3}>
                    
                  {isEmployerNameHide===false &&
                  <Grid item xs={12} sm={6}>
                     <Stack spacing={1}>
                     <InputLabel htmlFor="customer-user_id">
                        
                         <FormattedMessage id="company-name" />
                       </InputLabel>
                      
                       <Autocomplete
                         id="customer-user_id"
                      
                     
                      
                         onChange={(event,newValue) => {
                           console.log(newValue,"new vlue");
                           
                           const selectedId = newValue;
                           const selectedUser = userName.find(
                             (user) => user?.id === selectedId?.id,
                           );
                          
                           handleCompanyChange(selectedUser?.user_name)
                           
                             if(selectedUser){
                             setselectedUser(selectedUser?.user_name)
                             setselectedUserId(newValue)
                             }
                         // Set user name value to another field
                         }}

                         value={selectedUserId}
                         
                        options={userName}
                        renderInput={(params)=>(<TextField {...params} label="Select Company"  />)}
                       
                     />
                     </Stack></Grid>}
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="email">
                         
                          <FormattedMessage id="email" />
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="email"
                          type="email"
                          value={vacancyData?.email}
                          name="email" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                        
                          error={touched?.email && Boolean(errors?.email)} 
                          helperText={touched?.email && errors?.email}
                          placeholder={intl.formatMessage({ id: "email", defaultMessage: "Email" })}
                          
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="company_description">
                          
                          <FormattedMessage id="company-description" />
                        </InputLabel>
                        <TextArea
                          rows={4}
                          id="company_description"
                          value={vacancyData?.company_description}
                          name="company_description" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                         
                         
                          placeholder={intl.formatMessage({ id: "company-description", defaultMessage: "Company description" })}
                          
                        />
                        {touched?.company_description &&
                                        errors?.company_description && (
                                          <FormHelperText error>
                                            {errors?.company_description}
                                          </FormHelperText>
                                        )}
                      </Stack>
                    </Grid>
                      </Grid>
                   </Grid>

            </MainCard>
            <MainCard title="Job Vacancy Information">
            <Grid item xs={12} md={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                      <InputLabel htmlFor="customr-package_validity">
                         
                          <FormattedMessage id="employee-total-number" />
                        </InputLabel>
                        <TextField
                          fullWidth
                          
                          value={vacancyData?.employees_total_numbers}
                          name="employees_total_numbers" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 10 }}
                          type="number"
                          placeholder={intl.formatMessage({ id: "employees-total-number", defaultMessage: "Employees total number" })}
                          error={touched?.employees_total_numbers && Boolean(errors?.employees_total_numbers)} 
                          helperText={touched?.employees_total_numbers && errors?.employees_total_numbers}
                          
                        />
                        </Stack></Grid>
                        <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                      <InputLabel htmlFor="customr-package_validity">
                    
                         <FormattedMessage id="ad-period" />
                        </InputLabel>
                        
                        <Select
                                    
                                    value={vacancyData?.duration}
                                    onChange={(e:any)=>handleChange(e)}
                                    onBlur={handleBlur}
                                  
                                    name="duration"
                                  >
                                    <MenuItem value="30">
                                      30
                                    </MenuItem>
                                    <MenuItem value="60">
                                      60
                                    </MenuItem>
                                    
                                  </Select>
                        </Stack></Grid>
                        {fieldVacancyConfigurations?.map((field) => (
                            <Grid item xs={12} sm={6} key={field.id}>
                              <Stack spacing={1}>
                                <InputLabel htmlFor={field.id}>
                                  {field.label}
                                </InputLabel>
                                {field.name === "city" ? (
                                  <FormControl fullWidth>
                                    <Select
                                      labelId="city-label"
                                      id="city"
                                      value={selectedCity}
                                      onChange={handleCityChange}
                                      onBlur={handleBlur}
                                      name="city"
                                    >
                                      {childCities.map((city) => (
                                        <MenuItem key={city.id} value={city.id}>
                                       {themeDirection==="ltr"? city.name:city.name_trans.ar}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                ) : field.name === "country" ? (
                                  <FormControl fullWidth>
                                    <Select
                                      value={selectedCountry}
                                      onChange={handleCountryChange}
                                      onBlur={handleBlur}
                                      inputProps={{
                                        name: "country",
                                        id: "country",
                                      }}
                                    >
                                      {country &&
                                        country?.map((country: any) => (
                                          <MenuItem
                                            key={country.id}
                                            value={country.id}
                                          >
                                          {themeDirection==="ltr"?  country.name:country.name_trans.ar}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                )   :<></>}
                              </Stack>
                              
                            </Grid>
                          ))}
                          <Grid item xs={12} sm={4}>
                      <Stack spacing={1}>
                      <InputLabel htmlFor="customr-package_validity">
                    
                      <FormattedMessage id="field-of-work" />
                        </InputLabel>
                        <Select
                          multiple
                          
                         value={selectedActivities}
                          name="duration" // Add the name attribute
                          onChange={(e)=>{
                            const checkedActivities=e.target.value;
                            const checkedObj=[]
                            
                            checkedActivities?.map((check:any)=>{
                              jobActitivities?.map((activity:any)=>{

                                activity.id===check && checkedObj.push(activity)
                              })
                            })
                            setselectedActivities(checkedActivities)
                          
                            
                          }}
                          onBlur={handleBlur}
                         
                          placeholder={intl.formatMessage({ id: "field-of-work", defaultMessage: "Field of work" })}
                       
                          
                        > 
                        {jobActitivities?.map((activity) => (
                                        <MenuItem key={activity.id} value={activity.id}>
                                          {themeDirection==="ltr"? activity.name:activity.name_trans.ar}
                                        </MenuItem>
                                      ))}</Select>
                        </Stack></Grid>
                        <Grid item xs={12} sm={4}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-job_type_id">
                        
                          <FormattedMessage id="job-type" />
                        </InputLabel>
                        <Select
                          id="customer-job_type_id"
                          displayEmpty
                         
                          onChange={(event: any) => {
                            const selectedId = event.target.value;
                            const selectedTypeObj = type.find(
                              (user) => user.id === selectedId,
                            );
                            
                            setselectedJopType(selectedTypeObj.id);
                            handleChange(event)
                            
                          }}
                          value={selectedJopType?selectedJopType:vacancyData.job_type}
                          onBlur={handleBlur}
                        >
                          {type &&
                            type?.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {themeDirection==="ltr"?user.name:user.name_trans.ar}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched?.job_type_id &&
                                        errors?.job_type_id && (
                                          <FormHelperText error>
                                            {errors?.job_type_id}
                                          </FormHelperText>
                                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                            <Stack spacing={1}>
                             
                                <InputLabel htmlFor="salary_type">
                               
                                <FormattedMessage id="salary-type" />
                                </InputLabel>
                                
                            
                            
                                  <FormControl fullWidth>
                                    <Select
                                      
                                      value={selectedSalaryType}
                                      onChange={(e:any)=>{setselectedSalaryType(e.target.value);console.log(selectedSalaryType,"salary type")}
                                      }
                                      onBlur={handleBlur}
                                      
                                      name="salary_type"
                                    >
                                      <MenuItem value="SALARY_SCALE">
                                     
                                      <FormattedMessage id="salary-scale" />
                                      </MenuItem>
                                      <MenuItem value="AFTER_THE_INTERVIEW">
                                     
                                      <FormattedMessage id="after-the-interview" />
                                      </MenuItem>
                                      <MenuItem value="RANGE">
                                     
                                      <FormattedMessage id="range" /> 
                                      </MenuItem>
                                      <MenuItem value="FIXED">
                                     
                                      <FormattedMessage id="fixed" />
                                      </MenuItem>
                                      
                                    </Select>
                                  </FormControl>
                              
                            </Stack>
                          </Grid>
                          {selectedSalaryType==="FIXED"&&
                             <Grid item xs={12} sm={6}>
                             <Stack spacing={1}>
                             <InputLabel >
                            
                             <FormattedMessage id="value" />
                               </InputLabel>
                               <TextField
                                 fullWidth
                                 
                                 value={vacancyData?.salary_type_meta.value||salaryTypeMetaValue}
                                 name="salary_type_meta.value" // Add the name attribute
                                 onChange={(e)=>{setsalaryTypeMetaValue(e.target.value)}}
                                 onBlur={handleBlur}
                                 inputProps={{ maxLength: 10 }}
                                
                                 placeholder="Value"
                                 
                               />
                               </Stack></Grid>
                            }
                            {selectedSalaryType==="RANGE"&&<>
                            <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                            <InputLabel >
                           
                            <FormattedMessage id="min-value" />
                              </InputLabel>
                              <TextField
                                fullWidth
                                
                                value={vacancyData?.salary_type_meta.start_range||salaryTypeMetaMin}
                                name="salary_type_meta.start_range" // Add the name attribute
                                onChange={(e:any)=>{setsalaryTypeMetaMin(e.target.value)}}
                                onBlur={handleBlur}
                                inputProps={{ maxLength: 10 }}
                               
                                placeholder={intl.formatMessage({ id: "min-value", defaultMessage: "Min value" })}
                                
                              />
                              </Stack></Grid>
                              <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                            <InputLabel >
                         
                            <FormattedMessage id="max-value" />
                              </InputLabel>
                              <TextField
                                fullWidth
                                
                                value={vacancyData?.salary_type_meta.end_range||salaryTypeMetaMax}
                                name="salary_type_meta.end_range" // Add the name attribute
                                onChange={(e:any)=>setsalaryTypeMetaMax(e.target.value)}
                                onBlur={handleBlur}
                                inputProps={{ maxLength: 10 }}
                               
                                placeholder={intl.formatMessage({ id: "max-value", defaultMessage: "Max value" })}
                                
                              />
                              </Stack></Grid>
                              </>
                            }
                       
                    
                        
                   
                     
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                      <InputLabel htmlFor="responsibilities">
                     
                      <FormattedMessage id="responsibilities" />
                        </InputLabel>
                        <TextArea
                         rows={4}
                          
                          value={vacancyData?.responsibilities}
                          name="responsibilities" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                          
                        
                          placeholder={intl.formatMessage({ id: "responsibilities", defaultMessage: "Responsibilities" })}
                          
                        />
                        {touched?.responsibilities &&
                                        errors?.responsibilities && (
                                          <FormHelperText error>
                                            {errors?.responsibilities}
                                          </FormHelperText>
                                        )}
                        </Stack></Grid>

                        <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                      <InputLabel >
                      
                      <FormattedMessage id="qualifications" />
                        </InputLabel>
                        
                        <TextArea
                 
                  value={vacancyData?.qualifications}
                  name="qualifications"
                  onChange={handleChange}
                  rows={4}
                  onBlur={handleBlur}
                  
                  placeholder={intl.formatMessage({ id: "qualifications", defaultMessage: "Qualifications" })}
                  style={{height:"auto"}}
                />
                {touched?.qualifications &&
                                        errors?.qualifications && (
                                          <FormHelperText error>
                                            {errors?.qualifications}
                                          </FormHelperText>
                                        )}
                        </Stack></Grid>

                        <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                      <InputLabel >
                      
                      <FormattedMessage id="specific-vacancy-requirements" />
                        </InputLabel>
                        <TextArea
                         
                         rows={4} 
                          value={vacancyData?.additional_req}
                          name="additional_req" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={intl.formatMessage({ id: "specific-vacancy-requirements", defaultMessage: "Specific Vacancy Requirements" })}
                          
                          
                        />
                        {touched?.additional_req &&
                                        errors?.additional_req&& (
                                          <FormHelperText error>
                                            {errors?.additional_req}
                                          </FormHelperText>
                                        )}
                        </Stack></Grid>

                        <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                      <InputLabel htmlFor="customr-package_validity">
                     
                      <FormattedMessage id="salary-and-benefits" />
                        </InputLabel>
                        <TextArea
                        rows={4}
                          
                          value={vacancyData?.benefits}
                          name="benefits" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                        
                          placeholder={intl.formatMessage({ id: "benefits", defaultMessage: "Benefits" })}
                         
                          
                        />
                        </Stack></Grid>

                        </Grid></Grid>
            </MainCard>
            <MainCard title="Required Employee Specifications" >
            <Grid item xs={12} md={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                      <InputLabel htmlFor="customr-package_validity">
                     
                      <FormattedMessage id="job-title" />
                        </InputLabel>
                        <TextField
                          fullWidth
                          
                          value={vacancyData?.title}
                          name="title" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 10 }}
                         
                          placeholder={intl.formatMessage({ id: "title", defaultMessage: "Title" })}
                          
                        />
                        {touched.title &&
                                        errors.title && (
                                          <FormHelperText error>
                                            {errors.title}
                                          </FormHelperText>
                                        )}
                        </Stack></Grid>
                        
                        <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                      <InputLabel htmlFor="customr-package_validity">
                   
                      <FormattedMessage id="minimum-education-level" />
                        </InputLabel>

                        <Select
                          id="degree_type_id"
                        
                          
                          onChange={(event: any) => {
                            const selectedId = event.target.value;
                           
                            seteductationDegree(selectedId)
                            setselectedDegree(selectedId);
                           
                           handleChange(event)
                            // Set user name value to another field
                          }}
                          value={eductationDegree}
                          onBlur={handleBlur}
                        >
                          {degree &&
                            degree.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {themeDirection==="ltr"?user.name:user.name_trans.ar}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.degree_type_id &&
                                        errors.degree_type_id && (
                                          <FormHelperText error>
                                            {errors.degree_type_id}
                                          </FormHelperText>
                                        )}
                        </Stack></Grid>

                        <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                     

                        <InputLabel htmlFor="job_gender">
                                
                                <FormattedMessage id="gender" />
                              </InputLabel>
                              <FormControl fullWidth>
                                    <Select
                                    
                                      value={vacancyData?.gender}
                                      onChange={(e:any)=>handleChange(e)}
                                      onBlur={handleBlur}
                                     
                                      name="gender"
                                    >
                                      <MenuItem value="MALE">
                                     
                                      <FormattedMessage id="male" />
                                      </MenuItem>
                                      <MenuItem value="FEMALE">
                                       
                                        <FormattedMessage id="female" />
                                      </MenuItem>
                                      <MenuItem value="EXEMPTED">
                                     
                                        <FormattedMessage id="not-specified" />
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                          
                       
                        </Stack></Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                             
                                <InputLabel htmlFor="military_service">
                              
                                  <FormattedMessage id="military-service" />
                                </InputLabel>
                                
                            
                          
                                
                                  <FormControl fullWidth>
                                    <Select
                                    
                                      value={vacancyData?.military_service}
                                      onChange={(e:any)=>handleChange(e)}
                                      onBlur={handleBlur}
                                      disabled={isMilitaryServiceDisabled}
                                      name="military_service"
                                    >
                                      <MenuItem value="NOT_FINISHED">
                                        
                                        <FormattedMessage id="not-finished" />
                                      </MenuItem>
                                      <MenuItem value="FINISHED">
                                       
                                        <FormattedMessage id="finished" />
                                      </MenuItem>
                                      <MenuItem value="EXEMPTED">
                                        
                                        <FormattedMessage id="exempted" />
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                               
                            </Stack>
                          </Grid>

                        </Grid>
                        </Grid>
                       
            </MainCard>
         

            <MainCard>
            <Button
                            
                            type="submit"
                            variant="contained"
                          >
                            <FormattedMessage id="save" />
                          </Button> 
            </MainCard>
            <Divider />
       
        
     
        </LocalizationProvider>
        
    </form>
    </Stack>
    </Stack>
    </>
                )}
      </Formik>
    </>
  );
};

export default FormVacancyManagmentEdit;
