import { useEffect, useState } from "react";

// material-ui

import {

  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,


  MenuItem,

  Select,
  Checkbox,
  Divider,
  DialogActions,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useAppDispatch } from "hooks";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { openSnackbar } from "api/snackbar";
import { SnackbarProps } from "types/snackbar";

import {
  editSkillsResumes,
  getSkillsResumes,
} from "store/actions/main/cv/skill/skill";
import { FormattedMessage,useIntl } from "react-intl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// types

function EditLanguageDialog({
  open,
  cvId,
  setOpen,

  resumeId,
  setLoading,

  fetchGetOneSkill,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneSkill: any;
  resumeId: any;
  cvId: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  language: any;
  setLanguage: any;
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [skillData, setSkillData] = useState({
    resume_id: 0,
    skill_name: "",
    skill_level: "",
    experience_years: "",
    last_used: "",
    until_now: "",
    description: "",
  });

  const [selectedLevel, setSelectedLevel] = useState("");

  const [untilNow, setUntilNow] = useState<any>(false);

  const handleUntillNowhange = (event) => {
    setUntilNow(event.target.checked); // Set the value of confidentiality based on checkbox state
  };
  const handleInputChange = (
   fieldName: string, date: dayjs.Dayjs | null
  ) => {
   
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    // setExperienceData((prevEducationData: any) => ({
    //   ...prevEducationData,
    //   [fieldName]: formattedDate,
    // }));
    // Convert the value to a string if it's not already
    // const processedValue = name === "last_used" ? String(value) : value;
    console.log(fieldName,"name name");

    setSkillData((prevData) => ({
      ...prevData,
      [fieldName]: formattedDate,
    }));
  };
  console.log(skillData,"skill data")
  const handleInputTextChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => {
     const { name, value } = e.target;
    //  const formattedDate = date ? date.format("YYYY-MM-DD") : null;
     // setExperienceData((prevEducationData: any) => ({
     //   ...prevEducationData,
     //   [fieldName]: formattedDate,
     // }));
     // Convert the value to a string if it's not already
     
     setSkillData((prevData) => ({
       ...prevData,
       [name]: value,
     }));
   };
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 90; i--) {
    years.push(i);
  }
  const fetchGetOne = () => {
    dispatch(getSkillsResumes({ id: cvId })).then((action) => {
      const data = action.payload.data;
      setSkillData(data);
      setUntilNow(data?.still_used|| false);
      setSelectedLevel(data?.skill_level || "");
    });
  };
  useEffect(() => {
    fetchGetOne();
  }, [dispatch]);
  const handleSave = async () => {
    // Log average value for debugging
    setLoading(true);
    dispatch(
      editSkillsResumes({
        id: cvId,
        data: {
          resume_id: resumeId,
          skill_level: selectedLevel,
          skill_name: skillData.skill_name,
          last_used: skillData.last_used,
          description: skillData.description,
          experience_years: skillData.experience_years,
          still_used:untilNow

        },
      }),
    ).then((action) => {
      if (action.type === "skills/editSkillsResumes/fulfilled") {
        openSnackbar({
          open: true,
          message: "Skill Added successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setOpen(false);
        setLoading(false);
        fetchGetOneSkill();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setOpen(false);
        setLoading(false);
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
     
        <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px' }}><FormattedMessage id="skill" /></DialogTitle>
       <Divider/>

      <DialogContent>
       
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-skill_name">
                 
                  <FormattedMessage id="skill-name" />
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-skill_name"
                  value={skillData.skill_name}
                  name="skill_name"
                  // onBlur={handleBlur}
                  onChange={handleInputTextChange}
                  placeholder={intl.formatMessage({ id: "skill-name", defaultMessage: "Skill name" })}
                  autoFocus
                  //inputRef={inputRef}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-reading_level"><FormattedMessage id="level" /></InputLabel>
                <Select
                  id="reading_level"
                  value={selectedLevel} // Set the selected ID as the value of the Select component
                  onChange={(event) => setSelectedLevel(event.target.value)} // Handle the change event
                >
                  <MenuItem value={"BEGINNER"}> <FormattedMessage id="beginner" /> </MenuItem>
                  <MenuItem value={"INTERMEDIATE"}><FormattedMessage id="intermediate" /></MenuItem>
                  <MenuItem value={"INTERMEDIATE"}><FormattedMessage id="intermidiate" /></MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-experience_years">
                 
                  <FormattedMessage id="years-of-experience" />
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-experience_years"
                  value={skillData.experience_years}
                  name="experience_years"
                  // onBlur={handleBlur}
                  onChange={handleInputTextChange}
                  placeholder={intl.formatMessage({ id: "years-of-experience", defaultMessage: "Years of experience" })}
                  autoFocus
                  type="number"
                  //inputRef={inputRef}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
              <InputLabel htmlFor="last_used"><FormattedMessage id="last-used" /></InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    
                    value={dayjs(skillData.last_used)}
                    onChange={(date:any)=>handleInputChange("last_used", date)}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction={"row"}>
                {/* <Stack spacing={1}>
                  <InputLabel htmlFor="last_used"><FormattedMessage id="last-used" /></InputLabel>
                  <Select
                    fullWidth
                    id="last_used"
                    style={{ width: "200px" }}
                    value={skillData.last_used}
                    onChange={(e:any)=>handleInputChange("started_at", date)}
                    name="last_used"
                    placeholder={intl.formatMessage({ id: "last-used", defaultMessage: "Last used" })}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack> */}
                <Stack
                  spacing={1}
                  display={"flex"}
                  alignItems={"end"}
                  justifyContent={"end"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={untilNow}
                        onChange={handleUntillNowhange}
                        id="stillWorking-checkbox"
                      />
                    }
                    label="stillWorking"
                  />
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-description">
                  
                  <FormattedMessage id="description" />
                </InputLabel>
                <TextArea
                  id="personal-description"
                  value={skillData.description}
                  name="description"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder={intl.formatMessage({ id: "description", defaultMessage: "Descritpion" })}
                  autoFocus
                  //inputRef={inputRef}
                />
              </Stack>
            </Grid>
          </Grid>
         
  
      </DialogContent>
      <Divider/>
      <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" onClick={handleSave} variant="contained">
                      <FormattedMessage id="edit-skill" />
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={handleClose}>
                        
                        <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
    </Dialog>
  );
}

export default EditLanguageDialog;
