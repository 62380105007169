const GET_QUESTIONS = "resumeMiniInterviewQuestion";

const ADD_QUESTIONS = "resumeMiniInterviewQuestion";

const GET_ONE_QUESTIONS = "resumeMiniInterviewQuestion/";

const EDIT_QUESTIONS = "resumeMiniInterviewQuestion/";

const DELETE_QUESTIONS = "resumeMiniInterviewQuestion/";

const MULTI_DELET_QUESTIONS = "resumeMiniInterviewQuestion/remove/bulk_delete";

export default {
  GET_QUESTIONS,
  ADD_QUESTIONS,
  GET_ONE_QUESTIONS,
  EDIT_QUESTIONS,
  DELETE_QUESTIONS,

  MULTI_DELET_QUESTIONS,
};
