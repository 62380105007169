import {  useEffect, useRef, useState } from "react";


// material-ui
import {
  Autocomplete,
  Box,
  Button,

  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,

  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
} from "@mui/material";
import { DatePicker } from "antd";
import './resume.css'
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import { openSnackbar } from "api/snackbar";
import MainCard from "components/MainCard";

// types
import { SnackbarProps } from "types/snackbar";
import phoneNumber from "hooks/data/phoneNumber";

import EducationSection from "./education/EducationSection";
import RequiredJobSection from "./reqJob/RequiredJobSection";
import ExperienceSection from "./experience/ExperienceSection";
import LanguageSection from "./language/LanguageSection";
import RecommendationsSection from "./recommendations/RecommendationsSection";

import ProfileTabs from "./profileTabs";
import { fieldConfigurations } from "./profileList";
import { useAppDispatch } from "hooks";
import CouurseSection from "./course/CourseSection";

import {
  GetChildCountries,
  ParentCountries,
} from "store/actions/main/countries";
import {
  addNewJobSeekerProfile,
  editJobSeekerProfile,
  getOneJobSeekerProfile,
} from "store/actions/main/cv/jobSeekerProfile/jobSeekerProfile";
import { FormattedMessage,useIntl } from "react-intl";
import moment from "moment";
import BasicInfoSection from "./basicInfoSection/BasicInfoSection";
import SkillSection from "./skills/SkillSection";
import QuestionSection from "./question/QuestionSection";
import { getOnebasicInfo, getResumeStatistics } from "store/actions/main/cv/basicinfo/basicInfo";

import AnalyticEcommerce from "components/cards/statistics/AnalyticEcommerce";

import { getAllTitles } from "store/actions/main/basicInfo/title";
import { searshAllCountries } from "../../../store/actions/main/countries";

// ==============================|| TAB - PERSONAL ||============================== //

const Profile = () => {
  const maxDate = new Date();
  const intl = useIntl();

  const pathname = window.location.pathname;

  // Split the pathname into segments using "/"
  const segments = pathname.split("/");
  const [basicResumeId, setbasicResumeId] = useState("");
  // Extract the resume ID (second-to-last segment) and user ID (last segment)

  const resumeId = segments[segments.length - 1];

  maxDate.setFullYear(maxDate.getFullYear() - 18);
  const [open, setOpen] = useState(false);
  const [openReqJob, setOpenReqJob] = useState(false);
  const [openExp, setOpenExp] = useState(false);
  const [openCourses, setOpenCourses] = useState(false);
  const [openLanguage, setOpenLanguages] = useState(false);
  const [openSkills, setOpenSkills] = useState(false);
  const [nationality, setNationality] = useState(false);
  const [selectedTitle, setselectedTitle] = useState<any>();
  const [openReco, setOpenReco] = useState(false);

  const [openAnswre, setOpenAnswer] = useState(false);

  const [activeSection, setActiveSection] = useState(0); // State variable for active section index

  const [profileId, setProfileID] = useState(0);
  
  useEffect(() => {
    const navButtons = document.querySelectorAll(".nav-button");
    navButtons.forEach((button) => {
      button.classList.remove("active");
    });
    const activeButton = document.querySelector(`#nav-button-${activeSection}`);
    if (activeButton) {
      activeButton.classList.add("active");
    }
  }, [activeSection]);
  const handleSectionClick = (index: any) => {
    setActiveSection(index);
    const section = document.getElementById(`section-${index}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const dispatch = useAppDispatch();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [country, setCountry] = useState<any>();

  const [childCities, setChildCities] = useState([]); // Step 3: State to store child cities

  // This will give you the value "2" from the URL

  const [profileData, setProfileData] = useState({
    resume_id: "1",
    account_photo: null,
    title: "",
    birthdate: "",
    city: "",
    confidetial: 0,
    countryCode: "+963",
    country: "",
    driving_license: "",
    driving_license_date: "",
    gender: "",
    full_name: "",
    nationality: "",
    marital_status: "",
    military_service: "",
    military_service_date: "",
    title_id:"",
    phone_number: "",
  });
  const [basicInfo, setBasicInfo] = useState({
    user_id: "",
    lang: "",
    status: false,
    read: false,
    seo_keywords: "",
    seo_description: "",
    resume_target_jobs: [],
    resume_educations: [],
    resume_experiences: [],
    resume_courses: [],
    resume_languages: [],
    resume_skills: [],
    resume_recommendations: [],
  });
  const [code, setCode] = useState("");
  // Ensure `dispatch` and `id` are in the dependency array
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [resumeStatistics, setresumeStatistics] = useState<any>();
  const [titlesData, settitlesData] = useState([]);
  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value; // Get the selected country ID
    setSelectedCountry(selectedCountryId); // Update the selected country ID state

    // Find the selected country object
    const selectedCountryObject = country.find(
      (country) => country.id === selectedCountryId,
    );
    console.log("Selected Country Object:", selectedCountryObject); // Add this line for debugging
    console.log("Selected Country ID:", selectedCountryId); // Add this line for debugging

    if (selectedCountryObject) {
      setSelectedCountryName(selectedCountryObject.name); // Set the selected country name

      // Set the selected city to "All Cities" if the selected country is not the first item in the list
      if (selectedCountryId == "1") {
        dispatch(GetChildCountries({ parent_id: 1 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        }); // Disable city selection
        setSelectedCity("");
      } else {
        dispatch(GetChildCountries({ parent_id: 2 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        });
        setSelectedCity("");
      }
    }
  };

  const fetchDataPackages = () => {
    console.log("in fetsh packages")
    dispatch(ParentCountries())
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setCountry(data);
        // Set default selected country to the first one in the list
        if (data.length > 0) {
          setSelectedCountry(data[0].id);
          setSelectedCountryName(data[0].name);
          setNationality(data[0].nationality);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  // Dispatch parent countries action when the component mounts
  useEffect(() => {
    fetchDataPackages();
    dispatch(GetChildCountries({ parent_id: 1 })).then((action) => {
      const { data } = action.payload;
      setChildCities(data);
      if (data?.length > 0) {
        setSelectedCity(data[0].id);
        setSelectedCityName(data[0].name);
      }
      // Set fetched child cities to state
    });
  }, [dispatch]);

  const [isMilitaryServiceDisabled, setIsMilitaryServiceDisabled] =
    useState(false);
  const [isDrivingDisabled, setIsDrivingDisabled] = useState(false);
  const handleChange = (event: React.ChangeEvent<any>) => {
    const { name, value, type, checked } = event.target;

    // Handle different input types
    const newValue = type === "checkbox" ? checked : value;

    // Update profileData state with the new value
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      [name]: newValue,
    }));

    // Toggle military service field based on selected gender
    if (name === "gender" && value === "FEMALE") {
      setIsMilitaryServiceDisabled(true);
    } else if (name === "gender" && value === "MALE") {
      setIsMilitaryServiceDisabled(false);
    }
    if (name === "driving_license" && value === "NONE") {
      setIsDrivingDisabled(true);
    } else {
      setIsDrivingDisabled(false);
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : ""; // Format the date as per your requirement
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      birthdate: formattedDate,
    }));
  };
  const handleDrivingDateChange = (date) => {
    const formattedDrivnDate = date ? date.format("YYYY-MM-DD") : ""; // Format the date as per your requirement
    setProfileData((prevProfileData) => ({
      ...prevProfileData,

      driving_license_date: formattedDrivnDate,
    }));
  };
  const handleMilitaryDateChange = (date) => {
    const formatteMilitarydDate = date ? date.format("YYYY-MM-DD") : ""; // Format the date as per your requirement
    setProfileData((prevProfileData) => ({
      ...prevProfileData,

      military_service_date: formatteMilitarydDate,
    }));
  };

  // Function to handle city change
  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = childCities.find((city) => city.id === selectedCityId);
    setSelectedCity(selectedCityId);
    // Set the selected city name
    setSelectedCityName(selectedCity?.name || "");
  };
  const fileInputRef = useRef(null);
  // Function to handle file change event
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if a file was selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        setProfileData({ ...profileData, account_photo: file });
        // Clear any previous file error
      } else {
        setProfileData({ ...profileData, account_photo: null });
        // Set file error message
      }
    }
  };

  // Dispatch child cities action when the selected country changes
  const validationSchema = Yup.object().shape({
    
   
       full_name:
      profileData.full_name === ""
        && Yup.string().required("Arabic full name is required")
        ,
    birthdate:
      profileData.birthdate === ""
        && Yup.date().required("Birthdate is required")
        ,
    gender:
      profileData.gender === ""
        &&Yup.string().required("Gender is required")
        ,


    phone_number:
      profileData.phone_number === ""
        
        && Yup.string().required("Phone number must be a number")
          ,
    // Add more fields as needed
    // Add more fields as needed

    // Add more fields as needed
  });

  // Check if the resumeId is a number
  const fetchGetOne = () => {
    {/*  @ts-ignore */}
    if (resumeId!=="0") {
      setLoading(true);

      dispatch(getOnebasicInfo({ cvId: resumeId }))
        .then((action) => {
          const { data } = action.payload;
          const profileIds = data.profile_id;
          setBasicInfo(data);
          setProfileID(profileIds);
          setLoading(false);
          dispatch(getOneJobSeekerProfile({ id: profileIds })).then(
            (action) => {
              const data = action.payload["result: "];
              console.log(action.payload.data,"jobseekers profile")
              console.log(data,"profile data");
              
              setProfileData(action.payload.data);
              setSelectedCountry(action.payload.data.country_id);
              setselectedTitle(action.payload.data.title_id)
              dispatch( getResumeStatistics({ cvId: action.payload.data.user_id })).then((action:any)=>{
                console.log(action.payload,"action");
                
                const  data  = action.payload["result: "];
                setresumeStatistics(data)
                console.log(data,"resume statistics");
                
                
               })
            },
          );
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
          setLoading(false);
        });
    }
    dispatch(getAllTitles({pageNumber:1})).then((action)=>{
      const {data}=action.payload
      settitlesData(data)
      console.log(data,"data titles");
      
    })
  };

  useEffect(() => {
    fetchGetOne();
   
  }, [dispatch]);
  console.log(selectedTitle,"selected title")
  return (
    <>
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          {/* row 1 */}
          
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <AnalyticEcommerce title="Application Count" count={resumeStatistics?.applications?.total_applications}/>
          </Grid>
          
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <AnalyticEcommerce
              title="Total Email"
              count={resumeStatistics?.applications?.total_emails}
              isLoss
              color="warning"
              extra="1,943"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce title="Read Application Count" count={resumeStatistics?.applications?.read_applications} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <AnalyticEcommerce
              title="Total Note"
             count={resumeStatistics?.applications?.total_notes}
              isLoss
              color="warning"
              extra="$20,395"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <AnalyticEcommerce
              title="Companies Reports"
              count={resumeStatistics?.applications?.total_company_reports}
              isLoss
              color="warning"
              extra="$20,395"
            />
          </Grid>
          </Grid>

      <Box  sx={{ display: "flex", justifyContent: "center", margin: "16px 0" }}>
        <Button style={{backgroundColor:"#fff",border:"0.5px solid rgb(224 221 221)",color:"#000",marginRight:"13px",width:"90%"}} onClick={() => handleSectionClick(0)}>
      
          <FormattedMessage id="personal-information" />
        </Button>
        <Button style={{backgroundColor:"#fff",border:"0.5px solid rgb(224 221 221)",color:"#000",marginRight:"13px",width:"90%",minWidth:"auto"}} onClick={() => handleSectionClick(1)}> <FormattedMessage id="traget-job" /> </Button>
        <Button style={{backgroundColor:"#fff",border:"0.5px solid rgb(224 221 221)",color:"#000",marginRight:"13px",width:"90%",minWidth:"auto"}} onClick={() => handleSectionClick(2)}> <FormattedMessage  id="education"/> </Button>
        <Button style={{backgroundColor:"#fff",border:"0.5px solid rgb(224 221 221)",color:"#000",marginRight:"13px",width:"90%",minWidth:"auto"}} onClick={() => handleSectionClick(3)}> <FormattedMessage id="experience" /> </Button>
        <Button style={{backgroundColor:"#fff",border:"0.5px solid rgb(224 221 221)",color:"#000",marginRight:"13px",width:"90%",minWidth:"auto"}} onClick={() => handleSectionClick(4)}> <FormattedMessage id="courses" /> </Button>
        <Button style={{backgroundColor:"#fff",border:"0.5px solid rgb(224 221 221)",color:"#000",marginRight:"13px",width:"90%",minWidth:"auto"}} onClick={() => handleSectionClick(5)}><FormattedMessage id="languages" /> </Button>
        <Button style={{backgroundColor:"#fff",border:"0.5px solid rgb(224 221 221)",color:"#000",marginRight:"13px",width:"90%",minWidth:"auto"}} onClick={() => handleSectionClick(6)}> <FormattedMessage id="recommendation" /> </Button>
        <Button style={{backgroundColor:"#fff",border:"0.5px solid rgb(224 221 221)",color:"#000",marginRight:"13px",width:"90%",minWidth:"auto"}} onClick={() => handleSectionClick(7)}> <FormattedMessage id="skills" /> </Button>
        <Button style={{backgroundColor:"#fff",border:"0.5px solid rgb(224 221 221)",color:"#000",marginRight:"13px",width:"90%",minWidth:"auto"}} onClick={() => handleSectionClick(8)}> <FormattedMessage id="question" /> </Button>
      </Box>

      <section id="section-0">
        <Stack direction={"row"}>
          <Grid style={{ height: "100px", marginRight: "20px", width: "100%" }}>
            <BasicInfoSection
              fetchGetOne={fetchGetOne}
              userId={profileId}
              setLoading={setLoading}
              loading={loading}
              setbasicResumeId={setbasicResumeId}
              basicResumeId={basicResumeId}
              resumeId={resumeId}
              basicInfo={basicInfo}
              setBasicInfo={setBasicInfo}
            />
          <ProfileTabs data={resumeStatistics}  />
          </Grid>
          <Stack className="main-resume-container" direction={"column"}>
            <MainCard
             
              content={false}
              title={intl.formatMessage({ id: "personal-information", defaultMessage: "Personal information" })}
              sx={{ "& .MuiInputLabel-root": { fontSize: "0.875rem" } }}
            >
              <Formik
                initialValues={{
                  resume_id: 1,
                  title: profileData?.title || "",
                  countryCode: "",
                  full_name: profileData?.full_name|| "",
                  birthdate: profileData?.birthdate || "",
                  gender: profileData?.gender || "",
                  nationality: profileData?.nationality || "",
                  marital_status: profileData?.marital_status || "",
                  military_service: profileData?.military_service || "",
                  military_service_date:
                    profileData?.military_service_date || "",
                  confidetial: profileData?.confidetial || "",
                  driving_license: profileData?.driving_license || "",
                  driving_license_date: profileData?.driving_license_date || "",
                  account_photo: profileData.account_photo,
                  country: profileData?.country || "",
                  city: profileData?.city || "",
                
                  phone_number: profileData?.phone_number || "",
                  submit: null,
                }}
                validationSchema={validationSchema} // Pass the validation schema to Formik
                onSubmit={(values, { setSubmitting }) => {
                  // Check if account_photo is an image
                 
                    // Prepare FormData object

                    const formData = new FormData();
                    formData.append("resume_id", profileData.resume_id);
                    formData.append("title_id", selectedTitle);
                    formData.append("account_type", "COMPANY");
                    
                    formData.append(
                      "full_name",
                      profileData.full_name,
                    );
                    formData.append("city", selectedCityName);
                    formData.append("birthdate", profileData.birthdate);
                    formData.append("gender", profileData.gender);
                     {/*  @ts-ignore */}
                    formData.append("nationality", nationality);
                    formData.append(
                      "marital_status",
                      profileData.marital_status,
                    );
                    formData.append(
                      "military_service",
                      profileData.military_service,
                    );
                    formData.append(
                      "military_service_date",
                      profileData.military_service_date,
                    );
                    formData.append(
                      "driving_license",
                      profileData.driving_license,
                    );
                    formData.append(
                      "driving_license_date",
                      profileData.driving_license_date,
                    );
                    //   formData.append("confidetial", profileData.confidetial);
                    // Assuming profileData.account_photo is a File object or a Blob
                 
                    formData.append("country", selectedCountryName);
                  
                    
                    const countryCode = Object.values(code)[0]; // Assuming code is an object with only one key-value pair

                    // Concatenate country code and phone number
                    const phoneNumberWithCode = `${countryCode}-${profileData.phone_number}`;
                    formData.append("phone_number", phoneNumberWithCode);
                    if (profileId === 0) {
                      dispatch(addNewJobSeekerProfile(formData)).then(
                        (action) => {
                          if (
                            action.type ===
                            "jobSeeker/addNewJobSeekerProfile/fulfilled"
                          ) {
                            openSnackbar({
                              open: true,
                              message: "profile added successfully",
                              anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                              },
                              variant: "alert",
                              alert: {
                                color: "success",
                              },
                            } as SnackbarProps);
                            setSubmitting(false);
                          }
                        },
                      );
                    } else {
                      dispatch(
                        editJobSeekerProfile({
                          id: profileId,
                          data: formData,
                        }),
                      ).then((action) => {
                        if (
                          action.type ===
                          "jobSeeker/editJobSeekerProfile/fulfilled"
                        ) {
                          openSnackbar({
                            open: true,
                            message: "profile Updated successfully",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                            variant: "alert",
                            alert: {
                              color: "success",
                            },
                          } as SnackbarProps);
                          setSubmitting(false);
                        }
                      });
                    }

                    // Send FormData with Axios
                    // Set file error message
                   // Your form submission logic here
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <>
                    <form noValidate onSubmit={handleSubmit}>
                      <Divider />
                      <Box sx={{ p: 2.5 }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                            <InputLabel htmlFor="job_title"><FormattedMessage id="title" /></InputLabel>
                           
                              <FormControl fullWidth>
                                <Select
                                  name="title"
                                  value={selectedTitle}
                                  onChange={(e:any)=>{setselectedTitle(e.target.value);console.log(e.target.value,"title");
                                  }}
                                  onBlur={handleBlur}
                                 // Check if the field has been touched and has an error
                              
                                >

                                {titlesData.map((title:any)=>  (<MenuItem value={title.id}>{title?.name}</MenuItem>))}
                                  
                                </Select>
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                              <InputLabel htmlFor="job_full_name_english">
                                <FormattedMessage id="full-name" /> *
                              </InputLabel>
                              <TextField
                                fullWidth
                                id="customer-full_name_english"
                                placeholder={intl.formatMessage({ id: "full-name", defaultMessage: "Full name" })}
                                value={profileData?.full_name}
                                name="full_name" // Add the name attribute
                              
                                onChange={handleChange}
                          // Add the onChange handler
                              />
                            </Stack>
                          </Grid>
                          
                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                              <InputLabel htmlFor="job_birthdate">
                               <FormattedMessage id="date-of-birth" />
                              </InputLabel>
                              <DatePicker
                                value={
                                  profileData.birthdate
                                    ? moment(profileData.birthdate)
                                    : null
                                }
                                onChange={handleDateChange}
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "4px",
                                  borderColor: "lightgray",
                                }}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                              <InputLabel htmlFor="job_nationality">
                               
                                <FormattedMessage id="nationality" />
                              </InputLabel>
                              <FormControl fullWidth>
                                <Select
                                  name="nationality"
                                  value={nationality}
                                  onChange={(event:any) =>
                                    setNationality(event.target.value)
                                  }
                                  
                                  inputProps={{
                                    name: "nationality",
                                    id: "nationality",
                                  }}
                                >
                                  {country &&
                                    country.map((country: any) => (
                                      <MenuItem
                                        key={country.id}
                                        value={country.nationality}
                                      >
                                        {country.nationality}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                              <InputLabel htmlFor="job_marital_status">
                                
                                <FormattedMessage id="material-status" />
                              </InputLabel>
                              <FormControl fullWidth>
                                <Select
                                  value={profileData?.marital_status || ""}
                                  onChange={(e:any)=>handleChange(e)}
                                  onBlur={handleBlur}
                                  name="marital_status"
                                >
                                  <MenuItem value="Married"><FormattedMessage id="married" /></MenuItem>
                                  <MenuItem value="Single"><FormattedMessage id="single" /></MenuItem>
                                </Select>
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                              <InputLabel htmlFor="job_confidetial">
                                
                                <FormattedMessage id="confidental" />
                              </InputLabel>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  // Check if the field has been touched and has an error

                                  aria-label="confidetial"
                                  name="confidetial"
                                  value={profileData.confidetial}
                                  onChange={handleChange}
                                  style={{ flexDirection: "row" }}
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                              <InputLabel htmlFor="job_gender">
                             
                                <FormattedMessage id="gender" />
                              </InputLabel>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="gender"
                                  name="gender"
                                  value={profileData.gender}
                                  onChange={handleChange}
                                  style={{ flexDirection: "row" }}
                                >
                                  <FormControlLabel
                                    value="MALE"
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: "male", defaultMessage: "Male" })}
                                  />
                                  <FormControlLabel
                                    value="FEMALE"
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: "female", defaultMessage: "Female" })}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                              <Stack direction={"row"} display={"flex"}>
                                <InputLabel htmlFor="military_service">
                                  
                                  <FormattedMessage id="military-service" />
                                </InputLabel>
                                <InputLabel
                                  style={{ marginLeft: "120px" }}
                                  htmlFor="military_service_date"
                                >
                                  
                                  <FormattedMessage id="military-service-date" />
                                </InputLabel>
                              </Stack>
                              <Stack direction={"row"}>
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  // alignItems="center"
                                  spacing={2}
                                >
                                  <FormControl fullWidth>
                                    <Select
                                      style={{
                                        width: "200px",
                                        marginRight: "20px",
                                      }}
                                      value={profileData.military_service}
                                      onChange={(e:any)=>handleChange(e)}
                                      onBlur={handleBlur}
                                      disabled={isMilitaryServiceDisabled}
                                      name="military_service"
                                    >
                                      <MenuItem value="NOT_FINISHED">
                                      
                                        <FormattedMessage id="not-finished" />
                                      </MenuItem>
                                      <MenuItem value="FINISHED">
                                       
                                        <FormattedMessage id="finished" />
                                      </MenuItem>
                                      <MenuItem value="EXEMPTED">
                                      
                                        <FormattedMessage id="exempted" />
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Stack>

                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  spacing={2}
                                >
                                  <DatePicker
                                    style={{
                                      width: "180px",
                                      marginRight: "20px",
                                    }}
                                    disabled={isMilitaryServiceDisabled}
                                    value={
                                      profileData.military_service_date
                                        ? moment(
                                            profileData.military_service_date,
                                          )
                                        : null
                                    }
                                    onChange={handleMilitaryDateChange}
                                  />
                                </Stack>
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                              <Stack direction={"row"} display={"flex"}>
                                <InputLabel htmlFor="driving_license">
                                  
                                  <FormattedMessage id="driving-license" />
                                </InputLabel>
                                <InputLabel
                                  style={{ marginLeft: "120px" }}
                                  htmlFor="driving_license_date"
                                >
                                
                                  <FormattedMessage id="driving-license-date" />
                                </InputLabel>
                              </Stack>
                              <Stack direction={"row"}>
                                <Stack
                                  direction="row"
                                  justifyContent="start"
                                  // alignItems="center"
                                  spacing={2}
                                >
                                  <FormControl fullWidth>
                                    <Select
                                      style={{
                                        width: "200px",
                                        marginRight: "20px",
                                      }}
                                      name="driving_license"
                                      value={profileData.driving_license}
                                      onChange={(e:any)=>handleChange(e)}
                                      onBlur={handleBlur}
                                      error={
                                        touched.driving_license &&
                                        Boolean(errors.driving_license)
                                      } // Check if the field has been touched and has an error
                                      
                                    >
                                      <MenuItem value="NONE"> <FormattedMessage id="none" /> </MenuItem>
                                      <MenuItem value="TYPE_A"> <FormattedMessage id="type-a" /></MenuItem>
                                      <MenuItem value="TYPE_B"><FormattedMessage id="type-b" /> </MenuItem>
                                      <MenuItem value="TYPE_C"> <FormattedMessage id="type-c" /></MenuItem>
                                      <MenuItem value="TYPE_D"> <FormattedMessage id="type-d" /> </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Stack>

                                <Stack direction="row" spacing={2}>
                                  <DatePicker
                                    style={{
                                      width: "180px",
                                      marginRight: "20px",
                                    }}
                                    disabled={isDrivingDisabled}
                                    value={
                                      profileData.driving_license_date
                                        ? moment(
                                            profileData.driving_license_date,
                                          )
                                        : null
                                    }
                                    onChange={handleDrivingDateChange}
                                  />
                                </Stack>
                              </Stack>
                            </Stack>
                          </Grid>

                          {fieldConfigurations.map((field) => (
                            <Grid item xs={12} sm={6} key={field.id}>
                              <Stack spacing={1}>
                                <InputLabel htmlFor={field.id}>
                                  {field.label}
                                </InputLabel>
                                {field.name === "nationality" ? (
                                  <FormControl fullWidth>
                                    <Select
                                      name="nationality"
                                      value={nationality}
                                      onChange={(event:any) =>
                                        setNationality(event.target.value)
                                      }
                                      onBlur={handleBlur}
                                      inputProps={{
                                        name: "nationality",
                                        id: "nationality",
                                      }}
                                    >
                                      {country &&
                                        country.map((country: any) => (
                                          <MenuItem
                                            key={country.id}
                                            value={country.nationality}
                                          >
                                            {country.nationality}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                ) : field.name === "city" ? (
                                  <FormControl fullWidth>
                                    <Select
                                      labelId="city-label"
                                      id="city"
                                      value={selectedCity}
                                      onChange={handleCityChange}
                                      onBlur={handleBlur}
                                      name="city"
                                    >
                                      {childCities.map((city) => (
                                        <MenuItem key={city.id} value={city.id}>
                                          {city.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                ) : field.name === "country" ? (
                                  <FormControl fullWidth>
                                    <Select
                                      value={selectedCountry}
                                      onChange={handleCountryChange}
                                      onBlur={handleBlur}
                                      inputProps={{
                                        name: "country",
                                        id: "country",
                                      }}
                                    >
                                      {country &&
                                        country.map((country: any) => (
                                          <MenuItem
                                            key={country.id}
                                            value={country.id}
                                          >
                                            {country.name}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                ) : field.name === "phone_number" ? (
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Autocomplete
                                      style={{ width: "200px" }}
                                      id="personal-country-code"
                                      options={phoneNumber}
                                      value={{ dialCode: "+963" }}
                                      autoHighlight
                                      getOptionLabel={(option) =>
                                        option.dialCode
                                      }
                                      onChange={(event, newValue:any) => {
                                        // newValue here will be the selected option
                                        setCode(newValue); // You can use newValue to get the selected value
                                      }}
                                      renderOption={(props, option) => (
                                        <MenuItem
                                          component="li"
                                          sx={{ typography: "body2" }}
                                          {...props}
                                        >
                                          ({option.dialCode})
                                        </MenuItem>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="countryCode"
                                          placeholder=""
                                          error={Boolean(
                                            touched.countryCode &&
                                              errors.countryCode,
                                          )}
                                          helperText={
                                            touched.countryCode &&
                                            errors.countryCode
                                          }
                                        />
                                      )}
                                    />

                                    <TextField
                                      fullWidth
                                      id="customer-mobile_number"
                                      placeholder="xxx xxx xxx"
                                      value={profileData.phone_number}
                                      name="phone_number" // Add the name attribute
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      inputProps={{ maxLength: 10 }} // Add the onChange handler
                                    />
                                  </Stack>
                                ) : field.name === "account_photo" ? (
                                  <Stack direction={"row"}>
                                    <input
                                      type="file"
                                      accept="image/*" // Allow only image files
                                      ref={fileInputRef} // Attach the reference to the file input
                                      // style={{ display: "none" }} // Hide the input element
                                      onChange={handleFileChange}
                                      // Handle file change event
                                    />
                                    <Button
                                      onClick={() =>
                                        fileInputRef.current.click()
                                      } // Trigger file input click on button click
                                    >
                                      
                                      <FormattedMessage id="upload-photo" />
                                    </Button>
                                  </Stack>
                                ) : (
                                  // Function to handle file change

                                  <TextField
                                    fullWidth
                                    id={field.id}
                                    value={profileData[field.name]}
                                    name={field.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange} // U
                                    placeholder={field.placeholder}
                                  />
                                )}
                              </Stack>
                              {touched[field.name] && errors[field.name] && (
                                <FormHelperText
                                  error
                                  id={`personal-${field.name}-helper`}
                                >
                                  {errors[field.name]}
                                </FormHelperText>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                        <Grid
                          display={"flex"}
                          justifyContent={"end"}
                          alignItems={"end"}
                          marginTop={"10px"}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                          
                          >
                            {profileId === 0 ? <FormattedMessage id="add-profile" /> :<FormattedMessage id="edit-profile" />}
                          </Button>
                        </Grid>
                      </Box>

                      <Divider />
                      <section id="section-1">
                        <RequiredJobSection
                          openReqJob={openReqJob}
                          setOpenReqJob={setOpenReqJob}
                          cvId={resumeId}
                          basicInfo={basicInfo}
                          setLoading={setLoading}
                          loading={loading}
                          fetchGetOneReq={fetchGetOne}
                        />
                      </section>

                      <Divider />
                      <section id="section-2">
                        <EducationSection
                          open={open}
                          setOpen={setOpen}
                          cvId={resumeId}
                          basicInfo={basicInfo}
                          setLoading={setLoading}
                          loading={loading}
                          fetchGetOneEdu={fetchGetOne}
                        />
                      </section>
                      <Divider />
                      <section id="section-3">
                        <ExperienceSection
                          cvId={resumeId}
                          basicInfo={basicInfo}
                          setLoading={setLoading}
                          loading={loading}
                          fetchGetOneExp={fetchGetOne}
                          openExp={openExp}
                          setOpenExp={setOpenExp}
                        />
                      </section>
                      <Divider />
                      <section id="section-4">
                        <CouurseSection
                          cvId={resumeId}
                          basicInfo={basicInfo}
                          setLoading={setLoading}
                          loading={loading}
                          fetchGetOneCourse={fetchGetOne}
                          openCourses={openCourses}
                          setOpenCourses={setOpenCourses}
                        />
                      </section>
                      <Divider />
                      <section id="section-5">
                        <LanguageSection
                          cvId={resumeId}
                          basicInfo={basicInfo}
                          setLoading={setLoading}
                          loading={loading}
                          fetchGetOneLang={fetchGetOne}
                          openLanguage={openLanguage}
                          setOpenLanguages={setOpenLanguages}
                        />
                      </section>
                      <Divider />
                      <section id="section-6">
                        <SkillSection
                          basicInfo={basicInfo}
                          cvId={resumeId}
                          openSkills={openSkills}
                          setOpenSkills={setOpenSkills}
                          setLoading={setLoading}
                          loading={loading}
                          fetchGetOneSkill={fetchGetOne}
                        />
                      </section>
                      <Divider />
                      <section id="section-7">
                        <RecommendationsSection
                          basicInfo={basicInfo}
                          cvId={resumeId}
                          openReco={openReco}
                          setOpenReco={setOpenReco}
                          setLoading={setLoading}
                          loading={loading}
                          fetchGetOneReco={fetchGetOne}
                        />
                      </section>
                      <Divider />
                      <section id="section-8">
                        <QuestionSection
                          loading={loading}
                          setLoading={setLoading}
                          basicInfo={basicInfo}
                          cvId={resumeId}
                          openAnswre={openAnswre}
                          setOpenAnswer={setOpenAnswer}
                          fetchGetOneQues={fetchGetOne}
                        />
                      </section>
                      <Box sx={{ p: 2.5 }}>
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          spacing={2}
                          sx={{ mt: 2.5 }}
                        >
                          <Button variant="outlined" color="secondary">
                           
                            <FormattedMessage id="cancel" />
                          </Button>
                          <Button
                            disabled={
                              isSubmitting || Object.keys(errors).length !== 0
                            }
                            type="submit"
                            variant="contained"
                          >
                            <FormattedMessage id="save" />
                          </Button>
                        </Stack>
                      </Box>
                    </form>
                  </>
                )}
              </Formik>
            </MainCard>
          </Stack>
        </Stack>
      </section>
      
    </>
  );
};

export default Profile;
