import { createAsyncThunk } from "@reduxjs/toolkit";
import mailUrl from "../../../utils/url/mail";
//USERAXIOS
import { USERAXIOS } from "../../../api";

//urls
//import aboutURL from '../../../utils/url/about';

//error message
import responseError from "../../../utils/api/error-handling";

//get AllUser
export const getAllMails = createAsyncThunk(
  "mail/getAllMail",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `https://test.syriajob.com/public/api/admin/mail_templates?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//GET ONE USER
export const searshAllMails = createAsyncThunk(
  "mail/searshAllMail",
  async ({title_trans }: { title_trans: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `https://test.syriajob.com/public/api/admin/mail_templates?title_trans=${title_trans}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const getOneMail = createAsyncThunk(
  "mail/getOneMail",
  async ({ mailId }: { mailId: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(`https://test.syriajob.com/public/api/admin/mail_templates/${mailId}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editMail = createAsyncThunk(
  "mail/editMail",
  async ({ mailId, data }: { mailId: number; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${mailUrl.MAIL_TEMPLATES}${mailId}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const addNewMail = createAsyncThunk(
  "mail/addMail",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(mailUrl.GET_MAIL_TEMPLATES, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteMail = createAsyncThunk(
  "mail/deleteMail",
  async ({ mailId }: { mailId: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${mailUrl.MAIL_TEMPLATES}${mailId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletMails = createAsyncThunk(
  "mail/MultiDeletMails",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(mailUrl.MULTI_DELET, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);