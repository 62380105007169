import { Fragment, MouseEvent, useEffect, useMemo, useState } from "react";

// material-ui
import { alpha, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Chip,
  Divider,
  
  FormControlLabel,
  Grid,
 
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _debounce from "lodash/debounce";
// third-party

import {
  ColumnDef,
  HeaderGroup,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  useReactTable,
  SortingState,
  FilterFn,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";

// project-import
import ScrollX from "components/ScrollX";
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";

import {
  CSVExport,
  DebouncedInput,
  HeaderSort,
  IndeterminateCheckbox,
  RowSelection,
  SelectColumnSorting,
  TablePagination,
} from "components/@extended/react-table";

// types

import { LabelKeyObject } from "react-csv/lib/core";

// assets
import {
  DeleteOutlined,
  EditOutlined,

} from "@ant-design/icons";

import { useAppDispatch } from "hooks";



import {
  FilterUsers,
  MultiDeletUsers,
  SearchUsers,

} from "store/actions/main/users";
import ExpandingUserDetail from "./ExpandingResumeDetail";

import Loader from "components/Loader";

import { CVResponse } from "model/cv/basicInfo/basicInfo";
import ResumeModal from "./resumeModal";
import AlertResumeDelete from "./AlertResumeDelete";
import {
  activeCv,
  deactivateCv,
  getAllBasicInfo,
} from "store/actions/main/cv/basicinfo/basicInfo";


export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // store the ranking info
  addMeta(itemRank);

  // return if the item should be filtered in/out
  return itemRank.passed;
};

// ==============================|| REACT TABLE - LIST ||============================== //

function ReactTable({
  countPage,
  setSortBy,
  setPageSize,
  handleApplyFilters,
  pageSize,
  currentPage,
  handlePageChange,
  data,
  selectedLanguage,
  sortBy,
  handleDateChange,
  setSelectedLanguage,
  columns,
  searchText,
  handleSearchChange,
  setCurrenttPage,
  modalToggler,
}: any) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "id",
      desc: false,
    },
  ]);
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  const table = useReactTable({
    data,

    columns,
    state: {
      sorting,
      rowSelection,
      globalFilter,
    },

    enableRowSelection: true,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    getRowCanExpand: () => true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    globalFilterFn: fuzzyFilter,
    debugTable: true,
  });

  const backColor = alpha(theme.palette.primary.lighter, 0.1);
  let headers: LabelKeyObject[] = [];
  columns.map(
    (columns: any) =>
      // @ts-ignore
      columns.accessorKey &&
      headers.push({
        label: typeof columns.header === "string" ? columns.header : "#",
        // @ts-ignore
        key: columns.accessorKey,
      }),
  );
  const dispatch = useAppDispatch();
  const selectedRowIds = useMemo(() => {
    // Get the selected rows from the table object
    const selectedRows = table
      .getSortedRowModel()
      .flatRows.filter((row) => row.getIsSelected());

    // Map over the selected rows to extract their IDs
    return selectedRows.map((row) => row.original.id);
  }, [table, rowSelection]);
  const handleMultiDelet = () => {
    dispatch(MultiDeletUsers({ ids: selectedRowIds }));
  };

  return (
    <MainCard content={false}>
      <Grid direction={"column"}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: 2,
            ...(matchDownSM && {
              "& .MuiOutlinedInput-root, & .MuiFormControl-root": {
                width: "100%",
              },
            }),
          }}
        >
          <DebouncedInput
            value={searchText}
            onFilterChange={handleSearchChange}
            placeholder={`Search ${data.length} records...`}
          />

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
            sx={{ width: { xs: "100%", sm: "auto" } }}
          >
            {/* <SelectColumnSorting
              {...{
                getState: table.getState,
                getAllColumns: table.getAllColumns,
                setSorting,
              }}
            /> */}
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                variant="contained"
                color="error"
                disabled={Object.keys(rowSelection).length === 0}
                startIcon={<DeleteOutlined />}
                onClick={handleMultiDelet}
              >
                Delet Multi Resumes
              </Button>
              <CSVExport
                {...{
                  data:
                    table
                      .getSelectedRowModel()
                      .flatRows.map((row: any) => row.original).length === 0
                      ? data
                      : table
                          .getSelectedRowModel()
                          .flatRows.map((row: any) => row.original),
                  headers,
                  filename: "customer-list.csv",
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Grid>

      <ScrollX>
        <Stack>
          <RowSelection selected={Object.keys(rowSelection).length} />
          <TableContainer>
            <Table>
              <TableHead>
                {table
                  .getHeaderGroups()
                  .map((headerGroup: HeaderGroup<any>) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        if (
                          header.column.columnDef.meta !== undefined &&
                          header.column.getCanSort()
                        ) {
                          Object.assign(header.column.columnDef.meta, {
                            className:
                              header.column.columnDef.meta.className +
                              " cursor-pointer prevent-select",
                          });
                        }

                        return (
                          <TableCell
                            key={header.id}
                            {...header.column.columnDef.meta}
                            onClick={header.column.getToggleSortingHandler()}
                            {...(header.column.getCanSort() &&
                              header.column.columnDef.meta === undefined && {
                                className: "cursor-pointer prevent-select",
                              })}
                          >
                            {header.isPlaceholder ? null : (
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <Box>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                </Box>
                                {header.column.getCanSort() && (
                                  <HeaderSort column={header.column} />
                                )}
                              </Stack>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <TableRow>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          {...cell.column.columnDef.meta}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.getIsExpanded() && (
                      <TableRow
                        sx={{
                          bgcolor: backColor,
                          "&:hover": { bgcolor: `${backColor} !important` },
                        }}
                      >
                        <TableCell colSpan={row.getVisibleCells().length}>
                          <ExpandingUserDetail data={row.original} />
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <>
            <Divider />
            <Box sx={{ p: 2 }}>
              <TablePagination
                {...{
                  setPageSize: setPageSize,
                  setPageIndex: setCurrenttPage,
                  getState: table.getState,
                  getPageCount: () => countPage.last_page,
                  currentPage: currentPage,
                  handlePageChange: handlePageChange,
                  initialPageSize: pageSize,
                }}
              />
            </Box>
          </>
        </Stack>
      </ScrollX>
    </MainCard>
  );
}

// ==============================|| CUSTOMER LIST ||============================== //

const Resume = () => {
  const theme = useTheme();

  //const { customersLoading, customers: lists } = useGetCustomer();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [customerModal, setCustomerModal] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<CVResponse | null|any>(
    null,
  );
  const [customerDeleteId, setCustomerDeleteId] = useState<any>("");

  const handleClose = () => {
    setOpen(!open);
  };

  const columns = useMemo<ColumnDef<CVResponse>[]>(
    () => [
      
      {
        header: "#",
        accessorKey: "id",
        meta: {
          className: "cell-center",
        },
      },
      {
        header: "User Name",
        accessorKey: "user_name",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
            </Stack>
          </Stack>
        ),
      },

      {
        header: "Account Language",
        accessorKey: "lang",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as number}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: (cell) => {
          const status = cell.getValue();
          return (
            <Chip
              color={status === true ? "success" : "error"}
              label={status === false ? "Inactive" : "Active"}
              size="small"
              variant="light"
            />
          );
        },
      },
      {
        header: "Actions",
        meta: {
          className: "cell-center",
        },
        disableSortBy: true,
        cell: ({ row, getValue }) => {
          const {  id, status } = row.original;

          const handleEditResume = () => {
            window.location.href = `resumes/edit-resume/${id}`;
          };
          const handleToggleAccountStatus = (id: number, status: any) => {
            // Update the status locally in the table data
            result.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  status: status,
                };
              }

              return item;
            });
            console.log(status);
            dispatch(
              status === "inactive"
                ? activeCv({ cvId: id || 0 })
                : deactivateCv({ cvId: id || 0 }),
            )
              .then(() => {
                fetchData();
              })
              .catch((error) => {
                console.error("Error toggling account status:", error);
              });
          };

          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={0}
            >
              <Tooltip title="Edit Resume">
                <IconButton color="primary" onClick={handleEditResume}>
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    handleClose();
                    setCustomerDeleteId(row.original.id);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>

              <FormControlLabel
                control={
                  <Switch
                    key={row.original.id}
                    checked={row.original.status}
                    onChange={() => {
                      // Ensure that account_status exists and has a valid value
                      const currentStatus = row.original.status;
                      const newStatus =
                        typeof currentStatus === "boolean"
                          ? currentStatus === true
                            ? "active"
                            : "inactive"
                          : "";
                      handleToggleAccountStatus(row.original.id, newStatus);
                    }}
                  />
                }
                label={status === true ? <FormattedMessage id="active" /> : <FormattedMessage id="inactive" />}
              />
            </Stack>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [theme],
  );
  const [result, setResult] = useState<CVResponse[]>([]);
  
  const [countPage, setCountPage] = useState({
    current_page: 0,

    last_page: 0,
  });
  const [currentPage, setCurrenttPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default value is 10, or you can set it to `initialPageSize` if available
  const [sortBy, setSortBy] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();
  const fetchData = () => {
    setLoading(true);
    dispatch(getAllBasicInfo({ pageNumber: currentPage }))
      .then((action) => {
        const { data, meta } = action.payload;
        console.log(data[0],"resume");
        console.log(action.payload,"action paylao")
        const fetchedUCountryData: CVResponse[] = data;
        const rowsWithKeys = fetchedUCountryData.map((company) => ({
          ...company,
          key: company.id,
        }));
        setResult(rowsWithKeys);
        setLoading(false);
        setCountPage(meta);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching user:", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, dispatch, searchText]);
  const debouncedSearchUsers = _debounce((value: string) => {
    dispatch(SearchUsers({ data: value }))
      .then((action) => {
        const searchdUserData: CVResponse[] = action.payload.data.map(
          (user: any) => ({
            ...user,
            isActive: user.account_status === "active",
          }),
        );
        setResult(searchdUserData);
      })
      .catch((error) => {
        console.error("Error filtering users:", error);
      });
  }, 500);

  const handleSearchChange = (value: string) => {
    setSearchText(value);
    debouncedSearchUsers(value);
  };
  const handleDateChange = (dates: any) => {
    setSelectedDate(dates);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setCurrenttPage(page); // Update the currentPage state when the page changes
  };

  const handleApplyFilters = () => {
    const startDate = selectedDate?.[0] ?? null;
    const endDate = selectedDate?.[selectedDate.length - 1] ?? null;
    // Dispatch filterUsers action with selected filter criteria

    dispatch(
      FilterUsers({
        account_status: sortBy,
        account_language: selectedLanguage,
        created_at_start: startDate,
        created_at_end: endDate,
      }),
    )
      .then((action) => {
        const filteredcompanyData: CVResponse[] = action.payload.data.map(
          (company: any) => ({
            ...company,
            isActive: company.account_status === "active",
          }),
        );
        setResult(filteredcompanyData);
      })
      .catch((error) => {
        console.error("Error filtering users:", error);
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ReactTable
          setSortBy={setSortBy}
          handleApplyFilters={handleApplyFilters}
          sortBy={sortBy}
          selectedLanguage={selectedLanguage}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
          setSelectedLanguage={setSelectedLanguage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handleSearchChange={handleSearchChange}
          searchText={searchText}
          data={result}
          columns={columns}
          modalToggler={() => {
            setCustomerModal(true);
            setSelectedCustomer(null);
          }}
          countPage={countPage}
          currentPage={currentPage}
          setCurrenttPage={setCurrenttPage}
          handlePageChange={handlePageChange}
        />
      )}
      <AlertResumeDelete
        fetchData={fetchData}
        id={customerDeleteId}
        title={customerDeleteId}
        open={open}
        handleClose={handleClose}
      />
      <ResumeModal
        fetchData={fetchData}
        id={customerDeleteId}
        open={customerModal}
        modalToggler={setCustomerModal}
        customer={selectedCustomer}
      />
      
    </>
  );
};

export default Resume;
