import {  useEffect, useState } from "react";


// material-ui
import {

  Button,
  
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,

  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  FormControlLabel,
  Checkbox,
  DialogActions,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
// types
import { useAppDispatch } from "hooks";

import dayjs from "dayjs";
import { getAllCategories } from "store/actions/main/categories";
import { getAllJobType } from "store/actions/main/basicInfo/jobTypes";
import {
  GetChildCountries,
  ParentCountries,
} from "store/actions/main/countries";
import TextArea from "antd/es/input/TextArea";
import { SnackbarProps } from "types/snackbar";
import { openSnackbar } from "api/snackbar";
import { addNewExperience } from "store/actions/main/cv/experience/experience";
import { FormattedMessage,useIntl } from "react-intl";
const validationSchema = Yup.object().shape({
  job_type_id: Yup.string().required("Job Type is required"),
  category_id: Yup.string().required("Category is required"),
  company_name: Yup.string().required("Company Name expected is required"),
  currency: Yup.string().required("Currency is required"),
  job_title: Yup.string().required("Job Title is required"),
  work_hours_weekly: Yup.string().required("Work Hours are required"),
  job_responsibilties: Yup.string().required(
    "Job Responsibilties are required",
  ),
  company_description: Yup.string().required("Company Description is required"),
});
function ExperienceDialog({
  cvId,
  openExp,
  setOpenExp,
  setLoading,
  loading,
  fetchGetOneExp,
}: {
  fetchGetOneExp: any;
  setLoading: any;
  loading: any;
  cvId: number;
  openExp: boolean;
  setOpenExp: (openExp: boolean) => void;
}) {
  const [experienceData, setExperienceData] = useState({
    resume_id: cvId,
    job_type_id: "",
    category_id: "",
    job_title: "", // Update to match input component naming convention
    work_hours_weekly: "",
    country: "",
    city: "",
    company_name: "",
    salary: "",
    started_at: "",
    ended_at: "",
    still_working: "",
    job_responsibilties: "",
    benefites: "",
    confidetial: "",
    currency: "",
    company_description: "",
  });

  const handleDateChange = (fieldName: string, date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setExperienceData((prevEducationData: any) => ({
      ...prevEducationData,
      [fieldName]: formattedDate,
    }));
  };

  const [selectedJobTypeId, setSelectedJobTypeId] = useState(""); // State to store the selected ID
  const [selectedCurrence, setSelectedCurrency] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState([]);
  const [confidential, setConfidential] = useState(false);
  const [stillWorking, setStillWorking] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [formErrors, setFormErrors] = useState({
    resume_id: cvId,
    job_type_id: "",
    category_id: "",
    job_title: "", // Update to match input component naming convention
    work_hours_weekly: "",
    country: "",
    city: "",
    company_name: "",
    salary: "",
    started_at: "",
    ended_at: "",
    still_working: "",
    job_responsibilties: "",
    benefites: "",
    confidetial: "",
    currency: "",
    company_description: "",
  });
  const handleJobTypeChange = (event) => {
    const selectedId = event.target.value; // Get the selected ID
    setSelectedJobTypeId(selectedId); // Set the selected ID to state
    const selectedItem = selectedJobType.find((item) => item.id === selectedId);
    if (selectedItem) {
     
      const selectedName = selectedItem.name;
    }
  };
  const validate = async () => {
    try {
      // Combine all data to be validated
      const dataToValidate = {
        ...experienceData,
        job_type_id: selectedJobTypeId,
        category_id: selectedCategoryId, // Include selected job availability ID
        currency: selectedCurrence,
      };

      // Validate the combined data against the validation schema
      await validationSchema.validate(dataToValidate, { abortEarly: false });
      setFormErrors({
        resume_id: cvId,
        job_type_id: "",
        category_id: "",
        job_title: "", // Update to match input component naming convention
        work_hours_weekly: "",
        country: "",
        city: "",
        company_name: "",
        salary: "",
        started_at: "",
        ended_at: "",
        still_working: "",
        job_responsibilties: "",
        benefites: "",
        confidetial: "",
        currency: "",
        company_description: "",
      });
      return true; // Return true if validation succeeds
    } catch (errors:any) {
      // If validation fails, extract error messages
      const errorMessages = {
        resume_id: cvId,
        job_type_id: "",
        category_id: "",
        job_title: "", // Update to match input component naming convention
        work_hours_weekly: "",
        country: "",
        city: "",
        company_name: "",
        salary: "",
        started_at: "",
        ended_at: "",
        still_working: "",
        job_responsibilties: "",
        benefites: "",
        confidetial: "",
        currency: "",
        company_description: "",
      };
      errors.inner.forEach((error) => {
        errorMessages[error.path] = error.message;
      });
      setFormErrors(errorMessages); // Set error messages for each field
      return false;
    }
  };
  const handleCategoryChange = (event) => {
    const selectedId = event.target.value; // Get the selected ID
    setSelectedCategoryId(selectedId); // Set the selected ID to state
    const selectedItem = selectedCategory.find(
      (item) => item.id === selectedId,
    );
    if (selectedItem) {
     
      const selectedName = selectedItem.name;
    }
  };
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [country, setCountry] = useState<any>();

  const [childCities, setChildCities] = useState([]);
  const [selectedCityName, setSelectedCityName] = useState("");
  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value; // Get the selected country ID
    setSelectedCountry(selectedCountryId); // Update the selected country ID state

    // Find the selected country object
    const selectedCountryObject = country.find(
      (country) => country.id === selectedCountryId,
    );

    if (selectedCountryObject) {
      setSelectedCountryName(selectedCountryObject.name); // Set the selected country name

      // Set the selected city to "All Cities" if the selected country is not the first item in the list
      if (selectedCountryId == "1") {
        dispatch(GetChildCountries({ id: 1 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        }); // Disable city selection

        setSelectedCity("");
      } else {
        dispatch(GetChildCountries({ id: 2 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        });
        setSelectedCity("");
      }
    }
  };

  const fetchDataPackages = () => {
    dispatch(ParentCountries())
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setCountry(data);
        // Set default selected country to the first one in the list
        if (data.length > 0) {
          setSelectedCountry(data[0].id);
          setSelectedCountryName(data[0].name);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = childCities.find((city) => city.id === selectedCityId);
    setSelectedCity(selectedCityId);
    // Set the selected city name
    setSelectedCityName(selectedCity?.name || "");
  };
  useEffect(() => {
    fetchDataPackages();
    dispatch(GetChildCountries({ id: 1 })).then((action) => {
      const { data } = action.payload;
      setChildCities(data);
      if (data.length > 0) {
        setSelectedCity(data[0].id);
        setSelectedCityName(data[0].name);
      }
      // Set fetched child cities to state
    });
  }, [dispatch]);
  const fetchCategoryData = () => {
    dispatch(getAllCategories({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedCategory(data);
        setLoading(false); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      });
  };
  const fetchJobTypeData = () => {
    dispatch(getAllJobType({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedJobType(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  useEffect(() => {
    fetchDataPackages();
    fetchCategoryData();
    fetchJobTypeData();
  }, [dispatch]);
  const handleConfidentialChange = (event) => {
    setConfidential(event.target.checked); // Set the value of confidentiality based on checkbox state
  };
  const handleStillWorkingChange = (event) => {
    setStillWorking(event.target.checked); // Set the value of confidentiality based on checkbox state
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setExperienceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    // Log average value for debugging

    const isValid = await validate();
    if (isValid) setLoading(true);
    dispatch(
      addNewExperience({
        resume_id: cvId,
        job_type_id: selectedJobTypeId,
        category_id: selectedCategoryId,
        country: selectedCountryName,
        city: selectedCityName,
        job_title: experienceData.job_title,
        company_name: experienceData.company_name,
        salary: experienceData.salary,
        started_at: experienceData.started_at,
        ended_at: experienceData.ended_at,
        still_working: stillWorking,
        job_responsibilties: experienceData.job_responsibilties,
        benefites: experienceData.benefites,
        confidetial: confidential,
        currency: selectedCurrence,
        company_description: experienceData.company_description,
        work_hours_weekly:experienceData.work_hours_weekly,
        salary_range: experienceData.salary,
      }),
    ).then((action) => {
      if (action.type === "experience/addNewExperience/fulfilled") {
        openSnackbar({
          open: true,
          message: "Education Added successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setOpenExp(false);
        setLoading(false);
        fetchGetOneExp();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setOpenExp(false);
        setLoading(false);
      }
    });
  };

  const handleClose = () => {
    setOpenExp(false);
  };

  return (
    <Dialog open={openExp} onClose={handleClose}>
     
        <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>Experiences</DialogTitle>
       
    <Divider/>

      <DialogContent>
      
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="job_title"><FormattedMessage id="job-title" /></InputLabel>
                <TextField
                  fullWidth
                  value={experienceData?.job_title}
                  name="job_title"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  id="job_title"
                  placeholder={intl.formatMessage({ id: "job-title", defaultMessage: "Job title" })}
                />
                {formErrors.job_title && (
                  <FormHelperText error>{formErrors.job_title}</FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="category_id">Field of work</InputLabel>
                <Select
                  id="category_id"
                  value={selectedCategoryId} // Set the selected ID as the value of the Select component
                  onChange={handleCategoryChange} // Handle the change event
                >
                  {selectedCategory.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.category_id && (
                  <FormHelperText error>
                    {formErrors.category_id}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="job_type_id"><FormattedMessage id="job-type" /></InputLabel>
                <Select
                  id="job_type_id"
                  value={selectedJobTypeId} // Set the selected ID as the value of the Select component
                  onChange={handleJobTypeChange} // Handle the change event
                >
                  {selectedJobType.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.job_type_id && (
                  <FormHelperText error>
                    {formErrors.job_type_id}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="work_hours_weekly">
                 
                  <FormattedMessage id="work-hours-weekly" />
                </InputLabel>
                <TextField
                  fullWidth
                  // type="number"
                  id="work_hours_weekly"
                  value={experienceData?.work_hours_weekly}
                  name="work_hours_weekly"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                 placeholder={intl.formatMessage({ id: "work-hours-weekly", defaultMessage: "Work Hours Weekly" })}
                />
                {formErrors.work_hours_weekly && (
                  <FormHelperText error>
                    {formErrors.work_hours_weekly}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="company_name"> <FormattedMessage id="company-name" /></InputLabel>
                <TextField
                  fullWidth
                  // type="number"
                  id="company_name"
                  value={experienceData?.company_name}
                  name="company_name"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder={intl.formatMessage({ id: "company-name", defaultMessage: "Company Name" })}
                />
                {formErrors.company_name && (
                  <FormHelperText error>
                    {formErrors.company_name}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
            <InputLabel style={{display:"none"}} htmlFor="country">Country</InputLabel>
              </Stack></Grid>
            <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
             <InputLabel htmlFor="country"><FormattedMessage id="country" /></InputLabel>
            <FormControl fullWidth>
                      <Select
                       
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        //  onBlur={handleBlur}
                        inputProps={{
                          name: "country",
                          id: "country",
                        }}
                      >
                        {country &&
                          country.map((country: any) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
              </Stack></Grid>

              <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
            <InputLabel htmlFor="city">
                   <FormattedMessage id="city" />
                  </InputLabel>
                  <FormControl fullWidth>
                      <Select
                     
                        labelId="city-label"
                        id="city"
                        value={selectedCity}
                        onChange={handleCityChange}
                        // onBlur={handleBlur}
                        name="city"
                      >
                        {childCities.map((city) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
              </Stack></Grid>

            {/* <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <Stack direction={"row"} display={"flex"}>
                  <InputLabel htmlFor="country">Country</InputLabel>
                  <InputLabel style={{ marginLeft: "130px" }} htmlFor="city">
                    City
                  </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        style={{ marginLeft: "20px", width: "140px" }}
                        labelId="city-label"
                        id="city"
                        value={selectedCity}
                        onChange={handleCityChange}
                        // onBlur={handleBlur}
                        name="city"
                      >
                        {childCities.map((city) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                </Stack>
                <Stack direction={"row"}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    // alignItems="center"
                    spacing={2}
                  >
                    <FormControl fullWidth>
                      <Select
                        style={{ width: "140px" }}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        //  onBlur={handleBlur}
                        inputProps={{
                          name: "country",
                          id: "country",
                        }}
                      >
                        {country &&
                          country.map((country: any) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <FormControl fullWidth>
                      <Select
                        style={{ marginLeft: "20px", width: "140px" }}
                        labelId="city-label"
                        id="city"
                        value={selectedCity}
                        onChange={handleCityChange}
                        // onBlur={handleBlur}
                        name="city"
                      >
                        {childCities.map((city) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <Stack direction={"row"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="salary">Net Salary</InputLabel>
                  <TextField
                    fullWidth
                    // type="number"
                    id="salary"
                    value={experienceData?.salary}
                    name="salary"
                    //onBlur={handleBlur}
                    onChange={handleInputChange}
                    placeholder="Salary"
                  />
                  {formErrors.salary && (
                    <FormHelperText error>{formErrors.salary}</FormHelperText>
                  )}
                </Stack>
                <Stack
                  spacing={1}
                  display={"flex"}
                  alignItems={"end"}
                  justifyContent={"end"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confidential}
                        onChange={handleConfidentialChange}
                        id="confidential-checkbox"
                      />
                    }
                    label="Confidential"
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="currency"><FormattedMessage id="currency" /></InputLabel>
                <Select
                  id="currency"
                  value={selectedCurrence} // Set the selected ID as the value of the Select component
                  onChange={(event) => setSelectedCurrency(event.target.value)} // Handle the change event
                >
                  <MenuItem value={"DOLLAR"}>DOLLAR</MenuItem>
                  <MenuItem value={"SYP"}>SYP</MenuItem>
                </Select>
                {formErrors.currency && (
                  <FormHelperText error>{formErrors.currency}</FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="started_at">Start Date</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={
                      experienceData?.started_at
                        ? dayjs(experienceData.started_at)
                        : null
                    }
                    onChange={(date) => handleDateChange("started_at", date)}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction={"row"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="ended_at">End Date</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={
                        experienceData?.ended_at
                          ? dayjs(experienceData.ended_at)
                          : null
                      }
                      disabled={stillWorking}
                      onChange={(date) => handleDateChange("ended_at", date)}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack
                  spacing={1}
                  display={"flex"}
                  alignItems={"end"}
                  justifyContent={"end"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={stillWorking}
                        onChange={handleStillWorkingChange}
                        id="stillWorking-checkbox"
                      />
                    }
                    label="stillWorking"
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="job_responsibilties">
                  Responsibilties
                </InputLabel>
                <TextArea
                  id="job_responsibilties"
                  value={experienceData?.job_responsibilties}
                  name="job_responsibilties"
                  rows={4}
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Job Responsibilties"
                  style={{height:"auto"}}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="benefites">Benefites</InputLabel>
                <TextArea
                  id="benefites"
                  value={experienceData?.benefites}
                  name="benefites"
                  rows={4}
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Features"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="company_description">
                  Company Description
                </InputLabel>
                <TextArea
                  id="company_description"
                  value={experienceData?.company_description || ""}
                  name="company_description"
                  rows={4}
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Company Description"
                />
                {formErrors.company_description && (
                  <FormHelperText error>
                    {formErrors.company_description}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          </Grid>
         
     
      </DialogContent>
      <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" onClick={handleSave} variant="contained">
                      Add Experience
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={handleClose}>
                        <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
    </Dialog>
  );
}

export default ExperienceDialog;
