import { useEffect, useState } from "react";

// material-ui

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,

  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports

import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets


// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";

import { PackagesResumes } from "model/packages";
import {
  addNewResumePacakges,
  editOneResume,
} from "store/actions/main/packages/resumesPackages";
import TextArea from "antd/es/input/TextArea";
import { CircularProgress } from "@mui/material";

// constant
const getInitialValues = (customer: PackagesResumes | null) => {
  const newCustomer = {
    count:0,
    fees:0,
    price_per_unit:0,
    package_validity: 0,
    package_duration: 0,
    note: "",
    note_trans: "",
    order: 0,
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }
  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormResumeAdd = ({
  customer,
  id,
  fetchData,
  closeModal,
}: {
  id: number;
  fetchData: any;
  customer: PackagesResumes | null;
  closeModal: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const CustomerSchema = Yup.object().shape({
    count: Yup.number().required("Count is required"),
    fees: Yup.number().required("Fees is required"),
    price_per_unit: Yup.number().required("Price Per Unit is required"),
    package_validity: Yup.number().required("Validity Per Unit is required"),
    package_duration: Yup.number().required("Duration Per Unit is required"),
    order: Yup.number().required("Order Per Unit is required"),
    note: Yup.string().required("English note is required"),
    note_trans: Yup.string().required("Arabic note is required"),
  });

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
     validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editOneResume({ id: customer.id || 0, data: values }),
          ).then((action) => {
            fetchData();
            if (action.type === "packages/editOneResume/fulfilled") {
              openSnackbar({
                open: true,
                message: "Resume Package updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addNewResumePacakges(values)).then((action) => {
            fetchData();
            if (action.type === "packages/addNewResumePacakges/fulfilled") {
              openSnackbar({
                open: true,
                message: "Resume Package added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
 
  } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? "Edit resume package details" : "Add new resume package"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                        <InputLabel htmlFor="customer-amount">
                            Count <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the count of the resume'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-amount"
                          placeholder="Enter Count"
                          {...getFieldProps("count")}
                          error={Boolean(touched.count && errors.count)}
                          helperText={touched.count && errors.count}
                        />
                      </Stack>
                </Grid>
                <Grid item md={1}/>
                <Grid item xs={12} md={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-price_per_unit">
                          Fees  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the fees of the resume'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-price_per_unit"
                          placeholder="Enter Price One Unit"
                          {...getFieldProps("fees")}
                          error={Boolean(
                            touched.fees && errors.fees,
                          )}
                          helperText={
                            touched.fees && errors.fees
                          }
                        />
                      </Stack>
                </Grid>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                        <InputLabel htmlFor="customer-amount">
                          Price  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the price of the resume'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-amount"
                          placeholder="Enter Amount"
                          {...getFieldProps("price_per_unit")}
                          error={Boolean(touched.price_per_unit && errors.price_per_unit)}
                          helperText={touched.price_per_unit && errors.price_per_unit}
                        />
                      </Stack>
                </Grid>
                <Grid item md={1}/>
                <Grid item xs={12} md={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customr-package_validity">
                          Vacancies Validity <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the validity of the resume'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-package_validity"
                          placeholder="Enter Validity"
                          {...getFieldProps("package_validity")}
                          error={Boolean(
                            touched.package_validity && errors.package_validity,
                          )}
                          helperText={
                            touched.package_validity && errors.package_validity
                          }
                        />
                      </Stack> 
                </Grid>
                <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-package_duration">
                          Post Duration  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the duration of the resume'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-package_duration"
                          placeholder="Enter Duration"
                          {...getFieldProps("package_duration")}
                          error={Boolean(
                            touched.package_duration && errors.package_duration,
                          )}
                          helperText={
                            touched.package_duration && errors.package_duration
                          }
                        />
                      </Stack>
                </Grid>
                <Grid item md={1}/>
                <Grid item xs={12} sm={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-order">Order  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the order of the resume'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="customer-order"
                      placeholder="Order"
                      {...getFieldProps("order")}
                      error={Boolean(touched.order && errors.order)}
                      helperText={touched.order && errors.order}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={11} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-note">
                      English Note  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the english note of the resume'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                    </InputLabel>
                    <TextArea
                      id="customer-note"
                      placeholder="Note"
                      {...getFieldProps("note")}
                    />
                  </Stack>
                  {touched.note && errors.note && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{ pl: 1.75 }}>
                      {errors.note}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={11} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-note">
                      Arabic Note  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the arabic note of the resume'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                    </InputLabel>
                    <TextArea
                      id="customer-note"
                      placeholder="ادخل الملاحظة"
                      {...getFieldProps("note_trans")}
                      dir="rtl" //sSet the direction to RTL
                      style={{ textAlign: "right" }} // Set text alignment to left
                      maxLength={100} // Set maximum length
                    />
                  </Stack>
                  
                  {touched.note_trans && errors.note_trans && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{ pl: 1.75 }}>
                      {errors.note_trans}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : 'Submit'}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                        <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormResumeAdd;
