import {
  EditOutlined as EditOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from "@mui/icons-material";
import {
  Grid,
  Typography,
  Stack,
  List,
  useMediaQuery,
  ListItem,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks";
import DeletLanguage from "./deleteRecoDialog";
import { Language } from "model/cv/language/language";
import { getAllCompanies } from "store/actions/main/companies";
import EditRecoDialog from "./editDialogReco";
import Loader from "components/Loader";
import { FormattedMessage } from "react-intl";

export function TableReco({
  cvId,
  recoData,
  setLoading,
  loading,
  fetchGetOneReco,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneReco: any;
  cvId: number;
  recoData: any;
}) {
  const languageArray = recoData.resume_recommendations;
  // Filter education certificates based on cvId

  const matchDownMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );
  const [deletId, setDeletId] = useState(0);
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState<Language>();
  const [openDialog, setOpenDialog] = useState(false);
    // @ts-ignore
  const [companyName, setCompanyName] = useState("");
  const dispatch = useAppDispatch();

  const handleEdit = (id: number) => {
    setDeletId(id);
    setOpenDialog(true);
  };
  const handleDelete = (id: number) => {
    setDeletId(id);
    setOpen(true);
  };
  useEffect(() => {
    const fetchName = async () => {
      const action = await dispatch(getAllCompanies({ pageNumber: 1 }));
      const { data } = action.payload;

      // Process availability names
      const availabilityNames = languageArray.map((job: any) => {
        const availability = data.find((av: any) => av.id === job.user_id);
        return availability ? availability.name : "";
      });

      // Combine names into a single string or format as needed
      const combinedNames = availabilityNames.join(", ");
      setCompanyName(combinedNames);
    };

    fetchName();
  }, [recoData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Stack>
          {languageArray.lemgth===0?
           <Grid width={"57vw"} sx={{ mt: "10px" }}>
           <MainCard
             title="Refrence"
             headerActions={
               <Stack
                 direction={"row"}
                 sx={{
                   display: "flex",
                   justifyContent: "space-between",
                   alignItems: "center",
                 }}
               >
                
                 {/* Add additional controls here */}
               </Stack>
             }
           >
             <List>
               <ListItem divider={!matchDownMD}>
                 <Grid container spacing={3}>
                   <Grid item xs={12} md={6}>
                     <Stack spacing={0.5}>
                       <Typography color="secondary">
                         <FormattedMessage id="referencer-name" />
                       </Typography>

                       <Typography></Typography>
                     </Stack>
                   </Grid>
                   <Grid item xs={12} md={6}>
                     <Stack spacing={0.5}>
                       <Typography color="secondary"><FormattedMessage id="position" /></Typography>
                       <Typography></Typography>
                     </Stack>
                   </Grid>
                 </Grid>
               </ListItem>
               <ListItem divider={!matchDownMD}>
                 <Grid container spacing={3}>
                   <Grid item xs={12} md={6}>
                     <Stack spacing={0.5}>
                       <Typography color="secondary"><FormattedMessage id="mobile" /></Typography>
                       <Typography></Typography>
                     </Stack>
                   </Grid>
                   <Grid item xs={12} md={6}>
                     <Stack spacing={0.5}>
                       <Typography color="secondary"><FormattedMessage id="email" /></Typography>
                       <Typography></Typography>
                     </Stack>
                   </Grid>
                 </Grid>
               </ListItem>
               <ListItem divider={!matchDownMD}>
                 <Grid container spacing={3}>
                   <Grid item xs={12} md={6}>
                     <Stack spacing={0.5}>
                       <Typography color="secondary">
                          <FormattedMessage id="company-name" />
                       </Typography>
                       <Typography></Typography>
                     </Stack>
                   </Grid>
                   <Grid item xs={12} md={6}>
                     <Stack spacing={0.5}>
                       <Typography color="secondary"><FormattedMessage id="job-title" /></Typography>
                       <Typography></Typography>
                     </Stack>
                   </Grid>
                 </Grid>
               </ListItem>
               <ListItem>
                 <Grid container spacing={3}>
                   <Grid item xs={12} md={6}>
                     <Stack spacing={0.5}>
                       <Typography color="secondary">
                       
                         <FormattedMessage id="read-by-admin" />
                       </Typography>
                       <Typography>
                       
                       </Typography>
                     </Stack>
                   </Grid>
                 </Grid>
               </ListItem>
             </List>
           </MainCard>
         </Grid>
          : languageArray.map((lang: any, index: number) => (
            <Grid width={"57vw"} sx={{ mt: "10px" }}>
              <MainCard
                title="Refrence"
                headerActions={
                  <Stack
                    direction={"row"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <EditOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          marginRight: 2,
                          cursor: "pointer",
                          color: "#4a934a",
                        }}
                        onClick={() => handleEdit(lang.id)}
                      />
                      <DeleteOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "#ff3333",
                          marginRight: "20px",
                        }}
                        onClick={() => handleDelete(lang.id)}
                      />
                    </div>
                    {/* Add additional controls here */}
                  </Stack>
                }
              >
                <List>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">
                            <FormattedMessage id="referencer-name" />
                          </Typography>

                          <Typography>{lang.recommender_name}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary"><FormattedMessage id="position" /></Typography>
                          <Typography>{lang.position}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary"><FormattedMessage id="mobile" /></Typography>
                          <Typography>{lang.mobile}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary"><FormattedMessage id="email" /></Typography>
                          <Typography>{lang.email}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">
                             <FormattedMessage id="company-name" />
                          </Typography>
                          <Typography>{lang.company_name}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary"><FormattedMessage id="job-title" /></Typography>
                          <Typography>{lang.job_title}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">
                          <FormattedMessage id="read-by-admin" />
                          </Typography>
                          <Typography>
                            {lang.read_by_admin === false ? "false" : "true"}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </MainCard>
            </Grid>
          ))}
        </Stack>
      )}

      {open && (
        <DeletLanguage
          open={open}
          setOpen={setOpen}
          id={deletId}
          fetchGetOneReco={fetchGetOneReco}
        />
      )}
      {openDialog && (
        <EditRecoDialog
          setLoading={setLoading}
          loading={loading}
          fetchGetOneReco={fetchGetOneReco}
          resumeId={cvId}
          cvId={deletId}
          open={openDialog}
          setOpen={setOpenDialog}
          language={language}
          setLanguage={setLanguage}
        />
      )}
    </>
  );
}
