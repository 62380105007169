import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import bannerURL from "../../../../utils/url/basicInfo/bannerPlace";

//get AllUser
export const getAllBannerPlace = createAsyncThunk(
  "basicInfo/getAllBannerPlace",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${bannerURL.GET_BANNER_PLACE}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER
export const searshAllBannerPlace = createAsyncThunk(
  "basicInfo/searshAllBannerPlace",
  async ({ name_trans }: { name_trans: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${bannerURL.GET_BANNER_PLACE}?name_trans=${name_trans}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const getOneBannerPlace = createAsyncThunk(
  "basicInfo/getOneBannerPlace",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${bannerURL.GET_ONE_BANNER_PLACE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editBannerPlace = createAsyncThunk(
  "basicInfo/editBannerPlace",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${bannerURL.EDIT_BANNER_PLACE}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addBannerPlace = createAsyncThunk(
  "basicInfo/addBannerPlace",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(bannerURL.ADD_BANNER_PLACE, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteBannerPlace = createAsyncThunk(
  "basicInfo/deleteBannerPlace",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${bannerURL.DELETE_BANNER_PLACE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletBannerPlace = createAsyncThunk(
  "basicInfo/MultiDeletBannerPlace",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        bannerURL.MULTI_DELET_BANNER_PLACEE,
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
