import ShopTwoIcon from "@mui/icons-material/ShopTwo";
import { FormattedMessage,useIntl } from "react-intl";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
// icons

const useADSItem = () => {
  const icons = {
    ShopTwoIcon,
    BusinessOutlinedIcon,
    WhereToVoteIcon,
  };

  // ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

  const packages = {
    id: "ads-management",
    title: (
      <FormattedMessage id="ads-management">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "banners-mangment",
        title: <FormattedMessage id="banners-mangment" />,
        type: "item",
        url: "/banners",
        icon: icons.ShopTwoIcon,
        breadcrumbs: false,
      },

      {
        id: "best-place",
        title: <FormattedMessage id="best-place" />,
        type: "item",
        url: "/best-place",
        icon: icons.WhereToVoteIcon,
        breadcrumbs: false,
      },

      {
        id: "major-company",
        title: <FormattedMessage id="major-company" />,
        type: "item",
        url: "/major-company",
        icon: icons.BusinessOutlinedIcon,
        breadcrumbs: false,
      },
    ],
  };
  return packages;
};

export default useADSItem;
