// assets

import { ProfileOutlined, FileTextOutlined } from "@ant-design/icons";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage,useIntl } from "react-intl";
// icons

const usePackagesItem = () => {
  const icons = {
    BusinessCenterOutlinedIcon,
    ProfileOutlined,
    FileTextOutlined,
    CheckCircleOutlineIcon,
    DescriptionIcon,
    SearchIcon,
  };

  // ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

  const packages = {
    id: "backages-and-subscription-management",
    title: (
      <FormattedMessage id="credits&money">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "search-engine-credits",
        title: <FormattedMessage id="search-engine-credits" />,
        type: "item",
        url: "/search-engine-credits",
        icon: icons.SearchIcon,
        breadcrumbs: false,
      },
      {
        id: "vacancies-packages",
        title: <FormattedMessage id="vacancies-packages" />,
        type: "item",
        url: "/vacancies-packages",
        icon: icons.BusinessCenterOutlinedIcon,
        breadcrumbs: false,
      },
      {
        id: "resumes-packages",
        title: <FormattedMessage id="resumes-packages" />,
        type: "item",
        url: "/resumes-packages",
        icon: icons.ProfileOutlined,
        breadcrumbs: false,
      },
      {
        id: "orders-jobs",
        title: <FormattedMessage id="vacancies-orders" />,
        type: "item",
        url: "/orders-jobs",
        icon: icons.CheckCircleOutlineIcon,
        breadcrumbs: false,
      },
      {
        id: "orders-resumes",
        title: <FormattedMessage id="resumes-orders" />,
        type: "item",
        url: "/orders-resumes",
        icon: icons.CheckCircleOutlineIcon,
        breadcrumbs: false,
      },
      {
        id: "subscription-jobs",
        title: <FormattedMessage id="vacancies-subscription" />,
        type: "item",
        url: "/subscription-jobs",
        icon: icons.DescriptionIcon,
        breadcrumbs: false,
      },
      {
        id: "subscription-resumes",
        title: <FormattedMessage id="resumes-subscription" />,
        type: "item",
        url: "/subscription-resumes",
        icon: icons.DescriptionIcon,
        breadcrumbs: false,
      },
    ],
  };
  return packages;
};

export default usePackagesItem;
