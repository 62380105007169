import { RefObject, useEffect, useRef, useState } from "react";
import { useLocation, useOutletContext, useParams } from "react-router";

// material-ui
import {
  Autocomplete,
  Box,
  Button,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Chip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  Typography,
} from "@mui/material";
import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import { openSnackbar } from "api/snackbar";
import MainCard from "components/MainCard";
// types
import { SnackbarProps } from "types/snackbar";
import phoneNumber from "hooks/data/phoneNumber";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  GetChildCountries,
  ParentCountries,
} from "store/actions/main/countries";
import ComapnyTabs from "./companyTabs";
import ComapnyProfileTabs from "./companyProfileTabs";
import { fieldConfigurations } from "./companyList";
import TextArea from "antd/es/input/TextArea";
import { getAllCategories } from "store/actions/main/categories";
import {
  addNewCompanyProfile,
  editCompanyProfile,
  getOneCompanyProfile,
} from "store/actions/main/companyProfile";
import { getAllTitles } from "store/actions/main/basicInfo/title";

// ==============================|| TAB - PERSONAL ||============================== //

const EditCompanyProfile = () => {
  const maxDate = new Date();

  const location = useLocation();
  const pathname = window.location.pathname;

  // Split the pathname into segments using "/"
  const segments = pathname.split("/");
  const [basicResumeId, setbasicResumeId] = useState("");
  // Extract the resume ID (second-to-last segment) and user ID (last segment)
  const resumeId = segments[segments.length - 2];
  const userId = segments[segments.length - 1];
  const companyId = isNaN(parseInt(resumeId))
    ? basicResumeId
    : parseInt(resumeId);

  const [selectednationality, setNationality] = useState("");

  const [activeSection, setActiveSection] = useState(0); // State variable for active section index

  useEffect(() => {
    const navButtons = document.querySelectorAll(".nav-button");
    navButtons.forEach((button) => {
      button.classList.remove("active");
    });
    const activeButton = document.querySelector(`#nav-button-${activeSection}`);
    if (activeButton) {
      activeButton.classList.add("active");
    }
  }, [activeSection]);

  const dispatch = useAppDispatch();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [country, setCountry] = useState<any>();

  const [childCities, setChildCities] = useState([]); // Step 3: State to store child cities

  // This will give you the value "2" from the URL

  const [profileData, setProfileData] = useState({
    user_id: 1,
    email: "",
    country: "",
    user_name: "",
    city: "",
    registration_date: "",
    name_en: "",
    name_trans: "",
    description_en: "",
    description_trans: "",
    url: "",
    post_office: "",
    address_en: "",
    address_trans: "",
    phone_number: "",
    nationality: "",
    commercial_register: "",
    employees_num: 0,
    expected_employees_num: "",
    logo: "",
    company_type: "",
    contacted: false,
    client: "",
    satisfied: false,
    culture: "",
    financial_ability: "",
    service_required: "",
    our_contact: "",
    hr_dept: false,
    trusted: false,
    apply_on_other_site: false,
    apply_url: "",
    pdf_mode: false,
    optional_end_date: false,
    categories: [],
    countryCode: "",
    title_id: "",
    full_name: "",
    position: "",
    mobile: "",
    phone: "",
    employee_email: "",
  });

  const [code, setCode] = useState("");
  // Ensure `dispatch` and `id` are in the dependency array
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedculture, setSelectedculture] = useState("");
  const [selectedfinancial, setSelectedfinancial] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [selectedTitleID, setSelectedTitleID] = useState("");
  const [selectedserviceRequired, setSelectedserviceRequired] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleChange = (event: React.ChangeEvent<any>) => {
    const { name, value, type, checked } = event.target;

    // Handle different input types
    const newValue = type === "checkbox" ? checked : value;

    // Update profileData state with the new value
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      [name]: newValue,
    }));

    // Toggle military service field based on selected gender
  };
  const fetchGetOne = () => {
    dispatch(getOneCompanyProfile({ companyId: companyId || userId })).then(
      (action) => {
        const data = action.payload["result: "];
        setProfileData(data);
      },
    );
  };
  const fetchCategoryData = () => {
    setLoading(true);
    dispatch(getAllCategories({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedCategory(data);
        setLoading(false); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      });
  };
  const validationSchema = Yup.object().shape({
    name_en:Yup.string().required("Name is required"),
    name_trans: Yup.string().required("Arabic Name is required")
       ,
    description_en: Yup.string().required("Description is required")
       ,
    description_trans: Yup.string().required("Arabic Description is required")
      ,
    url: Yup.string().required("Url full name is required")
        ,
    post_office: Yup.date().required("Post Office is required"),
    address_en: Yup.string().required("Address English is required"),
    email: Yup.string().required("Email is required")
        ,
    address_trans: Yup.string().required("Address Arabic is required"),
    phone_number: Yup.number().required("Phone number is required"),
    // Add more fields as needed
    // Add more fields as needed

    // Add more fields as needed
  });
  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value; // Get the selected country ID
    setSelectedCountry(selectedCountryId); // Update the selected country ID state

    // Find the selected country object
    const selectedCountryObject = country.find(
      (country) => country.id === selectedCountryId,
    );
    console.log("Selected Country Object:", selectedCountryObject); // Add this line for debugging
    console.log("Selected Country ID:", selectedCountryId); // Add this line for debugging

    if (selectedCountryObject) {
      setSelectedCountryName(selectedCountryObject.name); // Set the selected country name

      // Set the selected city to "All Cities" if the selected country is not the first item in the list
      if (selectedCountryId == "1") {
        dispatch(GetChildCountries({ id: 1 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        }); // Disable city selection

        setSelectedCity("");
      } else {
        dispatch(GetChildCountries({ id: 2 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        });
        setSelectedCity("");
      }
    }
  };

  // Dispatch parent countries action when the component mounts

  const fileInputRef = useRef(null);
  // Function to handle file change event
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setProfileData((prevState) => ({
        ...prevState,
        logo: file,
      }));
    } else {
      console.error("The selected file is not an image.");
    }
  };
  const syrianCities = [
    { code: "+21" },
    { code: "+11" },
    { code: "+15" },
    { code: "+51" },
    { code: "+33" },
    { code: "+52" },
    { code: "+31" },
    { code: "+23" },

    { code: "+14" },
    { code: "+41" },

    { code: "+22" },
    { code: "+32" },
    { code: "+16" },
    { code: "+43" },
  ];
  const [selectedCityCode, setSelectedCityCode] = useState(
    syrianCities.find((city) => city.code === "+11"),
  ); // Default to Damascus/ Dispatch parent countries action when the component mounts
  const handleCityCodeChange = (event, newValue) => {
    setSelectedCityCode(newValue);
    setCode(newValue ? newValue.code : "");
  };

  const fetchDataPackages = () => {
    dispatch(ParentCountries())
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setCountry(data);
        // Set default selected country to the first one in the list
        if (data.length > 0) {
          setSelectedCountry(data[0].id);
          setSelectedCountryName(data[0].name);
          setNationality(data[0].nationality);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleCategoryChange = (event, value) => {
    setSelectedCategories(value); // Update selected countries
  };
  useEffect(() => {
    fetchDataPackages();
    dispatch(GetChildCountries({ id: 1 })).then((action) => {
      const { data } = action.payload;
      setChildCities(data);
      if (data.length > 0) {
        setSelectedCity(data[0].id);
        setSelectedCityName(data[0].name);
      }
      // Set fetched child cities to state
    });
  }, [dispatch]);
  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = childCities.find((city) => city.id === selectedCityId);
    setSelectedCity(selectedCityId);
    // Set the selected city name
    setSelectedCityName(selectedCity?.name || "");
  };
  const fetchTitleData = () => {
    dispatch(getAllTitles({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedTitle(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleTitleChange = (event) => {
    const selectedId = event.target.value; // Get the selected ID
    setSelectedTitleID(selectedId); // Set the selected ID to state
    const selectedItem = selectedTitle.find((item) => item.id === selectedId);
    if (selectedItem) {
      const selectedName = selectedItem.name;
    }
  };
  useEffect(() => {
    fetchGetOne();
    fetchTitleData();
    fetchDataPackages();
    fetchCategoryData();
  }, [dispatch]);

  return (
    <>
      <section>
        <Stack direction={"row"}>
          <Grid style={{ height: "100px", marginRight: "20px", width: "100%" }}>
            <ComapnyTabs />
            <ComapnyProfileTabs />
          </Grid>
          <Stack direction={"column"}>
            <MainCard
              content={false}
              title="Edit Company Information"
              sx={{ "& .MuiInputLabel-root": { fontSize: "0.875rem" } }}
            >
              <Formik
                initialValues={{
                  name_en: profileData?.name_en || "",
                  name_trans: profileData?.name_trans || "",
                  description_en: profileData?.description_en || "",
                  description_trans: profileData?.description_trans || "",
                  url: profileData?.url || "",
                  email: profileData?.email || "",
                  post_office: profileData?.post_office || "",
                  address_en: profileData?.address_en || "",
                  address_trans: profileData?.address_trans || "",
                  phone_number: profileData?.phone_number || "",
                  nationality: profileData?.nationality || "",
                  commercial_register: profileData?.commercial_register || "",
                  employees_num: profileData?.employees_num || 0,
                  expected_employees_num:
                    profileData?.expected_employees_num || 0,
                  company_logo: profileData?.logo || "",
                  company_type: profileData?.company_type || "",
                  contacted: profileData?.contacted || false,
                  client: profileData?.client || "",
                  satisfied: profileData?.satisfied || false,
                  culture: profileData?.culture || "",
                  financial_ability: profileData?.financial_ability || "",
                  service_required: profileData?.service_required || "",
                  our_contact: profileData?.our_contact || "",
                  hr_dept: profileData?.hr_dept || false,
                  trusted: profileData?.trusted || false,
                  apply_on_other_site:
                    profileData?.apply_on_other_site || false,
                  apply_url: profileData?.apply_url || "",
                  pdf_mode: profileData?.pdf_mode || false,
                  optional_end_date: profileData?.optional_end_date || false,
                  categories: profileData?.categories || [],
                  country: profileData?.country || "",
                  city: profileData?.city || "",
                  title_id: profileData?.title_id || 0,
                  full_name: profileData?.full_name || "",
                  position: profileData?.position || "",
                  mobile: profileData?.position || "",
                  phone: profileData?.position || "",
                  employee_email: profileData?.employee_email || "",
                  submit: null,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const selectedcatIds = selectedCategories.map(
                    (cat) => cat.id,
                  );
                  console.log(selectedcatIds);
                  const formData = new FormData();
// @ts-ignore
                  formData.append("user_id", profileData.user_id);
                  formData.append("name_en", profileData.name_en);
                  formData.append("name_trans", profileData.name_trans);
                  formData.append("description_en", profileData.description_en);
                  formData.append(
                    "description_trans",
                    profileData.description_trans,
                  );
                  formData.append("url", profileData.url);
                  formData.append("post_office", profileData.post_office);
                  formData.append("address_en", profileData.address_en);
                  formData.append("address_trans", profileData.address_trans);
                  formData.append(
                    "nationality",
                    selectednationality.toString(),
                  );
                  // @ts-ignore
                  formData.append("category ids", selectedcatIds);
                  formData.append(
                    "commercial_register",
                    profileData.commercial_register,
                  );
                  formData.append(
                    "employees_num",
                    profileData.employees_num.toString(),
                  );
                  formData.append(
                    "expected_employees_num",
                    profileData.expected_employees_num.toString(),
                  );
                  formData.append("company_type", selectedType);
                  formData.append(
                    "contacted",
                    profileData.contacted === true ? "1" : "0",
                  );

                  formData.append("client", selectedClient);
                  formData.append(
                    "satisfied",
                    profileData.satisfied === true ? "1" : "0",
                  );
                  formData.append("culture", selectedculture);
                  formData.append("financial_ability", selectedfinancial);
                  formData.append("service_required", selectedserviceRequired);
                  formData.append("our_contact", selectedContact);
                  formData.append("country", selectedCountryName);
                  formData.append("city", selectedCityName);
                  formData.append("email", profileData.email);
                  formData.append("employee[phone]", profileData.phone);
                  formData.append("employee[position]", profileData.position);
                  formData.append(
                    "employee[employee_email]",
                    profileData.employee_email,
                  );
                  formData.append("employee[mobile]", profileData.mobile);
                  formData.append("employee[title_id]", selectedTitleID);
                  formData.append("employee[full_name]", profileData.full_name);
                  formData.append(
                    "hr_dept",
                    profileData.hr_dept === true ? "1" : "0",
                  );
                  formData.append(
                    "trusted",
                    profileData.trusted === true ? "1" : "0",
                  );
                  formData.append(
                    "apply_on_other_site",
                    profileData.apply_on_other_site === true ? "1" : "0",
                  );
                  formData.append("apply_url", profileData.apply_url);
                  formData.append(
                    "pdf_mode",
                    profileData.pdf_mode === true ? "1" : "0",
                  );
                  formData.append(
                    "optional_end_date",
                    profileData.optional_end_date === true ? "1" : "0",
                  );

                  // Handle company logo file

                  // Append categories
                  const countryCode = Object.values(code)[0]; // Assuming code is an object with only one key-value pair

                  // Concatenate country code and phone number
                  const phoneNumberWithCode = `${countryCode}-${profileData.phone_number}`;
                  formData.append("phone_number", phoneNumberWithCode);

                  // Dispatch the form data
                  dispatch(
                    editCompanyProfile({
                      companyId: companyId,
                      data: formData,
                    }),
                  )
                    .then((action) => {
                      if (
                        action.type ===
                        "companyProfile/editCompanyProfile/fulfilled"
                      ) {
                        openSnackbar({
                          open: true,
                          message: "Profile edited successfully",
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          variant: "alert",
                          alert: {
                            color: "success",
                          },
                        } as SnackbarProps);
                        setSubmitting(false);
                      }
                    })
                    .catch((error) => {
                      console.error("Error adding profile:", error);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <>
                    <form noValidate onSubmit={handleSubmit}>
                      <Divider />
                      <Box sx={{ p: 2.5 }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            <Box
                              sx={{
                                width: "100%", // Adjust width as needed
                                height: "100%", // Adjust height as needed
                                border: "1px solid lightblue",
                                backgroundColor: "white",
                                position: "relative", // Set position to relative for absolute positioning
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  position: "absolute",
                                  top: "-12px", // Adjust position as needed
                                  left: "10px", // Adjust position as needed
                                  backgroundColor: "white",
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                User Name
                              </Typography>
                              <Box sx={{ p: 2.5 }}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-user_name"
                                        style={{
                                          marginTop: "30px",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        User Name
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-user_name"
                                        value={profileData?.user_name}
                                        name="name_en"
                                        sx={{
                                          "& .MuiInputBase-input.Mui-disabled":
                                            {
                                              WebkitTextFillColor: "black", // This makes the text color black in Chrome, Safari, and Edge
                                              color: "black", // This makes the text color black in Firefox
                                            },
                                        }}
                                        placeholder="Full Name English"
                                        autoFocus
                                        disabled
                                      />
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Box
                              sx={{
                                width: "100%", // Adjust width as needed
                                height: "100%", // Adjust height as needed
                                border: "1px solid lightblue",
                                backgroundColor: "white",
                                position: "relative", // Set position to relative for absolute positioning
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  position: "absolute",
                                  top: "-12px", // Adjust position as needed
                                  left: "10px", // Adjust position as needed
                                  backgroundColor: "white",
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                Company Name & Description
                              </Typography>
                              <Box sx={{ p: 2.5 }}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-name_en"
                                        style={{
                                          marginTop: "30px",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Name English
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-name_en"
                                        value={profileData?.name_en}
                                        name="name_en"
                                        // onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Full Name English"
                                        autoFocus
                                        error={
                                          touched.name_en && !!errors.name_en
                                        }
                                        helperText={
                                          touched.name_en && errors.name_en
                                        }
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-name_trans"
                                        style={{
                                          marginBottom: "10px",
                                          marginTop: "30px",
                                        }}
                                      >
                                        Name Arabic
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-name_trans"
                                        value={profileData?.name_trans}
                                        name="name_trans"
                                        // onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Full Name Arabic"
                                        autoFocus
                                        error={
                                          touched.name_trans &&
                                          !!errors.name_trans
                                        }
                                        helperText={
                                          touched.name_trans &&
                                          errors.name_trans
                                        }
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-description_en"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Company Description In English
                                      </InputLabel>
                                      <TextArea
                                        id="description_en"
                                        placeholder="Company Description In English"
                                        value={profileData?.description_en}
                                        name="description_en" // Add the name attribute
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                      {touched.description_en &&
                                        errors.description_en && (
                                          <FormHelperText error>
                                            {errors.description_en}
                                          </FormHelperText>
                                        )}
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-description_trans"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Company Description In Arabic
                                      </InputLabel>
                                      <TextArea
                                        id="description_trans"
                                        placeholder="Company Description In Arabic"
                                        value={profileData?.description_trans}
                                        name="description_trans" // Add the name attribute
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                      {touched.description_trans &&
                                        errors.description_trans && (
                                          <FormHelperText error>
                                            {errors.description_trans}
                                          </FormHelperText>
                                        )}
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Box
                              sx={{
                                width: "100%", // Adjust width as needed
                                height: "100%", // Adjust height as needed
                                border: "1px solid lightblue",
                                backgroundColor: "white",
                                position: "relative", // Set position to relative for absolute positioning
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  position: "absolute",
                                  top: "-12px", // Adjust position as needed
                                  left: "10px", // Adjust position as needed
                                  backgroundColor: "white",
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                Contcat Information
                              </Typography>
                              <Box sx={{ p: 2.5 }}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-email"
                                        style={{
                                          marginTop: "30px",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        E-mail
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-email"
                                        value={profileData?.email}
                                        name="email"
                                        // onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="email"
                                        autoFocus
                                        error={touched.email && !!errors.email}
                                        helperText={
                                          touched.email && errors.email
                                        }
                                      />
                                    </Stack>
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-post_office"
                                        style={{
                                          marginTop: "30px",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Post Office
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-post_office"
                                        value={profileData?.post_office}
                                        name="post_office"
                                        // onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Post Office"
                                        autoFocus
                                        error={
                                          touched.post_office &&
                                          !!errors.post_office
                                        }
                                        helperText={
                                          touched.post_office &&
                                          errors.post_office
                                        }
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-mobile_number"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        Mobile Number
                                      </InputLabel>
                                      <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Autocomplete
                                          style={{ width: "200px" }}
                                          id="personal-country-code"
                                          options={phoneNumber}
                                          value={{ dialCode: "+963" }}
                                          autoHighlight
                                          getOptionLabel={(option) =>
                                            option.dialCode
                                          }
                                          onChange={(event, newValue:any) => {
                                            // newValue here will be the selected option
                                            setCode(newValue); // You can use newValue to get the selected value
                                          }}
                                          renderOption={(props, option) => (
                                            <MenuItem
                                              component="li"
                                              sx={{ typography: "body2" }}
                                              {...props}
                                            >
                                              ({option.dialCode})
                                            </MenuItem>
                                          )}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              name="countryCode"
                                              placeholder=""
                                            />
                                          )}
                                        />

                                        <TextField
                                          fullWidth
                                          id="customer-mobile_number"
                                          placeholder="xxx xxx xxx"
                                          value={profileData.phone_number}
                                          name="phone_number" // Add the name attribute
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          inputProps={{ maxLength: 10 }} // Add the onChange handler
                                        />
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack spacing={1}>
                                      <Stack direction={"row"} display={"flex"}>
                                        <InputLabel htmlFor="country">
                                          Country
                                        </InputLabel>
                                        <InputLabel
                                          style={{ marginLeft: "120px" }}
                                          htmlFor="city"
                                        >
                                          City
                                        </InputLabel>
                                      </Stack>
                                      <Stack direction={"row"}>
                                        <Stack
                                          direction="row"
                                          justifyContent="space-between"
                                          // alignItems="center"
                                        >
                                          <FormControl fullWidth>
                                            <Select
                                              style={{ width: "175px" }}
                                              value={selectedCountry}
                                              onChange={handleCountryChange}
                                              //  onBlur={handleBlur}
                                              inputProps={{
                                                name: "country",
                                                id: "country",
                                              }}
                                            >
                                              {country &&
                                                country.map((country: any) => (
                                                  <MenuItem
                                                    key={country.id}
                                                    value={country.id}
                                                  >
                                                    {country.name}
                                                  </MenuItem>
                                                ))}
                                            </Select>
                                          </FormControl>
                                        </Stack>

                                        <Stack
                                          direction="row"
                                          justifyContent="space-between"
                                          spacing={2}
                                        >
                                          <FormControl fullWidth>
                                            <Select
                                              style={{
                                                marginLeft: "20px",
                                                width: "175px",
                                              }}
                                              labelId="city-label"
                                              id="city"
                                              value={selectedCity}
                                              onChange={handleCityChange}
                                              // onBlur={handleBlur}
                                              name="city"
                                            >
                                              {childCities.map((city) => (
                                                <MenuItem
                                                  key={city.id}
                                                  value={city.id}
                                                >
                                                  {city.name}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Stack>
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-url"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        URL
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-url"
                                        value={profileData?.url}
                                        name="url"
                                        // onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Url"
                                        autoFocus
                                        error={touched.url && !!errors.url}
                                        helperText={touched.url && errors.url}
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-address_en"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        Address Engilsh
                                      </InputLabel>
                                      <TextArea
                                        id="address_en"
                                        placeholder=" Address Engilsh"
                                        value={profileData?.address_en}
                                        name="address_en" // Add the name attribute
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                      {touched.address_en &&
                                        errors.address_en && (
                                          <FormHelperText error>
                                            {errors.address_en}
                                          </FormHelperText>
                                        )}
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-address_en"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        Address Arabic
                                      </InputLabel>
                                      <TextArea
                                        id="address_trans"
                                        placeholder=" Address Arabic"
                                        value={profileData?.address_trans}
                                        name="address_trans" // Add the name attribute
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                      {touched.address_trans &&
                                        errors.address_trans && (
                                          <FormHelperText error>
                                            {errors.address_trans}
                                          </FormHelperText>
                                        )}
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Box
                              sx={{
                                width: "100%", // Adjust width as needed
                                height: "100%", // Adjust height as needed
                                border: "1px solid lightblue",
                                backgroundColor: "white",
                                position: "relative", // Set position to relative for absolute positioning
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  position: "absolute",
                                  top: "-12px", // Adjust position as needed
                                  left: "10px", // Adjust position as needed
                                  backgroundColor: "white",
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                Contcat Person
                              </Typography>
                              <Box sx={{ p: 2.5 }}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-title"
                                        style={{
                                          marginBottom: "10px",
                                          marginTop: "30px",
                                        }}
                                      >
                                        Title
                                      </InputLabel>
                                      <Select
                                        id="title"
                                        value={selectedTitleID} // Set the selected ID as the value of the Select component
                                        onChange={handleTitleChange} // Handle the change event
                                      >
                                        {selectedTitle.map((user) => (
                                          <MenuItem
                                            key={user.id}
                                            value={user.id}
                                          >
                                            {user.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-name_trans"
                                        style={{
                                          marginTop: "30px",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Full Name
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-full_name"
                                        value={profileData?.full_name}
                                        name="full_name"
                                        // onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Full Name"
                                        autoFocus
                                        error={
                                          touched.name_trans &&
                                          !!errors.name_trans
                                        }
                                        helperText={
                                          touched.name_trans &&
                                          errors.name_trans
                                        }
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-position"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Position
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-position"
                                        value={profileData?.position}
                                        name="position"
                                        // onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Position"
                                        autoFocus
                                        error={
                                          touched.post_office &&
                                          !!errors.post_office
                                        }
                                        helperText={
                                          touched.post_office &&
                                          errors.post_office
                                        }
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-address_en"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        Mobile Number
                                      </InputLabel>
                                      <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Autocomplete
                                          style={{ width: "200px" }}
                                          id="personal-country-code"
                                          options={phoneNumber}
                                          value={{ dialCode: "+963" }}
                                          autoHighlight
                                          getOptionLabel={(option) =>
                                            option.dialCode
                                          }
                                          onChange={(event, newValue:any) => {
                                            // newValue here will be the selected option
                                            setCode(newValue); // You can use newValue to get the selected value
                                          }}
                                          renderOption={(props, option) => (
                                            <MenuItem
                                              component="li"
                                              sx={{ typography: "body2" }}
                                              {...props}
                                            >
                                              ({option.dialCode})
                                            </MenuItem>
                                          )}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              name="countryCode"
                                              placeholder=""
                                            />
                                          )}
                                        />

                                        <TextField
                                          fullWidth
                                          id="customer-mobile"
                                          placeholder="xxx xxx xxx"
                                          value={profileData.mobile}
                                          name="mobile" // Add the name attribute
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          inputProps={{ maxLength: 10 }} // Add the onChange handler
                                        />
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-address_en"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        Phone
                                      </InputLabel>
                                      <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Autocomplete
                                          style={{ width: "100px" }}
                                          id="personal-country-code"
                                          options={phoneNumber}
                                          value={{ dialCode: "+963" }}
                                          autoHighlight
                                          getOptionLabel={(option) =>
                                            option.dialCode
                                          }
                                          onChange={(event, newValue:any) => {
                                            // newValue here will be the selected option
                                            setCode(newValue); // You can use newValue to get the selected value
                                          }}
                                          renderOption={(props, option) => (
                                            <MenuItem
                                              component="li"
                                              sx={{ typography: "body2" }}
                                              {...props}
                                            >
                                              ({option.dialCode})
                                            </MenuItem>
                                          )}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              name="countryCode"
                                              placeholder=""
                                            />
                                          )}
                                        />

                                        <Autocomplete
                                          style={{ width: "100px" }}
                                          id="personal-country-code"
                                          options={syrianCities}
                                          value={selectedCityCode}
                                          autoHighlight
                                          getOptionLabel={(option) =>
                                            `${option.code}`
                                          }
                                          onChange={handleCityCodeChange}
                                          renderOption={(props, option) => (
                                            <MenuItem
                                              component="li"
                                              sx={{ typography: "body2" }}
                                              {...props}
                                            >
                                              ({option.code})
                                            </MenuItem>
                                          )}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              name="cityCode"
                                            />
                                          )}
                                        />

                                        <TextField
                                          fullWidth
                                          id="customer-phone"
                                          placeholder="xxx xxx x"
                                          value={profileData.phone}
                                          name="phone" // Add the name attribute
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          inputProps={{ maxLength: 10 }} // Add the onChange handler
                                        />
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-employee_email"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        E-mail
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-employee_email"
                                        value={profileData?.employee_email}
                                        name="employee_email"
                                        // onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Employee Email"
                                        autoFocus
                                        error={
                                          touched.post_office &&
                                          !!errors.post_office
                                        }
                                        helperText={
                                          touched.post_office &&
                                          errors.post_office
                                        }
                                      />
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Box
                              sx={{
                                width: "100%", // Adjust width as needed
                                height: "100%", // Adjust height as needed
                                border: "1px solid lightblue",
                                backgroundColor: "white",
                                position: "relative", // Set position to relative for absolute positioning
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  position: "absolute",
                                  top: "-12px", // Adjust position as needed
                                  left: "10px", // Adjust position as needed
                                  backgroundColor: "white",
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                Company Activity
                              </Typography>
                              <Box sx={{ p: 2.5 }}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={12}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-url"
                                        style={{
                                          marginBottom: "10px",
                                          marginTop: "30px",
                                        }}
                                      >
                                        Company Activity
                                      </InputLabel>
                                      <Stack direction={"row"}>
                                        <Autocomplete
                                          style={{
                                            width: "100%",
                                          }}
                                          multiple // Enable multi-select
                                          id="categories-autocomplete"
                                          options={selectedCategory}
                                          getOptionLabel={(option) =>
                                            option.name
                                          } // Customize option label
                                          value={selectedCategories}
                                          onChange={handleCategoryChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="outlined"
                                              label="Company Activity"
                                              placeholder="Select Company Activity"
                                            />
                                          )}
                                        />
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-url"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Nationality
                                      </InputLabel>
                                      <FormControl fullWidth>
                                        <Select
                                          name="nationality"
                                          value={selectednationality}
                                          onChange={(event) =>
                                            setNationality(event.target.value)
                                          }
                                          onBlur={handleBlur}
                                          inputProps={{
                                            name: "nationality",
                                            id: "nationality",
                                          }}
                                        >
                                          {country &&
                                            country.map((country: any) => (
                                              <MenuItem
                                                key={country.id}
                                                value={country.nationality}
                                              >
                                                {country.nationality}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-employees_num"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Employees Num
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-employees_num"
                                        value={profileData?.employees_num}
                                        name="employees_num"
                                        // onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Employees Num"
                                        autoFocus
                                        //inputRef={inputRef}
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-expected_employees_num"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Estimated Employment
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-expected_employees_num"
                                        value={
                                          profileData?.expected_employees_num
                                        }
                                        name="expected_employees_num"
                                        // onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Estimated Employment"
                                        autoFocus
                                        //inputRef={inputRef}
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-commercial_register"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Commercial Register
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-commercial_register"
                                        value={profileData?.commercial_register}
                                        name="commercial_register"
                                        // onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Commercial Register"
                                        autoFocus
                                        //inputRef={inputRef}
                                      />
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Box
                              sx={{
                                width: "100%", // Adjust width as needed
                                height: "100%", // Adjust height as needed
                                border: "1px solid lightblue",
                                backgroundColor: "white",
                                position: "relative", // Set position to relative for absolute positioning
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  position: "absolute",
                                  top: "-12px", // Adjust position as needed
                                  left: "10px", // Adjust position as needed
                                  backgroundColor: "white",
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                Additinal Info
                              </Typography>
                              <Box sx={{ p: 2.5 }}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-company_type"
                                        style={{
                                          marginBottom: "10px",
                                          marginTop: "30px",
                                        }}
                                      >
                                        Company Type
                                      </InputLabel>
                                      <FormControl fullWidth>
                                        <Select
                                          id="company_type"
                                          value={selectedType} // Set the selected ID as the value of the Select component
                                          onChange={(event) =>
                                            setSelectedType(event.target.value)
                                          } // Handle the change event
                                        >
                                          <MenuItem value={"A"}>A</MenuItem>
                                          <MenuItem value={"B"}>B</MenuItem>
                                          <MenuItem value={"C"}>C</MenuItem>
                                          <MenuItem value={"D"}>D</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-client"
                                        style={{
                                          marginBottom: "10px",
                                          marginTop: "30px",
                                        }}
                                      >
                                        Client
                                      </InputLabel>
                                      <FormControl fullWidth>
                                        <Select
                                          id="client"
                                          value={selectedClient} // Set the selected ID as the value of the Select component
                                          onChange={(event) =>
                                            setSelectedClient(
                                              event.target.value,
                                            )
                                          } // Handle the change event
                                        >
                                          <MenuItem value={"NO"}>NO</MenuItem>
                                          <MenuItem value={"YES"}>YES</MenuItem>
                                          <MenuItem value={"FREE"}>
                                            FREE
                                          </MenuItem>
                                          <MenuItem value={"UNDEFINED"}>
                                            UNDEFINED
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-culture"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Culture
                                      </InputLabel>
                                      <FormControl fullWidth>
                                        <Select
                                          id="culture"
                                          value={selectedculture} // Set the selected ID as the value of the Select component
                                          onChange={(event) =>
                                            setSelectedculture(
                                              event.target.value,
                                            )
                                          } // Handle the change event
                                        >
                                          <MenuItem value={"PROFESSIONAL"}>
                                            PROFESSIONAL
                                          </MenuItem>
                                          <MenuItem value={"NOT_PROFESSIONAL"}>
                                            NOT PROFESSIONAL
                                          </MenuItem>
                                          <MenuItem value={"MEDIUM"}>
                                            MEDIUM
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-financial_ability"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Financial Ability
                                      </InputLabel>
                                      <FormControl fullWidth>
                                        <Select
                                          id="financial_ability"
                                          value={selectedfinancial} // Set the selected ID as the value of the Select component
                                          onChange={(event) =>
                                            setSelectedfinancial(
                                              event.target.value,
                                            )
                                          } // Handle the change event
                                        >
                                          <MenuItem value={"NO"}>NO</MenuItem>
                                          <MenuItem value={"DAFFI3A"}>
                                            DAFFI3A
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-service_required"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Service required
                                      </InputLabel>
                                      <FormControl fullWidth>
                                        <Select
                                          id="service_required"
                                          value={selectedserviceRequired} // Set the selected ID as the value of the Select component
                                          onChange={(event) =>
                                            setSelectedserviceRequired(
                                              event.target.value,
                                            )
                                          } // Handle the change event
                                        >
                                          <MenuItem value={"ONLINE"}>
                                            ONLINE
                                          </MenuItem>
                                          <MenuItem value={"EMPLOYMENT"}>
                                            EMPLOYMENT
                                          </MenuItem>
                                          <MenuItem value={"HR_ASSISTANCE"}>
                                            HR ASSISTANCE
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-service_required"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Our Contact
                                      </InputLabel>
                                      <FormControl fullWidth>
                                        <Select
                                          id="our_contact"
                                          value={selectedContact} // Set the selected ID as the value of the Select component
                                          onChange={(event) =>
                                            setSelectedContact(
                                              event.target.value,
                                            )
                                          } // Handle the change event
                                        >
                                          <MenuItem value={"NO"}>NO</MenuItem>
                                          <MenuItem value={"HR"}>HR</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-contacted"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Contacted
                                      </InputLabel>
                                      <FormControl component="fieldset">
                                        <RadioGroup
                                          // Check if the field has been touched and has an error

                                          aria-label="Contacted"
                                          name="contacted"
                                          value={profileData?.contacted}
                                          onChange={handleChange}
                                          style={{ flexDirection: "row" }}
                                        >
                                          <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value={false}
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-service_required"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Satisfied
                                      </InputLabel>
                                      <FormControl component="fieldset">
                                        <RadioGroup
                                          // Check if the field has been touched and has an error

                                          aria-label="Satisfied"
                                          name="satisfied"
                                          value={profileData?.satisfied}
                                          onChange={handleChange}
                                          style={{ flexDirection: "row" }}
                                        >
                                          <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value={false}
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-service_required"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Hr Dept
                                      </InputLabel>
                                      <FormControl component="fieldset">
                                        <RadioGroup
                                          // Check if the field has been touched and has an error

                                          aria-label="Hr Dept"
                                          name="hr_dept"
                                          value={profileData?.hr_dept}
                                          onChange={handleChange}
                                          style={{ flexDirection: "row" }}
                                        >
                                          <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value={false}
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-trusted"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Trusted
                                      </InputLabel>
                                      <FormControl component="fieldset">
                                        <RadioGroup
                                          // Check if the field has been touched and has an error

                                          aria-label="Trusted"
                                          name="trusted"
                                          value={profileData?.trusted}
                                          onChange={handleChange}
                                          style={{ flexDirection: "row" }}
                                        >
                                          <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value={false}
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-apply_on_other_site"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Apply On Other Site
                                      </InputLabel>
                                      <FormControl component="fieldset">
                                        <RadioGroup
                                          aria-label="Apply On Other Site"
                                          name="apply_on_other_site"
                                          value={
                                            profileData?.apply_on_other_site
                                          }
                                          onChange={handleChange}
                                          style={{ flexDirection: "row" }}
                                        >
                                          <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value={false}
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-apply_url"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Apply Url
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        id="personal-apply_url"
                                        value={profileData?.apply_url}
                                        name="apply_url"
                                        // onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Url"
                                        autoFocus
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-service_required"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Pdf Mode
                                      </InputLabel>
                                      <FormControl component="fieldset">
                                        <RadioGroup
                                          // Check if the field has been touched and has an error

                                          aria-label="Pdf Mode"
                                          name="pdf_mode"
                                          value={profileData?.pdf_mode}
                                          onChange={handleChange}
                                          style={{ flexDirection: "row" }}
                                        >
                                          <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value={false}
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-optional_end_date"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Optional End Date
                                      </InputLabel>
                                      <FormControl component="fieldset">
                                        <RadioGroup
                                          // Check if the field has been touched and has an error
                                          aria-label="Optional End Date"
                                          name="optional_end_date"
                                          value={profileData?.optional_end_date}
                                          onChange={handleChange}
                                          style={{ flexDirection: "row" }}
                                        >
                                          <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value={false}
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={8}>
                                    <Stack marginLeft={"10px"}>
                                      <InputLabel
                                        htmlFor="personal-service_required"
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Logo
                                      </InputLabel>
                                      <Stack direction={"row"}>
                                        <input
                                          type="file"
                                          accept="image/*" // Allow only image files
                                          ref={fileInputRef} // Attach the reference to the file input
                                          // style={{ display: "none" }} // Hide the input element
                                          onChange={handleFileChange}
                                          // Handle file change event
                                        />
                                        <Button
                                          onClick={() =>
                                            fileInputRef.current.click()
                                          } // Trigger file input click on button click
                                        >
                                          Upload Photo
                                        </Button>
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          display={"flex"}
                          justifyContent={"end"}
                          alignItems={"end"}
                          marginTop={"10px"}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            {setProfileData.length === 0
                              ? "Edit Profile"
                              : "Edit Profile"}
                          </Button>
                        </Grid>
                      </Box>
                    </form>
                  </>
                )}
              </Formik>
            </MainCard>
          </Stack>
        </Stack>
      </section>
    </>
  );
};

export default EditCompanyProfile;
