import { useEffect, useState ,useRef } from "react";

// material-ui

import 'react-quill/dist/quill.snow.css';
import { useFormik, Form, FormikProvider } from "formik";
import {
  Box,
  Button,
FormControl,
  DialogTitle,
  Divider,
  Grid,

  InputLabel,

  MenuItem,

  Select,

  Stack,

  TextField,
  DialogContent,
  DialogActions,
  CircularProgress,

} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _ from "lodash";
import * as Yup from "yup";


// project imports

import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets


// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";

import { addNewTranslation, editTranslation, getOneTranslations } from "store/actions/main/translations";
import { getAllTranslationsCategory } from "store/actions/main/translationCategory";
import { FormattedMessage,useIntl } from "react-intl";
import { TranslationsModel } from "model/translations";
// constant

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //
const getInitialValues = (customer: TranslationsModel | null) => {
  if (customer) {
    return {
      en_name: customer.en_name || "",
      ar_name: customer.ar_name || "" ,
      key:customer.key || "",
      
    };
  } else {
    return {
      en_name:"",
    ar_name:"",
    key:"",
    translation_category:""
      
    };
  }
};
const FormPageManagmentTranslation = ({
  customer,
  id,
  closeModal,
  fetchData,
  setLoading,
  loading,
}: {

  loading: any;
  setLoading: any;
  fetchData: any;
  id: number;
  customer: TranslationsModel;
  closeModal: () => void;

}) => {
const dispatch = useAppDispatch();
  const intl = useIntl();


  // Split the pathname into segments using "/"

 
  // Extract the resume ID (second-to-last segment) and user ID (last segment)




const [translationCategories, settranslationCategories] = useState([]);
const [selectedCategoryTranslatoion, setselectedCategoryTranslatoion] = useState<any>(null);



//  useEffect(() => {
//   if(customer){
//     console.log("save translation categroy")
//     setselectedCategoryTranslatoion(customer.translation_category.id)
//   }
//  }, [customer]);
 

 

//   useEffect(() => {
//     console.log(vacancyId,"vacancy id");
//     if(vacancyId!=="0"){
//       console.log("im if");
      
//     dispatch(getOneTranslations({id:id})).then((action)=>{
// console.log(action.payload,"data vacancy");
//  setvacancyData(action.payload[0])
//       //   const  {data}  = action.payload;
//       //   console.log(data,"vacancy data");
//       //   setvacancyData(data)
       
//       // setcustomer(data)  
//     })}
//   }, [dispatch]);
useEffect(() => {
    dispatch(getAllTranslationsCategory()).then((action)=>{
        const data = action.payload;
        console.log(data,"translation category");
        settranslationCategories(data)
    })
}, [dispatch]);
  // const [vacancyData, setvacancyData] = useState({
  //   id:0,
  //   en_name:"",
  //   ar_name:"",
  //   key:""
    
 

  // });
  // console.log(vacancyData,"vcancy data");
  
  const validationSchema = Yup.object().shape({
    ar_name: Yup.string().required("Arabic name is required"),
        key:  Yup.string().required("Key is required"),
       
        
        
       
      
    // Add more fields as needed
    // Add more fields as needed

    // Add more fields as needed
  });


  
 const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editTranslation({
              id: customer.id || 0,
              data: {
                ar_name: values.ar_name,
                en_name: values.en_name,
                key:values.key,
                translation_category_id:selectedCategoryTranslatoion
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "basicInfo/editTitle/fulfilled") {
              openSnackbar({
                open: true,
                message: "Title edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addNewTranslation({
            ar_name: values.ar_name,
            key:values.key,
            en_name:values.en_name,
            translation_category_id:selectedCategoryTranslatoion
        
          })).then((action) => {
            fetchData();
            if (action.type === "basicInfo/addTitle/fulfilled") {
              openSnackbar({
                open: true,
                message: "Title added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });
  const handleChange = (event: React.ChangeEvent<any>) => {
    const { name, value, type, checked } = event.target;

    // Handle different input types
    const newValue = type === "checkbox" ? checked : value;

    console.log(newValue,"new value");
    
    console.log(name,"name");
    
    // Update profileData state with the new value
   

    // Toggle military service field based on selected gender
   
  };
console.log(customer,"customer")
  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps
  } = formik;


  
 

  
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
 <FormikProvider value={formik}>
       
      
        <LocalizationProvider>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px',}} >
              {id ? "Edit Translation" : "New Translation"}
             
            </DialogTitle>
            <Divider />
         
    <DialogContent sx={{ p: 2.5 }}>
          
            <Grid item xs={12} md={12}>
                  <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="en_name">
                        
                        <FormattedMessage id="key" />
                        </InputLabel>
                        <TextField
                          fullWidth
                          
                         
                          name="key" // Add the name attribute
                         
                        
                          {...getFieldProps("key")}
                         
                          placeholder="key"
                         
                          
                        />
                        
                      </Stack>
                    </Grid>
                  <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="en_name">
                     
                        <FormattedMessage id="english-name" />
                        </InputLabel>
                        <TextField
                          fullWidth
                          
                       
                          name="en_name" // Add the name attribute
                       
                      
                          {...getFieldProps("en_name")}
                         
                          placeholder="en_name"
                          
                          
                        />
                        
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                      <InputLabel htmlFor="customr-package_validity">
                      
                      <FormattedMessage id="arabic-name" />
                        </InputLabel>
                        <TextField
                          fullWidth
                          
                          {...getFieldProps("ar_name")}
                         // Add the name attribute
                       
                       
                         
                          placeholder="ar_name"
                          
                          
                        />
                        </Stack></Grid>


                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                             
                                <InputLabel htmlFor="lang">
                               
                                <FormattedMessage id="translation-category" />
                                </InputLabel>
                                
                            
                            
                                  <FormControl fullWidth>
                                    <Select
                                      id="translation_category"
                                      value={selectedCategoryTranslatoion?selectedCategoryTranslatoion:customer.translation_category}
                                      onChange={(e:any)=>{setselectedCategoryTranslatoion(e.target.value)}}
                                    
                                      
                                      name="status"
                                    >
                                      {translationCategories.map((category)=>(
                                          <MenuItem value={category?.id}>
                                         {category?.name}
                                          </MenuItem>)
                                      )}
                                    
                                    
                                      
                                    </Select>
                                  </FormControl>
                              
                            </Stack>
                          </Grid>
                          

                        </Grid></Grid>
        
                        </DialogContent>
           
<Divider />
            <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="center" alignItems="center">
               <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                                  <Grid item md={2}/>
                                  <Grid item md={4}>
                                    <Button fullWidth type="submit" variant="contained">
                                    {customer? <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : <FormattedMessage id="update" />}
                      </Button>:
                       <Button fullWidth type="submit" variant="contained">
                       {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : <FormattedMessage id="submit" />}
                     </Button>}
                                    </Button>
                                  </Grid>
                                  <Grid item md={4}>
                                    <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                                    <FormattedMessage id="cancel" />
                                    </Button>
                                  </Grid>
                                  <Grid item md={2}/>
                                </Stack>
            {/* <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button
                            
                            type="submit"
                            variant="contained"
                          >
                            Save
                          </Button> 
                          </Stack> */}
                          </Grid>
                          </DialogActions>
            <Divider />
       
        
            </Form>
        </LocalizationProvider>
    
           
      </FormikProvider>
    </>
  );
};

export default FormPageManagmentTranslation;
