// material-ui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  FormControl,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
// @ts-ignore
import _, { values } from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets

// types
import { SnackbarProps } from "types/snackbar";
import { CustomerList } from "types/customer";
import { useAppDispatch } from "hooks";

import {
  addNewNote,
  editNote,
} from "store/actions/main/adminNote";
import { CircularProgress } from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { AdminNote } from "model/basicInfo";
import TextArea from "antd/es/input/TextArea";
import { FormattedMessage,useIntl } from "react-intl";




// constant
const getInitialValues = (customer: AdminNote | null) => {
  
  

    if (customer) {
      return {
        title: customer.title || "",
        title_trans: customer.title_trans.ar || "",
        message_trans:customer.message_trans.ar||"",
        message:customer.message||"",
        status:customer.status||false,
        
      };
    
    
  }
  else{
return{
  
    title: '',
    
title_trans:"",
    
    message: '',
    message_trans:'',
    status:false,
  
  
}
  }

  return customer;
};
// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormCustomerAdd = ({
  customer,
  id,
  loading,
  fetchData,
  setLoading,
  closeModal,
}: {
  loading: any;
  id: number;
  setLoading: any;
  fetchData: any;
  customer: CustomerList | any;
  closeModal: () => void;
}) => {

  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    title:  Yup.string().required("Title is required"),
    message:  Yup.string().required("Message is required"),
 
  });


  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editNote({
              noteId: customer.id || 0,
              data: {
                title: values.title,
                title_trans:{
                  ar:values.title_trans
                },
              
                message:  values.message,
                status:values.status,
                message_trans:{
                  ar:values.message_trans

                }
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "note/editNote/fulfilled") {
              openSnackbar({
                open: true,
                message: "Note type edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addNewNote({
              title: values.title,
              title_trans:{
                ar:values.title_trans
              },
              status:values.status,
              message:  values.message,
              message_trans:{
                ar:values.message_trans

              }
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "note/addNote/fulfilled") {
              openSnackbar({
                open: true,
                message: "Note type added successfully!",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });
  
  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? <FormattedMessage id="edit-note-type-details" /> :  <FormattedMessage id="add-new-note-type" />}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container>
              <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-title">
                        <FormattedMessage id="title" />  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={<FormattedMessage id="set-the-title-of-note-type" />}>
                            
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-title"
                          placeholder="Title"
                          {...getFieldProps("title")}
                         
                        />
                      </Stack>
                </Grid>
                <Grid item md={1}/>
                <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-title">
                        <FormattedMessage id="title-arabic" />  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={<FormattedMessage id="set-the-title-arabic-of-note-type" />}>
                            
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-title"
                          placeholder="Title"
                          {...getFieldProps("title_trans")}
                          
                        />
                      </Stack>
                </Grid>
              
                <Grid item md={1}/>
               
                <Grid item xs={12} sm={11} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-note">
                    <FormattedMessage id="message" /> <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={<FormattedMessage id="set-the-message-of-the-note-type" />}>
                            
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                    </InputLabel>
                    <TextArea
                      id="customer-note"
                      placeholder="Note"
                      {...getFieldProps("message")}
                    />
                  </Stack>
                  {touched.message && errors.message && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{ pl: 1.75 }}>
                      {errors.message}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={11} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-note">
                    <FormattedMessage id="message-arabic" /> <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={<FormattedMessage id="set-the-message-of-the-note-type" />}>
                            
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                    </InputLabel>
                    <TextArea
                      id="customer-note"
                      placeholder="Note"
                      {...getFieldProps("message_trans")}
                    />
                  </Stack>
                 
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-is_active">
                    <FormattedMessage id="status" />
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="set-the-country-status" />}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            id="customer-is_active"
                            checked={formik.values.status}
                            {...getFieldProps("status")}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              setFieldValue("status", checked);
                            }}
                          />
                        }
                        label={formik.values.status ? <FormattedMessage id="active" /> : <FormattedMessage id="inactive" />}
                      />
                    </FormControl>
                    {touched.status && errors.status && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{ pl: 1.75 }}
                      >
                        {errors.status}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                     {customer? <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : <FormattedMessage id="update" />}
                      </Button>:
                       <Button fullWidth type="submit" variant="contained">
                       {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : <FormattedMessage id="submit" />}
                     </Button>}
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                      <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormCustomerAdd;
