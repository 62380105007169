// assets
import {
  LoginOutlined,
  ProfileOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { FormattedMessage,useIntl } from "react-intl";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupIcon from "@mui/icons-material/Group";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
// icons

const useResumeItems = () => {
  const icons = {
    LoginOutlined,
    ProfileOutlined,
    FileTextOutlined,
    BusinessCenterOutlinedIcon,
    SettingsOutlinedIcon,
    CheckCircleOutlineIcon,
    DescriptionIcon,
    GroupIcon,
    ToggleOnIcon,
    ToggleOffIcon,
  };

  // ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

  const resume = {
    id: "Resume Management",
    title: (
      <FormattedMessage id="individuals">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "list-jobseekers",
        title: <FormattedMessage id="individuals" />,
        type: "item",
        url: "/list-jobseekers",
        icon: icons.GroupIcon,
        breadcrumbs: false,
      },
      {
        id: "resumes",
        title: <FormattedMessage id="resumes-list" />,
        type: "item",
        url: "/resumes",
        icon: icons.FileTextOutlined,
        breadcrumbs: false,
      },
      {
        id: "active-resume",
        title: <FormattedMessage id="active-resume" />,
        type: "item",
        url: "/active-resume",
        icon: icons.ToggleOnIcon,
        breadcrumbs: false,
      },
      {
        id: "unactive-resume",
        title: <FormattedMessage id="unactive-resume" />,
        type: "item",
        url: "/unactive-resume",
        icon: icons.ToggleOffIcon,
        breadcrumbs: false,
      },
      {
        id: "resume-management-report",
        title: <FormattedMessage id="resume-management-report" />,
        type: "item",
        url: "/resume-management-report",
        icon: icons.ProfileOutlined,
        breadcrumbs: false,
      },
      {
        id: "status",
        title: <FormattedMessage id="account-status" />,
        type: "item",
        url: "/account-status",
        icon: icons.SettingsOutlinedIcon,
        breadcrumbs: false,
      },
    ],
  };
  return resume;
};

export default useResumeItems;
