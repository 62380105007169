import {
  EditOutlined as EditOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from "@mui/icons-material";
import {
  Grid,
  Typography,
  Stack,
  List,
  useMediaQuery,
  ListItem,
} from "@mui/material";
import { Theme } from "@mui/material/styles";

import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import DeletEducation from "./deleteEducationDialog";
import EditEducationDialog from "./editDialodEducation";
import { useAppDispatch } from "hooks";
import { getOneEducation } from "store/actions/main/cv/education/education";
import { EducationSectionModel } from "model/cv/education/education";
import { getAllEducational } from "store/actions/main/basicInfo/educational";
import { getAllDegree } from "store/actions/main/basicInfo/degree";
import Loader from "components/Loader";
import { FormattedMessage,useIntl } from "react-intl";
import { Form } from "react-router-dom";

export function TableEducation({
  cvId,
  educationCertificates,
  fetchGetOneEdu,
  loading,
  setLoading,
}: {
  fetchGetOneEdu: any;
  loading: any;
  setLoading: any;
  cvId: number;
  educationCertificates: any;
}) {
  const educationCertificate = educationCertificates.resume_educations;
  // Filter education certificates based on cvId
  const [educationName, setEducationName] = useState("");
  const [educationDegree, setEducationDegree] = useState("");
  const matchDownMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );
  const [deletId, setDeletId] = useState(0);
  const [open, setOpen] = useState(false);
  const [educationData, setEducationData] = useState<EducationSectionModel>();
  const [openDialog, setOpenDialog] = useState(false);

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const fetchEducationData = async (id: any) => {
    try {
      const action = await dispatch(getOneEducation({ id: id })); // Assume getEducationById is your API function
      const data = action.payload["result: "]; // Extract payload from the action
      setEducationData(data); // Set fetched data to state
    } catch (error) {
      console.error("Error fetching education data:", error);
    }
  };

  const handleEdit = (id: number) => {
    setDeletId(id);
    setOpenDialog(true);
    fetchEducationData(id);
  };
  const handleDelete = (id: number) => {
    setDeletId(id);
    setOpen(true);
  };
  useEffect(() => {
    const fetchName = async () => {
      const action = await dispatch(getAllEducational({ pageNumber: 1 }));
      const { data } = action.payload;

      // Process availability names
      const availabilityNames = educationCertificate.map((job: any) => {
        const availability = data.find(
          (av: any) => av.id === job.educatioanl_institution_id,
        );
        return availability ? availability.name : "";
      });

      // Combine names into a single string or format as needed
      const combinedNames = availabilityNames.join(", ");
      setEducationName(combinedNames);
    };
    const fetchDegree = async () => {
      const action = await dispatch(getAllDegree({ pageNumber: 1 }));
      const { data } = action.payload;

      // Process availability names
      const availabilityNames = educationCertificate.map((job: any) => {
        const availability = data.find(
          (av: any) => av.id === job.degree_type_id,
        );
        return availability ? availability.name : "";
      });

      // Combine names into a single string or format as needed
      const combinedNames = availabilityNames.join(", ");
      setEducationDegree(combinedNames);
    };
    fetchName();
    fetchDegree();
  }, [educationCertificate]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Stack>
          {educationCertificate.map((certificate: any, index: number) => (
            <Grid width={"57vw"} sx={{ mt: "10px" }}>
              <MainCard
                title="Education"
                headerActions={
                  <Stack
                    direction={"row"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <EditOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          marginRight: 2,
                          cursor: "pointer",
                          color: "#4a934a",
                        }}
                        onClick={() => handleEdit(certificate.id)}
                      />
                      <DeleteOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "#ff3333",
                          marginRight: "20px",
                        }}
                        onClick={() => handleDelete(certificate.id)}
                      />
                    </div>
                    {/* Add additional controls here */}
                  </Stack>
                }
              >
                <List>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">
                           
                            <FormattedMessage id="educational-institution" />
                          </Typography>

                          <Typography>{educationName}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary"> <FormattedMessage id="degree" /> </Typography>
                          <Typography>{educationDegree}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary"><FormattedMessage id="major" /></Typography>
                          <Typography>{certificate.specialization}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary"> <FormattedMessage id="average" /></Typography>
                          <Typography>{certificate.average}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary"> <FormattedMessage id="country" /> </Typography>
                          <Typography>{certificate.country}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary"><FormattedMessage id="city" /></Typography>
                          <Typography>{certificate.city}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">
                          
                            <FormattedMessage id="graduation-year" />
                          </Typography>
                          <Typography>{certificate.graduation_year}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </MainCard>
            </Grid>
          ))}
        </Stack>
      )}

      {open && (
        <DeletEducation
          open={open}
          setOpen={setOpen}
          id={deletId}
          fetchGetOneEdu={fetchGetOneEdu}
        />
      )}
      {openDialog && (
        <EditEducationDialog
          resumeId={cvId}
          cvId={deletId}
          open={openDialog}
          setOpen={setOpenDialog}
          educationData={educationData}
          setEducationData={setEducationData}
          fetchGetOneEdu={fetchGetOneEdu}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
}
