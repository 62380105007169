import { useEffect, useState } from "react";

// material-ui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,

  MenuItem,
 
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
 
} from "@mui/material";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";

import {
  getAllVacancies,
} from "store/actions/main/packages/vacanciesPackages";
import { getAllCompanies } from "store/actions/main/companies";
import { VacancySubSecription } from "model/subscription";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  addNewVacancySub,
  editOneVacancySub,
} from "store/actions/main/subscription/vacancySub";
import { getAllVacanciesOrder } from "store/actions/main/order/vacancyOrder";
import { CircularProgress } from "@mui/material";
import { FormControl } from "@mui/material";
import { FormControlLabel } from "@mui/material";

// constant
const getInitialValues = (customer: VacancySubSecription | null) => {
  const newCustomer = {
    user_id: "",
    type: "",
    package_validation: "",
    paid_amount: "",
    free_amount: "",
    start_date: "",
    end_date: "",
    status: false,
    vacancy_package_id: "",
    vacancy_order_id: "",
    amount_vacancies: "",
    used_vacancies: "",
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormVacancyAdd = ({
  customer,
  fetchData,
  id,
  closeModal,
}: {
  fetchData: any;
  id: number;
  customer: VacancySubSecription | null;
  closeModal: () => void;
}) => {

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [userName, setUserName] = useState();
  const [Packages, setPackages] = useState();
  const [order, setOrder] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    setLoading(false);
    fetchComData();
    fetchDataPackages();
    fetchOrderPackages();
    // Format the start and end dates if they are provided as Dayjs objects
    if (customer) {
      const formattedStartDate = customer.start_date
        ? dayjs(customer.start_date).format("YYYY-MM-DD")
        : null;
      const formattedEndDate = customer.end_date
        ? dayjs(customer.end_date).format("YYYY-MM-DD")
        : null;
      setStartDate(formattedStartDate);
       // @ts-ignore
      setEndDate(formattedEndDate);
    } else {
      // If customer data doesn't exist, set default values for start and end dates
      setStartDate(null);
      setEndDate(null);
    }
  }, [dispatch]);

  const CustomerSchema = Yup.object().shape({
    user_id: Yup.string().required("Company Name is required"),
    type: Yup.string().required("Type is required"),
    package_validation: Yup.number().required("Package Validation is required"),
    status: Yup.string().required("Status is required"),
    paid_amount: Yup.number().required("Paid Amount is required"),
    amount_vacancies: Yup.string().required("Amount Vacancies is required"),
    //end_date: Yup.string().required("End Date is required"),
    //  start_date: Yup.string().required("Start Date is required"),
    used_vacancies: Yup.number().required("Used Vacancies is required"),
    free_amount: Yup.number().required("Free Amount is required"),
    vacancy_package_id: Yup.number().required("Vacancy Package is required"),
    vacancy_order_id: Yup.number().required("Vacancy Order Id is required"),
  });


  const fetchComData = () => {
    dispatch(getAllCompanies({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setUserName(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  const fetchDataPackages = () => {
    dispatch(getAllVacancies({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setPackages(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchOrderPackages = () => {
    dispatch(getAllVacanciesOrder({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setOrder(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
 
  const handleDateChange = (date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setStartDate(formattedDate); // Update the state directly with the formatted date
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
     // @ts-ignore
    setEndDate(formattedDate); // Update the state directly with the formatted date
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editOneVacancySub({
              id: customer.id || 0,
              data: {
                user_id: values.user_id,
                type: values.type,
                package_validation: values.package_validation,
                paid_amount: values.paid_amount,
                free_amount: values.free_amount,
                status: values.status === true ? 1 : 0,
                vacancy_package_id: values.vacancy_package_id,
                vacancy_order_id: values.vacancy_order_id,
                amount_vacancies: values.amount_vacancies,
                used_vacancies: values.used_vacancies,
                start_date: startDate,
                end_date: endDate,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "sub/editOneVacancySub/fulfilled") {
              openSnackbar({
                open: true,
                message: "Vacancy SubScription updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            } else {
              openSnackbar({
                open: true,
                message: "some thing went wrong.",
                variant: "alert",
                alert: {
                  color: "error",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addNewVacancySub({
              user_id: values.user_id,
              type: values.type,
              package_validation: values.package_validation,
              paid_amount: values.paid_amount,
              free_amount: values.free_amount,
              status: values.status === true ? 1 : 0,
              vacancy_package_id: values.vacancy_package_id,
              vacancy_order_id: values.vacancy_order_id,
              amount_vacancies: values.amount_vacancies,
              used_vacancies: values.used_vacancies,
              start_date: startDate,
              end_date: endDate,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "sub/addNewVacancySub/fulfilled") {
              openSnackbar({
                open: true,
                message: "Vacancy SubScription added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            } else {
              openSnackbar({
                open: true,
                message: "some thing went wrong.",
                variant: "alert",
                alert: {
                  color: "error",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  console.log(errors);

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? "Edit vacancy subscription" : "Add new vacancy subscription"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
                  <Grid container>
                    <Grid item mb={1} xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-vacancy_order_id">
                          Order <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Enter order for the vacancy subscription'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <Select
                          id="customer-vacancy_order_id"
                          displayEmpty
                          {...getFieldProps("vacancy_order_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedPackage = order.find(
                              (pkg: any) => pkg.id === selectedId,
                            );
                            const selectedType = selectedPackage
                              ? selectedPackage.id
                              : "";
                            setFieldValue("vacancy_order_id", selectedId);
                            setFieldValue("vacancy_order_id", selectedType); // Set type value to another field
                          }}
                          value={getFieldProps("vacancy_order_id").value || ""}
                        >
                          {order &&
                            order.map((pkg: any) => (
                              <MenuItem key={pkg.id} value={pkg.id}>
                                {pkg.id}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.vacancy_order_id &&
                          errors.vacancy_order_id && (
                            <FormHelperText error>
                              {errors.vacancy_order_id}
                            </FormHelperText>
                          )}
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item mb={1} xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-user_id">
                          Company Name  <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Enter company name for the vacancy subscription'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <Select
                          id="customer-user_id"
                          displayEmpty
                          {...getFieldProps("user_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                             // @ts-ignore
                            const selectedUser = userName.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedUserName = selectedUser
                              ? selectedUser.user_name
                              : "";
                            setFieldValue("user_id", selectedId);
                            setFieldValue("user_name", selectedUserName); // Set user name value to another field
                          }}
                          value={getFieldProps("user_id").value || ""}
                        >
                          {userName &&
                           // @ts-ignore
                            userName.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.user_name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.user_id && errors.user_id && (
                          <FormHelperText error>
                            {errors.user_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item mb={1} xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-type">Type <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Enter type for the vacancy subscription'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        
                        </InputLabel>
                        <Select
                          id="customer-type"
                          displayEmpty
                          {...getFieldProps("type")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;

                            // Set the field value to the converted value
                            setFieldValue("type", selectedValue);
                          }}
                          // rest of the code
                        >
                          <MenuItem value={"LIMITED"}>LIMITED</MenuItem>
                          <MenuItem value={"UNLIMITED"}>UNLIMITED</MenuItem>
                        </Select>
                        {touched.type && errors.type && (
                          <FormHelperText error>{errors.type}</FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item mb={1} xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-package_validation">
                          Package Validation  <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Enter package validation for the vacancy subscription'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-package_validation"
                          placeholder="Enter Package Validation"
                          {...getFieldProps("package_validation")}
                          error={Boolean(
                            touched.package_validation &&
                              errors.package_validation,
                          )}
                          helperText={
                            touched.package_validation &&
                            errors.package_validation
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item mb={1} xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-paid_amount">
                          Paid Amount  <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Enter paid amount for the vacancy subscription'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-paid_amount"
                          placeholder="Enter Paid Amount"
                          {...getFieldProps("paid_amount")}
                          error={Boolean(
                            touched.paid_amount && errors.paid_amount,
                          )}
                          helperText={touched.paid_amount && errors.paid_amount}
                        />
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item mb={1} xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-cost">
                          Free Amount  <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Enter free amount for the vacancy subscription'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-free_amount"
                          placeholder="Enter Free Amount"
                          {...getFieldProps("free_amount")}
                          error={Boolean(
                            touched.free_amount && errors.free_amount,
                          )}
                          helperText={touched.free_amount && errors.free_amount}
                        />
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item mb={1} xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-vacancy_package_id">
                          Packages  <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Enter packages for the vacancy subscription'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <Select
                          id="customer-vacancy_package_id"
                          displayEmpty
                          {...getFieldProps("vacancy_package_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                             // @ts-ignore
                            const selectedPackage = Packages.find(
                              (pkg: any) => pkg.id === selectedId,
                            );
                            const selectedType = selectedPackage
                              ? selectedPackage.type
                              : "";
                            setFieldValue("vacancy_package_id", selectedId);
                            setFieldValue("vacancy_package_type", selectedType); // Set type value to another field
                          }}
                          value={
                            getFieldProps("vacancy_package_id").value || ""
                          }
                        >
                          {Packages &&
                           // @ts-ignore
                            Packages.map((pkg: any) => (
                              <MenuItem key={pkg.id} value={pkg.id}>
                                {pkg.type}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.vacancy_package_id &&
                          errors.vacancy_package_id && (
                            <FormHelperText error>
                              {errors.vacancy_package_id}
                            </FormHelperText>
                          )}
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item mb={1} xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-amount_vacancies">
                          Amount Vacancies  <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Enter amount for the vacancy subscription'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-amount_vacancies"
                          placeholder="Amount Vacancies"
                          {...getFieldProps("amount_vacancies")}
                          error={Boolean(
                            touched.amount_vacancies && errors.amount_vacancies,
                          )}
                          helperText={
                            touched.amount_vacancies && errors.amount_vacancies
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item mb={1} xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-used_vacancies">
                          Used  <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Enter used for the vacancy subscription'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-used_vacancies"
                          placeholder="Enter Used Vacancies"
                          {...getFieldProps("used_vacancies")}
                          error={Boolean(
                            touched.used_vacancies && errors.used_vacancies,
                          )}
                          helperText={
                            touched.used_vacancies && errors.used_vacancies
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-start_date">
                          Start Date  <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Enter start date for the vacancy subscription'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={startDate ? dayjs(startDate) : null}
                            onChange={(date) => handleDateChange(date)}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-end_date">
                          End Date <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Enter end date for the vacancy subscription'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={endDate ? dayjs(endDate) : null}
                            onChange={(date) => handleEndDateChange(date)}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-status">
                          Status
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the vacancy status (Default inactive)'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <FormControl fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                id="customer-status"
                                checked={formik.values.status}
                                {...getFieldProps("status")}
                                onChange={(event) => {
                                  const checked = event.target.checked;
                                  setFieldValue("status", checked);
                                }}
                              />
                            }
                            label={formik.values.status ? <FormattedMessage id="active" /> : <FormattedMessage id="inactive" />}
                          />
                        </FormControl>
                        {touched.status && errors.status && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{ pl: 1.75 }}
                          >
                            {errors.status}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : 'Submit'}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                        <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormVacancyAdd;
