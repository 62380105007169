import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import { FormattedMessage,useIntl } from "react-intl";
import SchoolIcon from "@mui/icons-material/School";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import FeedIcon from "@mui/icons-material/Feed";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import DescriptionIcon from "@mui/icons-material/Description";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import ListIcon from "@mui/icons-material/List";
const useEducationItem = () => {
  const icons = {
    LocationCityOutlinedIcon,
    SchoolIcon,
    FeedIcon,
    CardMembershipIcon,
    CalendarTodayIcon,
    AddCircleIcon,
    RequestQuoteIcon,
    DescriptionIcon,
    CrisisAlertIcon,
    ListIcon,
    CategoryOutlinedIcon,
  };

  const educationItems = {
    id: "group-countries",
    title: (
      <FormattedMessage id="education">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "courses",
        title: <FormattedMessage id="courses" />,
        type: "item",
        url: "/courses",
        icon: icons.SchoolIcon,
        breadcrumbs: false,
      },
      {
        id: "coursecategories",
        title: <FormattedMessage id="coursecategories" />,
        type: "item",
        url: "/coursecategories",
        icon: icons.FeedIcon,
        breadcrumbs: false,
      },
      {
        id: "educationalcompanies",
        title: <FormattedMessage id="educationalcompanies" />,
        type: "item",
        url: "/educationalcompanies",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },
      {
        id: "coursecertificates",
        title: <FormattedMessage id="coursecertificates" />,
        type: "item",
        url: "/coursecertificates",
        icon: icons.CardMembershipIcon,
        breadcrumbs: false,
      },
      {
        id: "coursedays",
        title: <FormattedMessage id="coursedays" />,
        type: "item",
        url: "/coursedays",
        icon: icons.CalendarTodayIcon,
        breadcrumbs: false,
      },
      {
        id: "courseperiods",
        title: <FormattedMessage id="courseperiods" />,
        type: "item",
        url: "/courseperiods",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },

      {
        id: "addedtutorials",
        title: <FormattedMessage id="addedtutorials" />,
        type: "item",
        url: "/addedtutorials",
        icon: icons.AddCircleIcon,
        breadcrumbs: false,
      },
      {
        id: "requestedtutorials",
        title: <FormattedMessage id="requestedtutorials" />,
        type: "item",
        url: "/requestedtutorials",
        icon: icons.RequestQuoteIcon,
        breadcrumbs: false,
      },
      {
        id: "tutorialpackages",
        title: <FormattedMessage id="tutorialpackages" />,
        type: "item",
        url: "/tutorialpackages",
        icon: icons.DescriptionIcon,
        breadcrumbs: false,
      },
      {
        id: "tutorialtargetedsegments",
        title: <FormattedMessage id="tutorialtargetedsegments" />,
        type: "item",
        url: "/tutorialtargetedsegments",
        icon: icons.CrisisAlertIcon,
        breadcrumbs: false,
      },
      {
        id: "tutorialcategories",
        title: <FormattedMessage id="tutorialcategories" />,
        type: "item",
        url: "/tutorialcategories",
        icon: icons.CategoryOutlinedIcon,
        breadcrumbs: false,
      },

      {
        id: "suggestededucationalinstitution",
        title: <FormattedMessage id="suggestedinstitution" />,
        type: "item",
        url: "/suggestededucationalinstitution",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },

      {
        id: "educationalexperiencelist",
        title: <FormattedMessage id="educationalexperiencelist" />,
        type: "item",
        url: "/educationalexperiencelist",
        icon: icons.ListIcon,
        breadcrumbs: false,
      },
    ],
  };

  return educationItems;
};

export default useEducationItem;
